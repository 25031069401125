import * as React from "react";
const RefreshIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={22}
    fill="none"
    {...props}
  >
    <path
      stroke="#99B2C6"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10 1 8 2.995l.06.06m0 0L10 5M8.06 3.055A8 8 0 0 1 14 17.245M8 17l2 1.91-.03.032m0 0A8 8 0 0 1 4 4.755m5.97 14.187L8 21"
    />
  </svg>
);
export default RefreshIcon;
