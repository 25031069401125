import React, { useContext, useState } from "react";
import { SEND_ENUM } from "../../enums/sendEnum";
import styles from "./index.module.scss";
import cx from "classnames";
import { AppContext } from "../../context";
import Card from "../../components/Card/Card";
import ButtonRade from "../../components/RadeButtons";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { db, auth } from "../../firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";
import passwordHideIcon from "../../assets/passwordHideIcon.svg";
import passwordShowIcon from "../../assets/passwordShowIcon.svg";
import Modal from "react-modal";
import SignUpSuccessfulModal from "../SignUpSuccessfulModal/SignUpSuccessfulModal";
import SignUpWaitingModal from "../SignUpWaitingModal/SignUpWaitingModal";
import mixpanel from "mixpanel-browser";

const EmailPswdSignIn = ({
  setProfileEmail,
  setIsLoggedIn,
  setGoogleLoginPageNumber,
}) => {
  const { isMobile, setAuthLoading, setStrId } = useContext(AppContext);
  // state for setting the user entered email
  const [email, setEmail] = useState("");
  // state for setting the user entered password
  const [password, setPassword] = useState("");
  // state for setting the error message if there is any
  const [errorMessage, setErrorMessage] = useState("");
  // State to track sign up mode
  const [isSignUp, setIsSignUp] = useState(false);
  // State to track whether password is visible
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  // State to track whether confirm password is visible
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  // state for setting confirm passowrd field
  const [confirmPassword, setConfirmPassword] = useState("");
  //state to set the modal to open or close
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // State for controlling different pop-ups
  const [modalPageNumber, setModalPageNumber] = useState(false);
  // state for setting the message for password reset
  const [passwordReset, setPasswordReset] = useState("");
  // handle on change the user entering email
  const handleOnChangeEmail = (e) => {
    setErrorMessage("");
    setEmail(e.target.value);
  };
  // handle on change the user entering password
  const handleOnChangePassword = (e) => {
    setErrorMessage("");
    setPasswordReset("");
    setPassword(e.target.value);
  };
  const handleOnChangeConfirmPassword = (e) => {
    setErrorMessage("");
    setPasswordReset("");
    setConfirmPassword(e.target.value);
  };
  // toggle between sign up and sign in stages
  const toggleMode = () => {
    setIsSignUp(!isSignUp);
    setErrorMessage("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setPasswordReset("");
  };
  // toggle password visibility
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  // Separate toggle function for confirm password
  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };
  // handle on change the sign in and sign up methods
  const onSubmit = async (e) => {
    e.preventDefault();
    // Password validation regex (at least 8 characters, one uppercase, one number, one special character)
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&*_])[A-Za-z\d@#$%^&*_]{8,}$/;

    if (!email || !password) {
      setErrorMessage("Please enter the email and password to login!");
      return;
    }
    // Check if the password meets criteria
    if (isSignUp && !passwordRegex.test(password)) {
      setErrorMessage(
        "Your password does not meet the requirements. Please retype password."
      );
      return;
    }
    if (isSignUp && password !== confirmPassword) {
      setErrorMessage("Passwords do not match!");
      return;
    }

    try {
      // Set authLoading to prevent immediate login state change in App.js
      setAuthLoading(true);
      let stripeCustomerId;
      if (isSignUp) {
        // If in sign up mode, create a new user
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;
        // Create Stripe Customer for new user
        const idToken = await auth.currentUser.getIdToken(
          /* forceRefresh */ true
        );
        const response = await fetch(
          `${process.env.REACT_APP_PAYEZY_SERVER_URI}/create-stripe-customer?userEmail=${user.email}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        if (response.ok) {
          const customerData = await response.json();
          stripeCustomerId = customerData.customer.id;
          console.log("New Stripe customer created:", stripeCustomerId);
        } else {
          throw new Error("Failed to create Stripe customer");
        }
        setModalIsOpen(true);
        setModalPageNumber(0);
        // Create user document in Firestore
        const userRef = doc(db, "userData", user.email);
        const user_pii = {
          type: "individual",
          name: {
            first: "",
            middle: "",
            last: "",
          },
          address: {
            street: "",
            street2: "",
            city: "",
            subdivision: "",
            postal_code: "",
            country_code: "",
          },
          date_of_birth: "",
          phone_number: "",
          email_address: user.email, // Set email address here
        };

        // Create empty objects for transactionDetails, beneficiaryDetails, and cybridCustomerDetails
        const transaction_details = {};
        const beneficiary_details = {};
        const cybrid_customer_details = {};
        const customer_state = "Not Initialized";
        // Set the document in Firestore with userPii and the other details
        await setDoc(userRef, {
          user_pii,
          transaction_details,
          beneficiary_details,
          cybrid_customer_details,
          customer_state,
          user_since: new Date().getTime(),
        });
        console.log("opening the modal");
        setModalPageNumber(1);
        setTimeout(() => {
          setModalIsOpen(false);
          setIsLoggedIn(true);
          setProfileEmail(user.email);
          setStrId(stripeCustomerId);
          setAuthLoading(false); // Re-enable auth state updates after modal
        }, 3000);
        if (process.env.REACT_APP_DEPLOYMENT_MODE === "PRODUCTION") {
          mixpanel.track("User logged in using Email Password!");
        }
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            sender_details: {
              email: user.email,
            },
          }),
        };
        const welcomeEmailResponse = await fetch(
          `${process.env.REACT_APP_PAYEZY_SERVER_URI}/send-welcome-email`,
          requestOptions
        );
        if (!welcomeEmailResponse.ok) {
          console.error("Failed to send welcome email");
        }
      } else {
        // If in sign in mode, sign in with existing user
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;
        // Fetch user data from Firestore
        const userRef = doc(db, "userData", user.email);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          stripeCustomerId = userData.stripeCustomerId; // Retrieve stripeCustomerId from Firestore
          console.log("Existing user Stripe ID:", stripeCustomerId);

          // Update app state after sign-in
          setIsLoggedIn(true);
          setProfileEmail(user.email);
          setStrId(stripeCustomerId); // Set stripe customer ID in state
          if (process.env.REACT_APP_DEPLOYMENT_MODE === "PRODUCTION") {
            mixpanel.track("User logged in using Email Password!");
          }
        } else {
          console.log("User document does not exist in Firestore");
        }
        setAuthLoading(false); // Re-enable auth state updates after modal
      }
    } catch (error) {
      console.log("error", error);
      setAuthLoading(false); // Ensure the flag is reset on error
      if (error.code === "auth/invalid-email") {
        setErrorMessage("Invalid Email!");
      } else if (error.code === "auth/invalid-login-credentials") {
        setErrorMessage("Your email or password is incorrect!");
      } else if (error.code === "auth/email-already-in-use") {
        setErrorMessage("You have already logged in using same email!");
      } else {
        setErrorMessage(error.message);
      }
    }
  };

  // handle the forgot password method
  const forgotPasswordHandler = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setPasswordReset("Password reset email sent. Please check your inbox.");
      setErrorMessage("");
    } catch (error) {
      setErrorMessage(error.message);
    }
  };
  // handle on click back button
  const handleOnClickBack = () => {
    setGoogleLoginPageNumber(0);
  };
  const CardTitle = () => (
    <>
      <div
        className={cx(styles.loginTitle, {
          [styles.loginTitleMob]: isMobile,
        })}
      >
        {isSignUp ? SEND_ENUM.signUp : SEND_ENUM.signIn}
      </div>
    </>
  );
  const CardBody = () => (
    <div
      className={cx(styles.emailPswdContainer, {
        [styles.emailPswdContainerMob]: isMobile,
      })}
    >
      <div
        className={cx(styles.emailInputField, {
          [styles.emailInputFieldMob]: isMobile,
        })}
      >
        <input
          placeholder="Email"
          type="email"
          value={email}
          onChange={handleOnChangeEmail}
          className={cx(styles.input, {
            [styles.inputWeb]: !isMobile,
            [styles.inputMob]: isMobile,
          })}
        />
      </div>
      <div
        className={cx(styles.paswdInputField, {
          [styles.pswdInputFieldMob]: isMobile,
        })}
      >
        <input
          placeholder="Password"
          type={isPasswordVisible ? "text" : "password"}
          value={password}
          onChange={handleOnChangePassword}
          className={cx(styles.input, {
            [styles.inputWeb]: !isMobile,
            [styles.inputMob]: isMobile,
          })}
        />
        <img
          src={isPasswordVisible ? passwordShowIcon : passwordHideIcon}
          alt="Toggle Password Visibility"
          className={styles.passwordIcon}
          onClick={togglePasswordVisibility}
        />
      </div>
      {isSignUp && (
        <div
          className={cx(styles.paswdInputField, {
            [styles.pswdInputFieldMob]: isMobile,
          })}
        >
          <input
            placeholder="Confirm Password"
            type={isConfirmPasswordVisible ? "text" : "password"}
            value={confirmPassword}
            onChange={handleOnChangeConfirmPassword}
            className={cx(styles.input, {
              [styles.inputWeb]: !isMobile,
              [styles.inputMob]: isMobile,
            })}
          />
          <img
            src={isConfirmPasswordVisible ? passwordShowIcon : passwordHideIcon}
            alt="Toggle Password Visibility"
            className={styles.passwordIcon}
            onClick={toggleConfirmPasswordVisibility}
          />
        </div>
      )}
      {isSignUp && (
        <div
          className={cx(styles.passwordNote, {
            [styles.passwordNoteMob]: isMobile,
          })}
        >
          {SEND_ENUM.emailPasswordNote}
        </div>
      )}
      <div
        className={cx(styles.signInbuttonContainer, {
          [styles.signInbuttonContainerMob]: isMobile,
        })}
      >
        <ButtonRade
          customStyling={styles.backButton}
          onClick={handleOnClickBack}
        >
          {SEND_ENUM.cancel}
        </ButtonRade>
        <ButtonRade customStyling={styles.signInButton} onClick={onSubmit}>
          {isSignUp ? SEND_ENUM.signUp : SEND_ENUM.signIn}
        </ButtonRade>
      </div>
      {!isSignUp && (
        <div
          className={cx(styles.forgotPasswordContainer, {
            [styles.forgotPasswordContainerMob]: isMobile,
          })}
        >
          <ButtonRade
            customStyling={styles.forgotPasswordButton}
            onClick={forgotPasswordHandler}
          >
            {SEND_ENUM.forgotPswd}
          </ButtonRade>
        </div>
      )}
      <div className={styles.toggleText} onClick={toggleMode}>
        {isSignUp ? SEND_ENUM.alreadySignedIn : SEND_ENUM.newUser}
      </div>
      {errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}
      {passwordReset && (
        <div className={styles.passwordReset}>{passwordReset}</div>
      )}
    </div>
  );

  return (
    <div>
      <div
        className={cx(styles.loginWithGoogleContainer, {
          [styles.loginWithGoogleContainerMob]: isMobile,
        })}
      >
        <Card titleComponent={<CardTitle />}>{CardBody()}</Card>
      </div>
      {/* Code for opening the modal */}
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        {(() => {
          switch (modalPageNumber) {
            case 0:
              return <SignUpWaitingModal />;
            case 1:
              return <SignUpSuccessfulModal />;
            default:
              return null;
          }
        })()}
      </Modal>
    </div>
  );
};

export default EmailPswdSignIn;
