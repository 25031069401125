import React, { useContext, useState, useRef } from "react";
import cx from "classnames";
import styles from "./index.module.scss";
import { AppContext } from "../../context";
import { SEND_ENUM } from "../../enums/sendEnum";
import { SUPPORT_ENUM } from "../../enums/supportEnum";
import ButtonRade from "../../components/RadeButtons";
import NextIcon from "../../assets/NextIcon";
import Card from "../../components/Card/Card";
import Modal from "react-modal";
import ServiceTicketSubmit from "../ServiceTicketSubmitModal/ServiceTicketSubmitModal";
import { collection, addDoc, doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useLocation } from "react-router-dom";
import mixpanel from "mixpanel-browser";

// Custom hook to focus input on div click
const useFocusOnClick = () => {
  const inputRef = useRef(null);

  const handleDivClick = () => {
    inputRef.current.focus();
  };

  return {
    inputRef,
    handleDivClick,
  };
};

const FailedTransaction = ({
  orderId, // Receive orderId as a prop,
  setOrderId,
  message,
  setMessage,
  userModeSelection,
  setUserModeSelection,
}) => {
  const { isMobile, profileEmail, setSupportFlowPageNumber } =
    useContext(AppContext);
  // get the order id passed from purchases
  const location = useLocation();
  const orderIdPassed = location.state && location.state.orderIds[0];
  const issueType = "Failed Transaction";
  // state to set error message
  const [errorMessage, setErrorMessage] = useState("");
  //state to set the modal to open or close
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { inputRef: inputRef1, handleDivClick: handleDivClick1 } =
    useFocusOnClick();
  const { inputRef: inputRef2, handleDivClick: handleDivClick2 } =
    useFocusOnClick();

  // handle order id input change
  const handleOrderIdChange = (e) => {
    setErrorMessage("");
    setOrderId(e.target.value);
  };

  if (orderIdPassed) {
    setOrderId(orderIdPassed);
  }
  // handle message input change
  const handleMessageChange = (e) => {
    setErrorMessage("");
    setMessage(e.target.value);
  };

  const handleSubmitProceedButton = async () => {
    if (!orderId) {
      setErrorMessage("Please enter your order id!");
    } else if (!message) {
      setErrorMessage("Please enter the message!");
    } else {
      setErrorMessage("");
      setModalIsOpen(true);
      try {
        // Prepare the support ticket data
        const supportTicketData = {
          message: message,
          orderID: orderId,
          ticketType: orderIdPassed ? issueType : userModeSelection,
          ticketStatus: "Submitted",
          ticketReply: "",
          createdTimestamp: new Date().getTime(),
        };

        // Create a reference to the user's profile using the email
        const profileRef = doc(db, "ticketCollection", profileEmail); // Assuming "profiles" is the collection name

        // Create the user's profile document if it doesn't exist
        await setDoc(profileRef, {}); // Create an empty document if none exists

        // Create a reference to the user's ticket list under their profile
        const ticketListRef = collection(profileRef, "ticketList");

        // Add the support ticket data to the ticket list
        await addDoc(ticketListRef, supportTicketData);

        console.log("Support ticket submitted successfully!");

        if (process.env.REACT_APP_DEPLOYMENT_MODE === "PRODUCTION") {
          // Track the feedback event with additional properties
          mixpanel.track("Raising Service Ticket!", {
            ticketType: supportTicketData.ticketType, // Track the ticket type (e.g., based on issueType or userModeSelection)
            orderID: orderId, // Track the order ID associated with the feedback
            messageLength: message.length, // Track the length of the message
            ticketStatus: supportTicketData.ticketStatus, // Track the ticket status (e.g., "Submitted")
            createdTimestamp: supportTicketData.createdTimestamp, // Track the creation timestamp
          });
        }

        // Fetch updated tickets after submission (if needed)
      } catch (error) {
        console.error("Error adding support ticket document: ", error);
        setErrorMessage(
          "Failed to submit the support ticket. Please try again."
        );
        setModalIsOpen(false);
      }
    }
  };

  const handleSubmitBackButton = () => {
    setSupportFlowPageNumber(1);
  };

  const CardTitle = () => (
    <div
      className={cx(styles.supportTicketTitle, {
        [styles.supportTicketTitleMob]: isMobile,
      })}
    >
      {SUPPORT_ENUM.supportTicket}
    </div>
  );

  const CardBody = () => (
    <div
      className={cx(styles.failedTransactionMainContainer, {
        [styles.failedTransactionMainContainerMob]: isMobile,
      })}
    >
      <div
        className={cx(styles.fillInputField, {
          [styles.fillInputFieldMob]: isMobile,
        })}
        onClick={handleDivClick1}
      >
        <div
          className={cx(styles.inputkey, {
            [styles.inputkeyMob]: isMobile,
          })}
        >
          {SEND_ENUM.orderId}
        </div>
        <div
          className={cx(styles.inputValue, {
            [styles.inputValueMob]: isMobile,
          })}
        >
          <input
            type="text"
            ref={inputRef1} // Attach the ref to the input element
            value={orderIdPassed ? orderIdPassed : orderId}
            onChange={handleOrderIdChange}
            autoComplete="off"
            className={styles.orderId}
          />
        </div>
      </div>
      <div
        className={cx(styles.fillInputField, {
          [styles.fillInputFieldMob]: isMobile,
        })}
        onClick={handleDivClick2}
      >
        <div
          className={cx(styles.inputkey, {
            [styles.inputkeyMob]: isMobile,
          })}
        >
          {SUPPORT_ENUM.message}
        </div>
        <div
          className={cx(styles.inputValue, {
            [styles.inputValueMob]: isMobile,
          })}
        >
          <textarea
            type="text"
            ref={inputRef2}
            value={message}
            onChange={handleMessageChange}
            autoComplete="off"
            className={styles.message}
            rows={3} // Display up to 3 lines
          />
        </div>
      </div>

      <div
        className={cx(styles.errorMessage, {
          [styles.errorMessageMob]: isMobile,
        })}
      >
        {errorMessage}
      </div>

      <div
        className={cx(styles.proceedButtonDiv, {
          [styles.proceedButtonDivMob]: isMobile,
        })}
      >
        {/* Custom styled cancel button */}
        <ButtonRade
          outline
          customStyling={cx(styles.cancelButton, {
            [styles.cancelButtonMob]: isMobile,
          })}
          onClick={handleSubmitBackButton}
        >
          {SEND_ENUM.back}
        </ButtonRade>
        {/* Custom styled button for proceed */}
        <ButtonRade
          outline
          customStyling={cx(styles.proceedButton, {
            [styles.proceedButtonMob]: isMobile,
          })}
          onClick={() => {
            handleSubmitProceedButton();
          }}
        >
          {SUPPORT_ENUM.create}
          <NextIcon />
        </ButtonRade>
      </div>
    </div>
  );

  return (
    <>
      <Card titleComponent={<CardTitle />}>{CardBody()}</Card>
      {/* Code for opening the modal */}
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <ServiceTicketSubmit
          setModalIsOpen={setModalIsOpen}
          setOrderId={setOrderId}
          setMessage={setMessage}
          setUserModeSelection={setUserModeSelection}
        />
      </Modal>
    </>
  );
};

export default FailedTransaction;
