import axios from "axios";
import Modal from "react-modal";
import React, { useContext, useState, useEffect, useRef } from "react";
import cx from "classnames";
import { AppContext } from "../../context";
import Card from "../../components/Card/Card";
import { SEND_ENUM } from "../../enums/sendEnum";
import RadeButton from "../../components/RadeButtons";
import SelectBox from "../../components/SelectBox/SelectBox";
import styles from "./index.module.scss";
import {
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
  query,
  where,
} from "firebase/firestore";
import { firestore, auth } from "../../firebase";
import AcceptsTermsToContinuePopUp from "../AcceptsTermsToContinuePopUp/AcceptsTermsToContinuePopUp";
import { v4 as uuidv4 } from "uuid";
import StateDropdown from "../../components/StateDropdown/StateDropdown";
import stateCodes from "../../Data/INStates.json";
import indianFlag from "../../assets/indiaFlagIcon.svg";
import ButtonRade from "../../components/RadeButtons";
import RadioButton from "../../components/RadioButton/RadioButton";
import SupporetdBankAccount from "../SupportedBankAccountModal/SupportedBankAccountModal";
import mixpanel from "mixpanel-browser";
import { useLocation } from "react-router-dom";
import SelectRecipientModal from "../SelectRecipientModal/SelectRecipientModal";
import proceedIcon from "../../assets/ProceedIcon.svg";
import clearIcon from "../../assets/clearIcon.svg";
import SelectStateModal from "../SelectStateModal/SelectStateModal";

const EnterReceiverDetails = ({
  setSendFlowPageNumber,
  profileEmail,
  setRecipientId,
  sendFlowPageNumber,
  setCybridCounterpartyGuid,
  onNextFromRecurring,
  onBackFromRecurring,
}) => {
  const inputRef = useRef(null);
  const { isMobile, setIsNavbarClicked, userData, transactionId } =
    useContext(AppContext);
  const location = useLocation();
  const isRecurringTransfersCreateRoute = () =>
    location.pathname === "/autoSend/create";
  // State for storing the first name
  const [firstName, setFirstName] = useState("");
  // State for storing the last name
  const [lastName, setLastName] = useState("");
  // State for storing the street address
  const [street, setStreet] = useState("");
  // State for storing the city
  const [city, setCity] = useState("");
  // State for storing the state
  const [state, setState] = useState("");
  // State for storing the postal code
  const [postalCode, setPostalCode] = useState("");
  // state for storing phone number
  const [phoneNumber, setPhoneNumber] = useState("");
  // state for storing account number
  const [accountNumber, setAccountNumber] = useState("");
  // state for storing confirmed account number
  const [confirmAccountNumber, setConfirmAccountNumber] = useState("");
  // state for storing the account number from selected from the dropdown
  const [selectedRecipient, setSelectedRecipient] = useState("");
  // state for storing the account details fetch from the firebase
  const [storedRecipients, setStoredRecipients] = useState([]);
  // state for storing IFSC code
  const [IFSCCode, setIFSCCode] = useState("");
  // state for storing the fetched bank details
  const [searchedBankDetails, setSearchedBankDetails] = useState(null);
  // state for storing the nick name
  const [nickName, setNickName] = useState(null);
  // state for opening the modal
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // state that represents whether the user selects a nick name from the dropdown or not
  const [isNicknameSelected, setIsNicknameSelected] = useState(false);
  // state to store email
  const [email, setEmail] = useState(null);
  // State to set error message
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedStateName, setSelectedStateName] = useState(""); // State for displaying the name
  const [selectBankType, setSelectBankType] = useState(""); //state for storing the status input
  // State for controlling different modal open based on page number
  const [modalPageNumber, setModalPageNumber] = useState(false);
  // For showing the spinner in modal component continue button until all the functionalities are resolved in the proceed button on click
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsNavbarClicked(true);
    inputRef.current.focus();
  }, []);
  //   Radio button options
  const BankTypeOptions = [
    { value: "NRE", label: "NRE         " },
    { value: "NRO/Savings", label: "NRO/Savings" },
  ];
  //   Function to handle the status switching of the transfer
  const handleClickBankType = (e) => {
    setErrorMessage("");
    setSelectBankType(e.target.value);
  };
  // function to handle email input field
  const handleEmailInput = (e) => {
    setErrorMessage("");
    const enteredValue = e.target.value;
    const limitedValue = enteredValue.slice(0, 50); // Limit to 50 characters
    setEmail(limitedValue);
  };
  // email validation
  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
    return regex.test(email);
  };
  // function to handle nickname input field
  const handleNickName = (e) => {
    setErrorMessage("");
    const value = e.target.value;
    setNickName(value);
  };
  // function to handle first name input field
  const handleFirstName = (e) => {
    setErrorMessage("");
    const enteredValue = e.target.value;
    const limitedValue = enteredValue.slice(0, 50); // Limit to 50 characters
    setFirstName(limitedValue);
  };
  // function to handle last name input field
  const handleLastName = (e) => {
    setErrorMessage("");
    const enteredValue = e.target.value;
    const limitedValue = enteredValue.slice(0, 50); // Limit to 50 characters
    setLastName(limitedValue);
  };
  // function to handle city input field
  const handleCityChange = (e) => {
    setErrorMessage("");
    const enteredValue = e.target.value;
    const limitedValue = enteredValue.slice(0, 100); // Limit to 100 characters
    setCity(limitedValue);
  };
  // function to handle postal code input field
  const handlePostalChange = (e) => {
    setErrorMessage("");
    const enteredValue = e.target.value;
    const numericValue = enteredValue.replace(/\D/g, ""); // Remove non-digit characters
    const limitedValue = numericValue.slice(0, 6); // Limit to 6 digits
    setPostalCode(limitedValue);
  };
  // postal code validation
  const isValidPostalCode = (postalCode) => {
    const regex = /^[1-9][0-9]{5}$/; // PIN code must be 6 digits and cannot start with 0
    return regex.test(postalCode);
  };
  // function that handles the confirm account number input field
  const handleConfirmAccNum = (e) => {
    setErrorMessage("");
    const enteredValue = e.target.value;
    const numericValue = enteredValue.replace(/\D/g, ""); // Remove non-digit characters
    const limitedValue = numericValue.slice(0, 16); // Limit to 16 digits
    setConfirmAccountNumber(limitedValue);
  };
  // function to handle ifsc code input field
  const handleIFSCCodeChange = (e) => {
    const enteredValue = e.target.value.toUpperCase();
    const limitedValue = enteredValue.slice(0, 11);
    setErrorMessage("");
    setSearchedBankDetails("");
    setIFSCCode(limitedValue);
  };
  // Function that handles the phone number input
  const handlePhoneNumberChange = (e) => {
    setErrorMessage("");
    const enteredValue = e.target.value;
    const numericValue = enteredValue.replace(/\D/g, ""); // Remove non-digit characters
    const limitedValue = numericValue.slice(0, 10); // Limit to 10 digits
    setPhoneNumber(limitedValue);
  };
  // function that handles the account number input field
  const handleAccountNumber = (e) => {
    setErrorMessage("");
    const enteredValue = e.target.value;
    const numericValue = enteredValue.replace(/\D/g, ""); // Remove non-digit characters
    const limitedValue = numericValue.slice(0, 16); // Limit to 10 digits
    setAccountNumber(limitedValue);
  };
  // function to handle street input field
  const handleStreetAddress = (e) => {
    setErrorMessage("");
    const enteredValue = e.target.value;
    const limitedValue = enteredValue.slice(0, 100); // Limit to 100 characters
    setStreet(limitedValue);
  };
  // function to handle state input field
  const handleStateChange = (selectedOption) => {
    setErrorMessage("");
    setSelectedStateName(selectedOption.label); // Display the state name
    setState(selectedOption.value); // Store the state code
  };

  // Helper function to remove non-letter characters and accents
  const sanitizeName = (name) => {
    // Normalize the string and remove accents (diacritics) using regex
    const normalizedValue = name
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    // Remove any non-letter characters (numbers, special characters, spaces)
    return normalizedValue.replace(/[^a-zA-Z]/g, "");
  };

  // proceed button on click functionality
  const handleClickProceed = async () => {
    // If fields are empty then display error message
    if (!selectBankType) {
      setErrorMessage("Please select the type of bank account.");
    } else if (!nickName) {
      setErrorMessage("Please enter a nick name.");
    } else if (!firstName) {
      setErrorMessage("Please enter a first name.");
    } else if (!lastName) {
      setErrorMessage("Please enter a last name.");
    } else if (!phoneNumber) {
      setErrorMessage("Please enter a phone number.");
    } else if (phoneNumber && !(phoneNumber.length === 10)) {
      setErrorMessage("Please enter a valid phone number.");
    } else if (!email) {
      setErrorMessage("Please enter an email.");
    } else if (email && !isValidEmail(email)) {
      setErrorMessage("Please enter a valid email address.");
    } else if (!street) {
      setErrorMessage("Please enter the street address.");
    } else if (!city) {
      setErrorMessage("Please enter the city.");
    } else if (!state) {
      setErrorMessage("Please enter the state.");
    } else if (!accountNumber) {
      setErrorMessage("Please enter an account number.");
    } else if (!confirmAccountNumber) {
      setErrorMessage("Please confirm the account number.");
    } else if (accountNumber !== confirmAccountNumber) {
      setErrorMessage("Account numbers do not match.");
    } else if (!IFSCCode) {
      setErrorMessage("Please enter an IFSC code.");
    } else if (!searchedBankDetails) {
      setErrorMessage("Please check the IFSC code entered.");
    } else if (!postalCode) {
      setErrorMessage("Please enter the postal code.");
    } else if (postalCode && !isValidPostalCode(postalCode)) {
      setErrorMessage("Please enter a valid postal code for India.");
    } else if (selectBankType === "NRE") {
      setModalIsOpen(true);
      setModalPageNumber(1);
    } else {
      try {
        setModalIsOpen(true);
        setModalPageNumber(0);
        setIsLoading(true);
        // Get logged-in user's email (sender)
        const senderEmail = profileEmail;
        // Reference to the counterpartyDetails subcollection under the sender's email
        const counterpartyDetailsRef = collection(
          firestore,
          "counterpartyDetails",
          senderEmail,
          "userCounterpartyDetails"
        );
        // Check if the same nickname already exists for this sender
        const q = query(
          counterpartyDetailsRef,
          where("counterparty_pii.nick_name", "==", nickName)
        );
        const querySnapshot = await getDocs(q);
        // if not
        if (querySnapshot.empty) {
          // Nickname is unique; proceed to create a new recipient
          const recipientID = uuidv4(); // Generate unique ID for the recipient
          // Construct the recipient details object
          const recipientDetailsData = {
            counterparty_pii: {
              nick_name: nickName,
              email: email,
              phone_no: phoneNumber,
              account_no: accountNumber,
              ifsc_code: IFSCCode,
              bank_name: searchedBankDetails.BANK,
              date_of_birth: "1980-03-02",
              name: {
                first: sanitizeName(firstName),
                last: sanitizeName(lastName),
                full: `${sanitizeName(firstName)} ${sanitizeName(lastName)}`,
              },
              address: {
                street: street,
                city: city,
                subdivision: state,
                postal_code: postalCode,
                country_code: "IN",
              },
            },
            sender_email: senderEmail, // Adding the sender's email to track who added this recipient
          };
          // Add recipient details to the counterpartyDetails subcollection with recipientID as document ID
          await setDoc(
            doc(counterpartyDetailsRef, recipientID),
            recipientDetailsData
          );
          // Reference to userData for the logged-in user
          const userDataRef = doc(firestore, "userData", senderEmail);
          // Now update the userData with the recipientID in beneficiary_details
          const userDocSnap = await getDoc(userDataRef);
          // Check if user document exists before updating
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            // Update beneficiary_details by adding recipientID
            const updatedBeneficiaryDetails = {
              ...userData.beneficiary_details,
              ...{
                [Object.keys(userData.beneficiary_details).length]: recipientID,
              },
            };
            // Update the userData document with the new beneficiary_details
            await updateDoc(userDataRef, {
              beneficiary_details: updatedBeneficiaryDetails,
            });
            setRecipientId(recipientID);
            console.log(
              "Recipient added and beneficiaryDetails updated successfully"
            );
            // Counterparty creation only if new recipient
            try {
              // The beneficiary details to be passed while creating the counter party.
              const beneficiaryDetails = {
                nick_name: nickName,
                email: email,
                phone_no: phoneNumber,
                account_no: accountNumber,
                ifsc_code: IFSCCode,
                bank_name: searchedBankDetails.BANK,
                receiver_id: recipientID,
                counterparty_pii: {
                  type: "individual", // hardcode this for now
                  customer_guid: userData.cybrid_customer_details.customer_guid,
                  name: {
                    first: sanitizeName(firstName),
                    last: sanitizeName(lastName),
                    full: `${sanitizeName(firstName)} ${sanitizeName(
                      lastName
                    )}`,
                  },
                  address: {
                    street: street,
                    city: city,
                    subdivision: state,
                    postal_code: postalCode,
                    country_code: "IN",
                  },
                  date_of_birth: "1980-03-02",
                  labels: [nickName],
                },
              };
              // Obtain ID token
              const idToken = await auth.currentUser.getIdToken(true);
              const requestOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${idToken}`, // Include the ID token in the headers
                },
                body: JSON.stringify(beneficiaryDetails),
              };
              const response = await fetch(
                process.env.REACT_APP_PAYEZY_SERVER_URI +
                  `/create-counterparty/${userData.cybrid_customer_details.customer_guid}`,
                requestOptions
              );
              if (response.ok) {
                // Request was successful
                const responseData = await response.json();
                setCybridCounterpartyGuid(responseData.counterparty.guid);
                // Update recipientDetailsData to include cybrid_counterparty_details
                recipientDetailsData.cybrid_counterparty_details = {
                  ...responseData.counterparty,
                };
                // Now you can proceed to save recipientDetailsData to Firestore
                await setDoc(
                  doc(counterpartyDetailsRef, recipientID),
                  recipientDetailsData
                );
                if (process.env.REACT_APP_DEPLOYMENT_MODE === "PRODUCTION") {
                  mixpanel.track("Adding Beneficiary!");
                }
              } else {
                console.log("beneficiaryDetails:", beneficiaryDetails);
                console.error("Failed to fetch counter party response");
              }
            } catch (error) {
              console.error("Error:", error);
            }
            // At this point, we have the new recipientID. Now, update the receiver_id with this recipient ID
            if (!isRecurringTransfersCreateRoute()) {
              // Check if not in recurring transfer route
              const transactionsRef = collection(
                firestore,
                "transactions",
                senderEmail,
                "userTransactions"
              );
              const transactionDocRef = doc(transactionsRef, transactionId);

              await updateDoc(transactionDocRef, {
                receiver_id: recipientID,
              });
              console.log("transactionDetails updated successfully");
            }
            // Once all async operations are complete:
            setIsLoading(false);
          } else {
            console.error("User data not found.");
          }
        } else {
          // Existing account details found with the same nickname
          const matchedRecipient = querySnapshot.docs[0].data();
          const matchedRecipientCybridCounterpartyDetails =
            matchedRecipient.cybrid_counterparty_details;
          // Compare entered details with stored details
          const {
            counterparty_pii: {
              phone_no: storedPhoneNumber,
              account_no: storedAccountNumber,
              ifsc_code: storedIFSCCode,
              email: storedEmail,
              address: {
                street: storedStreet,
                city: storedCity,
                subdivision: storedState,
                postal_code: storedPostalCode,
              },
              name: { first: storedFirstName, last: storedLastName },
            },
          } = matchedRecipient;
          if (
            firstName === storedFirstName &&
            lastName === storedLastName &&
            phoneNumber === storedPhoneNumber &&
            accountNumber === storedAccountNumber &&
            email === storedEmail &&
            IFSCCode === storedIFSCCode &&
            street === storedStreet &&
            city === storedCity &&
            state === storedState &&
            postalCode === storedPostalCode
          ) {
            // All details match; proceed with the existing recipient
            // If recipient already exists, fetch the existing recipient ID
            const existingRecipientID = querySnapshot.docs[0].id;
            setRecipientId(existingRecipientID);
            setModalIsOpen(true);
            setModalPageNumber(0);
            setIsLoading(true);
            // Check if bank_name is missing in the matched recipient
            if (!matchedRecipient.counterparty_pii.bank_name) {
              // Add the missing bank_name field to the existing recipient
              await updateDoc(
                doc(counterpartyDetailsRef, existingRecipientID),
                {
                  "counterparty_pii.bank_name": searchedBankDetails.BANK, // Correctly update nested field
                }
              );
            }
            // If cybrid_counterparty_details are missing, create a new counterparty
            if (!matchedRecipientCybridCounterpartyDetails) {
              try {
                const beneficiaryDetails = {
                  nick_name: nickName,
                  email: email,
                  phone_no: phoneNumber,
                  account_no: accountNumber,
                  ifsc_code: IFSCCode,
                  bank_name: searchedBankDetails.BANK,
                  receiver_id: existingRecipientID, // Existing recipient ID
                  counterparty_pii: {
                    type: "individual",
                    customer_guid:
                      userData.cybrid_customer_details.customer_guid,
                    name: {
                      first: sanitizeName(firstName),
                      last: sanitizeName(lastName),
                      full: `${sanitizeName(firstName)} ${sanitizeName(
                        lastName
                      )}`,
                    },
                    address: {
                      street: street,
                      city: city,
                      subdivision: state,
                      postal_code: postalCode,
                      country_code: "IN",
                    },
                    date_of_birth: "1980-03-02",
                    labels: [nickName],
                  },
                };

                const idToken = await auth.currentUser.getIdToken(true);
                const requestOptions = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${idToken}`,
                  },
                  body: JSON.stringify(beneficiaryDetails),
                };

                const response = await fetch(
                  process.env.REACT_APP_PAYEZY_SERVER_URI +
                    `/create-counterparty/${userData.cybrid_customer_details.customer_guid}`,
                  requestOptions
                );

                if (response.ok) {
                  const responseData = await response.json();
                  matchedRecipient.cybrid_counterparty_details =
                    responseData.counterparty;

                  // Update the recipient document with cybrid_counterparty_details
                  await setDoc(
                    doc(counterpartyDetailsRef, existingRecipientID),
                    matchedRecipient
                  );
                  setCybridCounterpartyGuid(responseData.counterparty.guid);
                  if (process.env.REACT_APP_DEPLOYMENT_MODE === "PRODUCTION") {
                    mixpanel.track("Adding Beneficiary!");
                  }
                }
              } catch (error) {
                console.error(
                  "Error creating counterparty for existing recipient:",
                  error
                );
              }
            } else {
              setCybridCounterpartyGuid(
                matchedRecipientCybridCounterpartyDetails.guid
              );
            }
            // At this point, we have the existing recipientID. Now, update the receiver_id with this recipient ID
            if (!isRecurringTransfersCreateRoute()) {
              // Check if not in recurring transfer route
              const transactionsRef = collection(
                firestore,
                "transactions",
                senderEmail,
                "userTransactions"
              );
              const transactionDocRef = doc(transactionsRef, transactionId);

              await updateDoc(transactionDocRef, {
                receiver_id: existingRecipientID,
              });
            }
            console.log("Using existing account details");
            // Once all async operations are complete:
            setIsLoading(false);
          } else {
            setModalIsOpen(false);
            // If details do not match
            setErrorMessage(
              "Nickname is already in use with different details"
            );
          }
        }
      } catch (error) {
        console.error("Error storing recipient details in Firebase: ", error);
        setIsLoading(false); // Stop loading even if there's an error
      }
    }
  };
  // code to fecth the bank branch details based on the ifsc entered
  const fetchBankDetails = async (searchedIFSCCode, setSearchedBankDetails) => {
    try {
      // Make an API call to fetch bank details based on the searchedIFSCCode
      const response = await axios.get(
        `https://ifsc.razorpay.com/${searchedIFSCCode}`
      );
      // Update the searchedBankDetails state with the fetched address
      setSearchedBankDetails(response.data);
      setErrorMessage("");
    } catch (error) {
      console.error("Error fetching bank details: ", error);
      setErrorMessage(
        "IFSC should be 4 letters, followed by 7 letters or digits."
      );
    }
  };
  const handleClickCheckIfsc = () => {
    if (!IFSCCode) {
      setErrorMessage("Please enter the IFSC code first.");
    } else {
      fetchBankDetails(IFSCCode, setSearchedBankDetails);
    }
  };
  useEffect(() => {
    if (selectedRecipient && IFSCCode) {
      fetchBankDetails(IFSCCode, setSearchedBankDetails);
    }
  }, [selectedRecipient, IFSCCode]);

  useEffect(() => {
    // Fetch stored recipient details from Firebase
    const fetchRecipientDetails = async () => {
      try {
        // Get logged-in user's email (sender)
        const senderEmail = profileEmail;

        // Reference to userData for the logged-in user
        const userDataRef = doc(firestore, "userData", senderEmail);
        const userDocSnap = await getDoc(userDataRef);

        if (userDocSnap.exists()) {
          // Get user data including the recipient IDs
          const userData = userDocSnap.data();
          // Extract recipient IDs from beneficiary_details object
          const recipientIDs = Object.values(
            userData.beneficiary_details || {}
          );

          // Fetch recipient details based on the stored recipient IDs
          const recipients = await Promise.all(
            recipientIDs.map(async (recipientID) => {
              // Now you need to use the correct path including senderEmail
              const recipientRef = doc(
                firestore,
                "counterpartyDetails",
                senderEmail, // Add the user email as part of the path
                "userCounterpartyDetails", // Use the subcollection name
                recipientID
              );
              const recipientSnap = await getDoc(recipientRef);
              return recipientSnap.exists() ? recipientSnap.data() : null;
            })
          );
          // Filter out any null values in case of missing documents
          const validRecipients = recipients.filter((recipient) => recipient);
          // Store the fetched recipients in the state
          setStoredRecipients(validRecipients);
        }
      } catch (error) {
        console.error(
          "Error fetching recipient details from Firebase: ",
          error
        );
      }
    };
    // Fetch recipient details when the component is mounted
    fetchRecipientDetails();
  }, [profileEmail]);

  useEffect(() => {
    if (!selectedRecipient) {
      setIsNicknameSelected(false);
      setSelectedRecipient("");
      setNickName("");
      setFirstName("");
      setLastName("");
      setPhoneNumber("");
      setAccountNumber("");
      setConfirmAccountNumber("");
      setIFSCCode("");
      setSearchedBankDetails("");
      setEmail("");
      setStreet("");
      setCity("");
      setState("");
      setPostalCode("");
      setSelectedStateName("");
    }
  }, [selectedRecipient, setSelectedRecipient]);
  // Function that handles the drop-down
  const handleRecipientSelection = (selectedRecipient) => {
    if (selectedRecipient) {
      // Find the selected account details based on the nickname
      const selectedRecipientDetails = storedRecipients.find((recipient) => {
        return recipient.counterparty_pii.nick_name === selectedRecipient.value; // Compare with nick_name inside counterparty_pii
      });

      // Update the state values with the selected account details
      if (selectedRecipientDetails) {
        const {
          nick_name,
          phone_no,
          account_no,
          ifsc_code,
          email,
          name: { first, last },
          address: { street, city, subdivision, postal_code },
        } = selectedRecipientDetails.counterparty_pii; // Destructure the fields from counterparty_pii
        console.log("subdivision", subdivision);
        // Now update the state with the selected recipient's details
        setNickName(nick_name);
        setSelectedRecipient(nick_name); // Set the selected nickname
        setPhoneNumber(phone_no);
        setAccountNumber(account_no);
        setConfirmAccountNumber(account_no); // Confirm account number
        setIFSCCode(ifsc_code);
        setEmail(email);
        setFirstName(first);
        setLastName(last);
        setStreet(street);
        setCity(city);
        setState(subdivision);
        setPostalCode(postal_code);
        setIsNicknameSelected(true); // Mark that a nickname is selected
        // Find and set the state name based on the stored state code
        const stateInfo = stateCodes.find(
          (state) => state.code === subdivision
        );
        if (stateInfo) {
          setSelectedStateName(stateInfo.name); // Set the state name for display
        }
      }
    }
  };

  const handleErrorDismiss = () => {
    setErrorMessage(""); // reset the error message state
  };

  // Function that handles the back button
  const handleClickBackButton = () => {
    setSendFlowPageNumber(sendFlowPageNumber - 1);
  };
  const handleClickNickNameSelector = () => {
    setModalIsOpen(true);
    setModalPageNumber(2);
  };
  const handleClickStateSelector = () => {
    setModalIsOpen(true);
    setModalPageNumber(3);
  };
  // Function to handle recipient selection in mobile
  const handleSelectRecipient = (recipient) => {
    console.log("recipient", recipient);
    setSelectedRecipient(recipient.counterparty_pii.nick_name);
    setNickName(recipient.counterparty_pii.nick_name);
    setPhoneNumber(recipient.counterparty_pii.phone_no);
    setAccountNumber(recipient.counterparty_pii.account_no);
    setConfirmAccountNumber(recipient.counterparty_pii.account_no);
    setIFSCCode(recipient.counterparty_pii.ifsc_code);
    setEmail(recipient.counterparty_pii.email);
    setFirstName(recipient.counterparty_pii.name.first);
    setLastName(recipient.counterparty_pii.name.last);
    setStreet(recipient.counterparty_pii.address.street);
    setCity(recipient.counterparty_pii.address.city);
    setState(recipient.counterparty_pii.address.subdivision);
    setPostalCode(recipient.counterparty_pii.address.postal_code);
    // Find and set the state name based on the stored state code
    const stateInfo = stateCodes.find(
      (state) => state.code === recipient.counterparty_pii.address.subdivision
    );
    if (stateInfo) {
      setSelectedStateName(stateInfo.name); // Set the state name for display
    }
    setIsNicknameSelected(true); // Mark that a nickname is selected
    setModalIsOpen(false);
  };

  const handleSelectState = (state) => {
    setState(state.code);
    setSelectedStateName(state.name);
    setModalIsOpen(false);
  };
  const handleClearSelectedNickName = () => {
    setIsNicknameSelected(false);
    setSelectedRecipient("");
    setNickName("");
    setFirstName("");
    setLastName("");
    setPhoneNumber("");
    setAccountNumber("");
    setConfirmAccountNumber("");
    setIFSCCode("");
    setSearchedBankDetails("");
    setEmail("");
    setStreet("");
    setCity("");
    setState("");
    setPostalCode("");
    setSelectedStateName("");
  };
  const handleClearSelectedState = () => {
    setState("");
    setSelectedStateName("");
  };

  const CardTitle = () => (
    <div
      className={cx(styles.enterAmountOfUSDWishToSend, {
        [styles.enterAmountOfUSDWishToSendMob]: isMobile,
      })}
    >
      {SEND_ENUM.enterTheDetailsOfReciep}
    </div>
  );
  const CardBody = () => (
    <div
      className={cx(styles.sendRUDMainContainer, {
        [styles.sendRUDMainContainerMob]: isMobile,
        [styles.sendRUDMainContainerIfRecurringRoute]:
          isRecurringTransfersCreateRoute(),
      })}
    >
      {isRecurringTransfersCreateRoute() && (
        <div className={styles.headerContainerIfRecurringRoute}>
          {SEND_ENUM.enterTheDetailsOfReciep}
        </div>
      )}
      <div
        className={cx(styles.disclaimerContainer, {
          [styles.disclaimerContainerMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.disclaimerContent, {
            [styles.disclaimerContentMob]: isMobile,
          })}
        >
          {SEND_ENUM.beneficiaryDisclaimer}
        </div>
      </div>
      <div
        className={cx(styles.selectBankAccountContainer, {
          [styles.selectBankAccountContainerMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.selectBankAccount, {
            [styles.selectBankAccountMob]: isMobile,
          })}
        >
          {SEND_ENUM.selectBankAccount}
        </div>
        <div
          className={cx(styles.radioButtonContainer, {
            [styles.radioButtonContainerMob]: isMobile,
          })}
        >
          <RadioButton
            name="bankTypeOptions"
            value={selectBankType}
            options={BankTypeOptions}
            onChangeFunc={handleClickBankType}
          />
        </div>
      </div>

      <div className={styles.accountDropdownContainer}>
        {isMobile
          ? storedRecipients.length !== 0 && (
              <div
                className={styles.nickNameSelectContainerMob}
                onClick={handleClickNickNameSelector}
              >
                {isNicknameSelected ? selectedRecipient : "Select a nickname"}
                <div className={styles.nickNameIcons}>
                  {isNicknameSelected && (
                    <img
                      src={clearIcon}
                      alt="clearIcon"
                      className={styles.clearIcon}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent the click from bubbling up to the parent div
                        handleClearSelectedNickName();
                      }}
                    />
                  )}
                  {!isNicknameSelected && (
                    <img
                      src={proceedIcon}
                      alt="proceedIcon"
                      className={styles.proceedIcon}
                    />
                  )}
                </div>
              </div>
            )
          : storedRecipients.length !== 0 && (
              <div className={styles.accountDropdownValue}>
                <SelectBox
                  placeHolder="Select a nickname"
                  options={storedRecipients.map((recipient) => ({
                    label: recipient.counterparty_pii.nick_name, // Accessing nick_name from counterparty_pii
                    value: recipient.counterparty_pii.nick_name, // Accessing nick_name from counterparty_pii
                    key: recipient.counterparty_pii.account_no, // Use account_no for uniqueness
                  }))}
                  onChange={handleRecipientSelection} // Handles account selection
                  setSelectedValue={setSelectedRecipient} // Tracks selected value
                  selectedValue={selectedRecipient || null} // Currently selected nickname
                  errorDismissOnclick={handleErrorDismiss}
                />
              </div>
            )}
      </div>
      <div className={styles.detailsCollectingContainer}>
        <div
          className={cx(styles.nickNameContainer, {
            [styles.nickNameContainerMob]: isMobile,
          })}
        >
          <div className={styles.sendRUDDiv}>
            <div className={styles.sendingRUD}>{SEND_ENUM.nickName} *</div>
          </div>
          <div className={styles.fullNameValue}>
            <input
              ref={inputRef}
              type="text"
              placeholder="Enter a nick name"
              value={nickName}
              onChange={handleNickName}
              disabled={isNicknameSelected}
              autoComplete="off"
              className={cx(styles.inputAmount, {
                [styles.inputAmountMob]: isMobile,
                [styles.disabledInput]: isNicknameSelected, // Add the disabledInput class when isNicknameSelected is true
              })}
            />
          </div>
        </div>
        <div
          className={cx(styles.flexContainer, {
            [styles.flexContainerMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.sendRUD, {
              [styles.sendRUDMob]: isMobile,
            })}
          >
            <div className={styles.sendRUDDiv}>
              <div className={styles.sendingRUD}>{SEND_ENUM.firstName} *</div>
            </div>
            <div className={styles.fullNameValue}>
              <input
                type="text"
                placeholder="Enter the first name [as per bank] "
                value={firstName}
                onChange={handleFirstName}
                disabled={isNicknameSelected}
                autoComplete="off"
                className={cx(styles.inputAmount, {
                  [styles.inputAmountMob]: isMobile,
                  [styles.disabledInput]: isNicknameSelected, // Add the disabledInput class when isNicknameSelected is true
                })}
              />
            </div>
          </div>
          <div
            className={cx(styles.sendRUD, {
              [styles.sendRUDMob]: isMobile,
            })}
          >
            <div className={styles.sendRUDDiv}>
              <div className={styles.sendingRUD}>{SEND_ENUM.lastName} *</div>
            </div>
            <div className={styles.fullNameValue}>
              <input
                type="text"
                placeholder="Enter the last name [as per bank] "
                value={lastName}
                onChange={handleLastName}
                disabled={isNicknameSelected}
                autoComplete="off"
                className={cx(styles.inputAmount, {
                  [styles.inputAmountMob]: isMobile,
                  [styles.disabledInput]: isNicknameSelected, // Add the disabledInput class when isNicknameSelected is true
                })}
              />
            </div>
          </div>
        </div>
        <div
          className={cx(styles.flexContainer, {
            [styles.flexContainerMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.sendRUD, {
              [styles.sendRUDMob]: isMobile,
            })}
          >
            <div className={styles.sendRUDDiv}>
              <div className={styles.sendingRUD}>{SEND_ENUM.phone} *</div>
              <div className={styles.flagContainer}>
                (+91)
                <div className={styles.iconContainer}>
                  <img
                    src={indianFlag}
                    alt="indianFlag"
                    className={styles.indianFlagIcon}
                  />
                </div>
              </div>
            </div>
            <div className={styles.fullNameValue}>
              <input
                type="number"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                disabled={isNicknameSelected}
                autoComplete="off"
                className={cx(styles.inputAmount, {
                  [styles.inputAmountMob]: isMobile,
                  [styles.disabledInput]: isNicknameSelected, // Add the disabledInput class when isNicknameSelected is true
                })}
              />
            </div>
          </div>
          <div
            className={cx(styles.sendRUD, {
              [styles.sendRUDMob]: isMobile,
            })}
          >
            <div className={styles.sendRUDDiv}>
              <div className={styles.sendingRUD}>{SEND_ENUM.email} *</div>
            </div>
            <div className={styles.fullNameValue}>
              <input
                type="email"
                value={email}
                disabled={isNicknameSelected}
                onChange={handleEmailInput}
                autoComplete="off"
                className={cx(styles.inputAmount, {
                  [styles.inputAmountMob]: isMobile,
                  [styles.disabledInput]: isNicknameSelected, // Add the disabledInput class when isNicknameSelected is true
                })}
              />
            </div>
          </div>
        </div>
        <div
          className={cx(styles.flexContainer, {
            [styles.flexContainerMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.sendRUD, {
              [styles.sendRUDMob]: isMobile,
            })}
          >
            <div className={styles.sendRUDDiv}>
              <div className={styles.sendingRUD}>
                {SEND_ENUM.addressLineOne} *
              </div>
            </div>
            <div className={styles.fullNameValue}>
              <input
                type="text"
                value={street}
                onChange={handleStreetAddress}
                disabled={isNicknameSelected}
                autoComplete="off"
                className={cx(styles.inputAmount, {
                  [styles.inputAmountMob]: isMobile,
                  [styles.disabledInput]: isNicknameSelected, // Add the disabledInput class when isNicknameSelected is true
                })}
              />
            </div>
          </div>
          <div
            className={cx(styles.sendRUD, {
              [styles.sendRUDMob]: isMobile,
            })}
          >
            <div className={styles.sendRUDDiv}>
              <div className={styles.sendingRUD}>
                {SEND_ENUM.addressLineTwo} *
              </div>
            </div>
            <div className={styles.fullNameValue}>
              <input
                type="text"
                value={city}
                onChange={handleCityChange}
                disabled={isNicknameSelected}
                autoComplete="off"
                className={cx(styles.inputAmount, {
                  [styles.inputAmountMob]: isMobile,
                  [styles.disabledInput]: isNicknameSelected, // Add the disabledInput class when isNicknameSelected is true
                })}
              />
            </div>
          </div>
        </div>
        <div
          className={cx(styles.flexContainer, {
            [styles.flexContainerMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.sendRUD, {
              [styles.sendRUDMob]: isMobile,
            })}
          >
            <div className={styles.sendRUDDiv}>
              <div className={styles.sendingRUD}>{SEND_ENUM.countryCode}</div>
              <div className={styles.flagContainer}>
                <img
                  src={indianFlag}
                  alt="indianFlag"
                  className={styles.indianFlagIcon}
                />
              </div>
            </div>
            <div className={styles.fullNameValue}>
              <input
                type="text"
                value="IN"
                disabled
                autoComplete="off"
                className={cx(styles.inputAmount, {
                  [styles.inputAmountMob]: isMobile,
                  [styles.disabledInput]: isNicknameSelected, // Add the disabledInput class when isNicknameSelected is true
                })}
              />
            </div>
          </div>
          <div
            className={cx(styles.sendRUD, {
              [styles.sendRUDMob]: isMobile,
            })}
          >
            {isMobile ? (
              isNicknameSelected ? (
                <div className={styles.selectedStateNameDisabled}>
                  {selectedStateName}
                </div>
              ) : (
                <div
                  className={styles.stateSelectContainerMob}
                  onClick={handleClickStateSelector}
                >
                  {selectedStateName ? (
                    <div className={styles.selectedStateNameMob}>
                      {selectedStateName}
                    </div>
                  ) : (
                    "Select state"
                  )}
                  <div className={styles.nickNameIcons}>
                    {selectedStateName && (
                      <img
                        src={clearIcon}
                        alt="clearIcon"
                        className={styles.clearIcon}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the click from bubbling up to the parent div
                          handleClearSelectedState();
                        }}
                      />
                    )}
                    {!selectedStateName && (
                      <img
                        src={proceedIcon}
                        alt="proceedIcon"
                        className={styles.proceedIcon}
                      />
                    )}
                  </div>
                </div>
              )
            ) : (
              <StateDropdown
                placeHolder="Select State"
                options={stateCodes.map((state) => ({
                  label: state.name, // Display state name
                  value: state.code, // Store state code
                }))}
                onChange={(selectedOption) => handleStateChange(selectedOption)} // Handle selection
                setSelectedValue={(selectedOption) =>
                  handleStateChange(selectedOption)
                } // Handle selection
                selectedValue={selectedStateName} // Display state name in the dropdown
                errorDismissOnclick={handleErrorDismiss}
                disabled={isNicknameSelected}
              />
            )}
          </div>
        </div>
        <div
          className={cx(styles.flexContainer, {
            [styles.flexContainerMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.sendRUD, {
              [styles.sendRUDMob]: isMobile,
            })}
          >
            <div className={styles.sendRUDDiv}>
              <div className={styles.sendingRUD}>
                {SEND_ENUM.accountNumber} *
              </div>
            </div>
            <div className={styles.fullNameValue}>
              <input
                type="password"
                value={accountNumber}
                onChange={handleAccountNumber}
                disabled={isNicknameSelected}
                autoComplete="off"
                className={cx(styles.inputAmount, {
                  [styles.inputAmountMob]: isMobile,
                  [styles.disabledInput]: isNicknameSelected, // Add the disabledInput class when isNicknameSelected is true
                })}
              />
            </div>
          </div>
          <div
            className={cx(styles.sendRUD, {
              [styles.sendRUDMob]: isMobile,
            })}
          >
            <div className={styles.sendRUDDiv}>
              <div className={styles.sendingRUD}>
                {SEND_ENUM.confirmAccountNumber} *
              </div>
            </div>
            <div className={styles.fullNameValue}>
              <input
                type="number"
                value={confirmAccountNumber}
                disabled={isNicknameSelected}
                onChange={handleConfirmAccNum}
                autoComplete="off"
                className={cx(styles.inputAmount, {
                  [styles.inputAmountMob]: isMobile,
                  [styles.disabledInput]: isNicknameSelected, // Add the disabledInput class when isNicknameSelected is true
                })}
              />
            </div>
          </div>
        </div>
        <div
          className={cx(styles.flexContainer, {
            [styles.flexContainerMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.sendRUD, {
              [styles.sendRUDMob]: isMobile,
            })}
          >
            <div className={styles.sendRUDDiv}>
              <div className={styles.sendingRUD}>{SEND_ENUM.IFSCCode} *</div>
            </div>
            <div className={styles.fullNameValue}>
              <input
                type="text"
                value={IFSCCode}
                onChange={handleIFSCCodeChange}
                disabled={isNicknameSelected}
                autoComplete="off"
                className={cx(styles.ifscInput, {
                  [styles.ifscInputMob]: isMobile,
                  [styles.disabledInput]: isNicknameSelected, // Add the disabledInput class when isNicknameSelected is true
                })}
              />
              <ButtonRade
                customStyling={cx(styles.checkIfscButton, {
                  [styles.checkIfscButtonMob]: isMobile,
                  [styles.disabledCheckIfscButton]: isNicknameSelected,
                  [styles.disabledAndMob]: isMobile && !isNicknameSelected,
                })}
                onClick={handleClickCheckIfsc}
                disabled={isNicknameSelected}
              >
                Check IFSC
              </ButtonRade>
            </div>
          </div>
          <div
            className={cx(styles.sendRUD, {
              [styles.sendRUDMob]: isMobile,
            })}
          >
            <div className={styles.sendRUDDiv}>
              <div className={styles.sendingRUD}>{SEND_ENUM.postalCode} *</div>
            </div>
            <div className={styles.postalCode}>
              <input
                type="number"
                value={postalCode}
                disabled={isNicknameSelected}
                onChange={handlePostalChange}
                autoComplete="off"
                className={cx(styles.inputAmount, {
                  [styles.inputAmountMob]: isMobile,
                  [styles.disabledInput]: isNicknameSelected, // Add the disabledInput class when isNicknameSelected is true
                })}
              />
            </div>
          </div>
        </div>
        <div
          className={cx(styles.getIFSCContainer, {
            [styles.getIFSCContainerMob]: isMobile,
          })}
        >
          {/* Display the fetched bank details */}
          {searchedBankDetails && (
            <>
              <div className={styles.bankDetailsKeyContainer}>
                <div className={styles.bankDetailsKey}>Bank:</div>
                <div className={styles.bankDetailsKey}>Branch:</div>
                <div className={styles.bankDetailsKey}>City:</div>
              </div>
              <div className={styles.bankDetailsValueContainer}>
                <div className={styles.bankDetailsValue}>
                  {searchedBankDetails.BANK}
                </div>
                <div className={styles.bankDetailsValue}>
                  {searchedBankDetails.BRANCH}
                </div>
                <div className={styles.bankDetailsValue}>
                  {searchedBankDetails.CITY}
                </div>
              </div>
            </>
          )}
        </div>
        <div className={styles.errorMessage}>{errorMessage}</div>
        <div
          className={cx(styles.buttonContainer, {
            [styles.buttonContainerMob]: isMobile,
          })}
        >
          <RadeButton
            className={styles.backButton}
            onClick={
              isRecurringTransfersCreateRoute()
                ? onBackFromRecurring
                : handleClickBackButton
            }
          >
            {SEND_ENUM.backButton}
          </RadeButton>
          <RadeButton
            customStyling={cx(styles.proceedButton, {
              [styles.proceedButtonMob]: isMobile,
            })}
            onClick={handleClickProceed}
          >
            {SEND_ENUM.proceedButton}
          </RadeButton>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.enterReceiverDetailsContainer}>
      {isRecurringTransfersCreateRoute() ? (
        CardBody()
      ) : (
        <Card titleComponent={<CardTitle />}>{CardBody()}</Card>
      )}
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        {(() => {
          switch (modalPageNumber) {
            case 0:
              return (
                <AcceptsTermsToContinuePopUp
                  setModalIsOpen={setModalIsOpen}
                  setSendFlowPageNumber={setSendFlowPageNumber}
                  sendFlowPageNumber={sendFlowPageNumber}
                  isLoading={isLoading}
                  onNextFromRecurring={onNextFromRecurring}
                  onBackFromRecurring={onBackFromRecurring}
                />
              );
            case 1:
              return <SupporetdBankAccount setModalIsOpen={setModalIsOpen} />;
            case 2:
              return (
                <SelectRecipientModal
                  recipients={storedRecipients}
                  onSelectRecipient={handleSelectRecipient}
                />
              );
            case 3:
              return (
                <SelectStateModal
                  states={stateCodes}
                  onSelectState={handleSelectState}
                />
              );
            default:
              return null;
          }
        })()}
      </Modal>
    </div>
  );
};

export default EnterReceiverDetails;
