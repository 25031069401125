export const PROFILE_ENUM = {
  updateProfile: "Profile",
  fullName: "Name",
  phone: "Phone",
  address: "Address",
  update: "Update",
  updateProfileSuccesfully: "Profile updated successfully!",
  editButton: "Edit",
  idUploadFrontSide: "ID [Front Side]",
  idUploadBackSide: "ID [Back Side]",
  profileIsNotverified:
    "Your profile is not verified. Verify your profile by completing KYC Verification Process.",
  KycButton: "Start KYC",
  profile: "Profile",
  KYC: "KYC",
  statusOfKYC: "Status:",
  submittedAndWaiting: "Submitted and Awaiting for Approval",
  KYCApplicationSubmitted: "KYC Applicaiton submitted successfully.",
  weWillLetYouKnow: "We will let you know once your account is fully verified.",
  yourAccountIsVerified: "Your account is Verified.",
  yourApplicationIsRejected:
    "Your application is rejected. Please contact our customer support team for assistance!",
  KycRejectedReason: " Reason: Reason statement",
  reSubmitKyc: "Resubmit KYC",
  proceedButton: "Proceed",
  changeFile: "To change file click thumbnail",
  submit: "Submit",
  supportedFiles: "Allowed file types: PNG, JPEG and PDF",
  errorForPancardHold: "Please upload the clear photo of you holding your ID",
  errorForAadhar: "Please upload the ID!",
  errorForAadharHold: "Please upload the clear photo of you holding your ID!",
  loading: "Loading",
  idFrontSide: "Front Side",
  idBackSide: "Back Side",
  idUpload: "Government ID/ Passport",
  invalidEmail: "Email is invalid",
  emptyInput: "Please fill up the required details!",
  invalidNumber: "Invalid phone number!",
  errorForFirstName: "Please enter the first name!",
  errorForLastName: "Please enter the last name!",
  errorForEmail: "Please enter the email!",
  errorForNumber: "Please enter the phone number!",
  errorForName: "Please enter the name!",
  errorForID: "Please upload the IDs",
  yourKYCIsInFroxen: "Your account is Frozen. Please submit a Ticket",
};
