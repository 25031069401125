import styles from "./ProfilePreviewKYC.module.scss";
import React, { useContext, useState, useEffect } from "react";
import cx from "classnames";
import { db } from "../../firebase";
import { collection, doc, getDoc } from "firebase/firestore";
import { AppContext } from "../../context";
const ProfilePreviewKYC = ({
  reSubmitKyc,
  handleChangeInputFieldName,
  firstName,
  handleChangeInputMiddleName,
  middleName,
  postalCode,
  handleChangeInputLastName,
  handleChangeInputStreet2,
  lastName,
  handleChangeInputFieldEmail,
  emailAddress,
  handlePhoneNumberChange,
  phoneNumber,
  handleDateOfBirthChange,
  handleChangeInputCountryCode,
  handleChangeInputPostalCode,
  handleChangeInputStreet,
  street,
  handleChange,
  street2,
  city,
  subdivision,
  setFirstName,
  setMiddleName,
  setLastName,
  setPhoneNumber,
  setEmailAddress,
  setStreet,
  setStreet2,
  setCity,
  dateOfBirth,
  setSubdivision,
  setPostalCode,
  setCountryCode,
  setDateOfBirth,
  setReSubmitKyc,
}) => {
  const { isMobile, userData } = useContext(AppContext);

  return (
    <div>
      <div
        className={cx(styles.profileNameEmailContainer, {
          [styles.profileNameEmailContainerWeb]: !isMobile,
          [styles.profileNameEmailContainerMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.firstFlexInStep1, {
            [styles.firstFlexMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.IDDetailsDiv, {
              [styles.IDDetailsDivWeb]: !isMobile,
              [styles.IDDetailsDivMob]: isMobile,
            })}
          >
            <p
              className={cx(styles.profileNameContainer, {
                [styles.profileNameContainerWeb]: !isMobile,
                [styles.profileNameContainerMob]: isMobile,
              })}
            >
              First Name *
            </p>
            <input
              key="name"
              type="text"
              className={cx(styles.inputName, {
                [styles.inputNameWeb]: !isMobile,
                [styles.inputNameMob]: isMobile,
                [styles.disabledInput]:
                  reSubmitKyc === "pending" || reSubmitKyc === "Verified",
              })}
              onChange={handleChangeInputFieldName}
              value={userData?.user_pii?.name?.first}
              disabled
            />
          </div>

          <div
            className={cx(styles.email, {
              [styles.emailWeb]: !isMobile,
              [styles.emailMob]: isMobile,
            })}
          >
            <p
              className={cx(styles.emailContainer, {
                [styles.emailContainerWeb]: !isMobile,
                [styles.emailContainerMob]: isMobile,
              })}
            >
              Middle Name
            </p>
            <input
              type="email"
              className={cx(styles.inputName, {
                [styles.inputNameWeb]: !isMobile,
                [styles.inputNameMob]: isMobile,
                [styles.disabledInput]:
                  reSubmitKyc === "pending" || reSubmitKyc === "Verified",
              })}
              onChange={(e) => setMiddleName(e.target.value)}
              value={userData?.user_pii?.name?.middle}
              disabled
            />
          </div>
        </div>
        <div
          className={cx(styles.secondFlex, {
            [styles.secondFlexMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.phoneNumber, {
              [styles.phoneNumberWeb]: !isMobile,
              [styles.phoneNumberMob]: isMobile,
            })}
          >
            <p
              className={cx(styles.profileNameContainer, {
                [styles.profileNameContainerWeb]: !isMobile,
                [styles.profileNameContainerMob]: isMobile,
              })}
            >
              Last Name *
            </p>
            <input
              key="name"
              type="text"
              className={cx(styles.inputName, {
                [styles.inputNameWeb]: !isMobile,
                [styles.inputNameMob]: isMobile,
                [styles.disabledInput]:
                  reSubmitKyc === "pending" || reSubmitKyc === "Verified",
              })}
              onChange={(e) => setLastName(e.target.value)}
              value={userData?.user_pii?.name?.last}
              disabled
            />
          </div>
          <div
            className={cx(styles.email, {
              [styles.emailWeb]: !isMobile,
              [styles.emailMob]: isMobile,
            })}
          >
            <p
              className={cx(styles.profileNameContainer, {
                [styles.profileNameContainerWeb]: !isMobile,
                [styles.profileNameContainerMob]: isMobile,
              })}
            >
              Email *
            </p>
            <input
              key="name"
              type="text"
              className={cx(styles.inputName, {
                [styles.inputNameWeb]: !isMobile,
                [styles.inputNameMob]: isMobile,
                [styles.disabledInput]:
                  reSubmitKyc === "pending" || reSubmitKyc === "Verified",
              })}
              onChange={handleChangeInputFieldEmail}
              value={userData?.user_pii?.email_address}
              disabled
            />
          </div>
        </div>
        <div
          className={cx(styles.secondFlex, {
            [styles.secondFlexMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.phoneNumber, {
              [styles.phoneNumberWeb]: !isMobile,
              [styles.phoneNumberMob]: isMobile,
            })}
          >
            <p
              className={cx(styles.profileNameContainer, {
                [styles.profileNameContainerWeb]: !isMobile,
                [styles.profileNameContainerMob]: isMobile,
              })}
            >
              Phone *
            </p>
            <input
              key="name"
              type="text"
              className={cx(styles.inputName, {
                [styles.inputNameWeb]: !isMobile,
                [styles.inputNameMob]: isMobile,
                [styles.disabledInput]:
                  reSubmitKyc === "pending" || reSubmitKyc === "Verified",
              })}
              onChange={handlePhoneNumberChange}
              value={userData?.user_pii?.phone_number}
              disabled
            />
          </div>
          <div
            className={cx(styles.email, {
              [styles.emailWeb]: !isMobile,
              [styles.emailMob]: isMobile,
            })}
          >
            <p
              className={cx(styles.profileNameContainer, {
                [styles.profileNameContainerWeb]: !isMobile,
                [styles.profileNameContainerMob]: isMobile,
              })}
            >
              DOB *
            </p>
            <input
              key="dob"
              type="date"
              className={cx(styles.inputName, {
                [styles.inputNameWeb]: !isMobile,
                [styles.inputNameMob]: isMobile,
                [styles.disabledInput]:
                  reSubmitKyc === "pending" || reSubmitKyc === "Verified",
              })}
              onChange={handleDateOfBirthChange}
              value={userData?.user_pii?.date_of_birth}
              disabled
            />
          </div>
        </div>
      </div>
      <div
        className={cx(styles.profileNameEmailContainer, {
          [styles.profileNameEmailContainerWeb]: !isMobile,
          [styles.profileNameEmailContainerMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.firstFlex, {
            [styles.firstFlexMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.profileName, {
              [styles.profileNameWeb]: !isMobile,
              [styles.profileNameMob]: isMobile,
            })}
          >
            <p
              className={cx(styles.profileNameContainer, {
                [styles.profileNameContainerWeb]: !isMobile,
                [styles.profileNameContainerMob]: isMobile,
              })}
            >
              Street *
            </p>
            <input
              type="address"
              onChange={(e) => setStreet(e.target.value)}
              value={userData?.user_pii?.address?.street}
              disabled
              className={cx(styles.inputName, {
                [styles.inputNameWeb]: !isMobile,
                [styles.inputNameMob]: isMobile,
                [styles.disabledInput]:
                  reSubmitKyc === "pending" || reSubmitKyc === "Verified",
              })}
            />
          </div>

          <div
            className={cx(styles.email, {
              [styles.emailWeb]: !isMobile,
              [styles.emailMob]: isMobile,
            })}
          >
            <p
              className={cx(styles.emailContainer, {
                [styles.emailContainerWeb]: !isMobile,
                [styles.emailContainerMob]: isMobile,
              })}
            >
              Street 2
            </p>
            <input
              key="street2"
              type="text"
              className={cx(styles.inputName, {
                [styles.inputNameWeb]: !isMobile,
                [styles.inputNameMob]: isMobile,
                [styles.disabledInput]:
                  reSubmitKyc === "pending" || reSubmitKyc === "Verified",
              })}
              onChange={(e) => setStreet2(e.target.value)}
              value={userData?.user_pii?.address?.street2}
              disabled
            />
          </div>
        </div>

        <div
          className={cx(styles.secondFlex, {
            [styles.secondFlexMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.phoneNumber, {
              [styles.phoneNumberWeb]: !isMobile,
              [styles.phoneNumberMob]: isMobile,
            })}
          >
            <p
              className={cx(styles.profileNameContainer, {
                [styles.profileNameContainerWeb]: !isMobile,
                [styles.profileNameContainerMob]: isMobile,
              })}
            >
              City *
            </p>
            <input
              key="city"
              type="text"
              className={cx(styles.inputName, {
                [styles.inputNameWeb]: !isMobile,
                [styles.inputNameMob]: isMobile,
                [styles.disabledInput]:
                  reSubmitKyc === "pending" || reSubmitKyc === "Verified",
              })}
              onChange={(e) => setCity(e.target.value)}
              value={userData?.user_pii?.address?.city}
              disabled
            />
          </div>
          <div
            className={cx(styles.email, {
              [styles.emailWeb]: !isMobile,
              [styles.emailMob]: isMobile,
            })}
          >
            <p
              className={cx(styles.profileNameContainer, {
                [styles.profileNameContainerWeb]: !isMobile,
                [styles.profileNameContainerMob]: isMobile,
              })}
            >
              Subdivision *
            </p>
            <input
              key="subdivision"
              type="text"
              className={cx(styles.inputName, {
                [styles.inputNameWeb]: !isMobile,
                [styles.inputNameMob]: isMobile,
                [styles.disabledInput]:
                  reSubmitKyc === "pending" || reSubmitKyc === "Verified",
              })}
              onChange={(e) => setSubdivision(e.target.value)}
              disabled
              value={userData?.user_pii?.address?.subdivision}
            />
          </div>
        </div>
        <div
          className={cx(styles.secondFlex, {
            [styles.secondFlexMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.phoneNumber, {
              [styles.phoneNumberWeb]: !isMobile,
              [styles.phoneNumberMob]: isMobile,
            })}
          >
            <p
              className={cx(styles.profileNameContainer, {
                [styles.profileNameContainerWeb]: !isMobile,
                [styles.profileNameContainerMob]: isMobile,
              })}
            >
              Zip Code *
            </p>
            <input
              key="postalCode"
              type="text"
              className={cx(styles.inputName, {
                [styles.inputNameWeb]: !isMobile,
                [styles.inputNameMob]: isMobile,
                [styles.disabledInput]:
                  reSubmitKyc === "pending" || reSubmitKyc === "Verified",
              })}
              onChange={(e) => setPostalCode(e.target.value)}
              value={userData?.user_pii?.address?.postal_code}
              disabled
            />
          </div>
          <div
            className={cx(styles.email, {
              [styles.emailWeb]: !isMobile,
              [styles.emailMob]: isMobile,
            })}
          >
            <p
              className={cx(styles.profileNameContainer, {
                [styles.profileNameContainerWeb]: !isMobile,
                [styles.profileNameContainerMob]: isMobile,
              })}
            >
              Country Code *
            </p>
            <input
              key="countryCode"
              type="text"
              className={cx(styles.inputName, {
                [styles.inputNameWeb]: !isMobile,
                [styles.inputNameMob]: isMobile,
                [styles.disabledInput]:
                  reSubmitKyc === "pending" || reSubmitKyc === "Verified",
              })}
              onChange={handleChangeInputCountryCode}
              value="US"
              disabled
            />
          </div>
        </div>
        <div
          className={cx(styles.secondFlex, {
            [styles.secondFlexMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.phoneNumber, {
              [styles.phoneNumberWeb]: !isMobile,
              [styles.phoneNumberMob]: isMobile,
            })}
          >
            <p
              className={cx(styles.profileNameContainer, {
                [styles.profileNameContainerWeb]: !isMobile,
                [styles.profileNameContainerMob]: isMobile,
              })}
            >
              Document Submitted
            </p>
            <input
              key="postalCode"
              type="text"
              className={cx(styles.inputName, {
                [styles.inputNameWeb]: !isMobile,
                [styles.inputNameMob]: isMobile,
                [styles.disabledInput]:
                  reSubmitKyc === "pending" || reSubmitKyc === "Verified",
              })}
              onChange={(e) => setPostalCode(e.target.value)}
              value={userData?.user_pii?.document_submitted}
              disabled
            />
          </div>
          <div
            className={cx(styles.email, {
              [styles.emailWeb]: !isMobile,
              [styles.emailMob]: isMobile,
            })}
          >
            <p
              className={cx(styles.profileNameContainer, {
                [styles.profileNameContainerWeb]: !isMobile,
                [styles.profileNameContainerMob]: isMobile,
              })}
            >
              Document ID
            </p>
            <input
              key="countryCode"
              type="text"
              className={cx(styles.inputName, {
                [styles.inputNameWeb]: !isMobile,
                [styles.inputNameMob]: isMobile,
                [styles.disabledInput]:
                  reSubmitKyc === "pending" || reSubmitKyc === "Verified",
              })}
              onChange={handleChangeInputCountryCode}
              value={userData?.user_pii?.document_id}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePreviewKYC;
