import React from "react";
import "./StepIndicator.css";

const StepIndicator = ({ currentStep }) => {
  const steps = ["Not-Initialized", "Started", "Review", "Verified"];

  return (
    <div className="step-indicator-container">
      <div className="steps">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`step ${index <= currentStep ? "active" : ""}`}
          >
            <div className="indicator" />
            <div className="step-text">{step}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepIndicator;
