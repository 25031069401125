import React, { useState, useEffect, useContext, useRef } from "react";
import {
  RECURRING_TRANSFER_ENUM,
  TransferFrequency,
  TransferScheduleType,
} from "../../enums/transferEnums";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/datepicker-custom.css";
import styles from "./RecurringTransferSchedule.module.scss";
import cx from "classnames";
import { AppContext } from "../../context";
import SelectBox from "../../components/SelectBox/SelectBox";
import ButtonRade from "../../components/RadeButtons";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import calendarIcon from "../../assets/calendarIcon.svg";

const MIN_ALLOWED_TRANSFER = 10;
export default function RecurringTransferSchedule({
  onNext,
  formData,
  setFormData,
  isLoading,
  errorMessage,
  setErrorMessage,
  customerActivityLimit,
}) {
  let navigate = useNavigate();
  const { isMobile } = useContext(AppContext);
  const [schedulePreview, setSchedulePreview] = useState([]);
  const [errors, setErrors] = useState({});
  const startDatePickerRef = useRef(null);
  const endDatePickerRef = useRef(null);
  const customDatePickerRefs = useRef([]);

  // Initialize form data if not set
  useEffect(() => {
    if (!formData.schedule_type) {
      setFormData((prev) => ({
        ...prev,
        schedule_type: TransferScheduleType.FIXED,
        scheduled_transfers: [],
        amount_in_usd: 0,
      }));
    }
  }, []);

  // Calculate all transfer dates based on schedule type and parameters
  const calculateTransferDates = (
    startDate,
    endDate,
    frequency,
    scheduleType
  ) => {
    if (scheduleType === TransferScheduleType.CUSTOM) {
      return (formData.scheduled_transfers || []).map((transfer) => ({
        date: new Date(transfer.scheduled_date),
        amount: Number(transfer.amount_in_usd),
      }));
    }

    const dates = [];
    let currentDate = new Date(startDate);
    const endDateTime = new Date(endDate);

    while (currentDate <= endDateTime) {
      dates.push({
        date: new Date(currentDate),
        amount: Number(formData.amount_in_usd),
      });

      switch (frequency) {
        case TransferFrequency.DAILY:
          currentDate.setDate(currentDate.getDate() + 1);
          break;
        case TransferFrequency.WEEKLY:
          currentDate.setDate(currentDate.getDate() + 7);
          break;
        case TransferFrequency.MONTHLY:
          currentDate.setMonth(currentDate.getMonth() + 1);
          break;
        default:
          break;
      }
    }

    return dates;
  };

  // Update schedule preview when form data changes
  useEffect(() => {
    if (!formData) return;

    if (formData.schedule_type === TransferScheduleType.FIXED) {
      if (formData.start_date && formData.end_date && formData.frequency) {
        const frequencyValue = formData.frequency.value;
        const dates = calculateTransferDates(
          formData.start_date,
          formData.end_date,
          frequencyValue,
          formData.schedule_type
        );
        setSchedulePreview(dates);
      }
    } else {
      setSchedulePreview(
        (formData.scheduled_transfers || []).map((transfer) => ({
          date: new Date(transfer.scheduled_date),
          amount: Number(transfer.amount_in_usd),
        }))
      );
    }
  }, [formData]);

  const handleScheduleTypeChange = (type) => {
    setFormData((prev) => ({
      ...prev,
      schedule_type: type,
      scheduled_transfers: [],
      frequency:
        type === TransferScheduleType.FIXED ? prev.frequency || "" : undefined,
      start_date: undefined,
      end_date: undefined,
    }));
    setErrors({});
    setErrorMessage("");
  };

  const handleFixedScheduleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
    setErrors((prev) => ({ ...prev, [field]: undefined }));
    setErrorMessage("");
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    let limitedValue;

    // Dynamically calculate max digits based on customerActivityLimit
    const maxIntegerDigits = String(Math.floor(customerActivityLimit)).length;

    // Limit to max integer digits before decimal and 2 after
    const regex = new RegExp(`^\\d{0,${maxIntegerDigits}}(\\.\\d{0,2})?`);
    limitedValue = value.match(regex)?.[0];

    // Additional check to ensure value doesn't exceed customerActivityLimit
    const numValue = parseFloat(limitedValue || "0");
    if (numValue > customerActivityLimit) {
      limitedValue = customerActivityLimit.toFixed(2);
    }

    // Update form data and errors
    setFormData((prev) => ({
      ...prev,
      amount_in_usd: limitedValue === "" ? 0 : Number(limitedValue),
    }));
    setErrors((prev) => ({ ...prev, amount_in_usd: undefined }));
    setErrorMessage("");
  };

  const addCustomTransfer = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    setFormData((prev) => ({
      ...prev,
      scheduled_transfers: [
        ...(prev.scheduled_transfers || []),
        {
          scheduled_date: new Date(tomorrow.setHours(0, 0, 0, 0)).toISOString(),
          amount_in_usd: Number(prev.amount_in_usd),
          status: "PENDING",
        },
      ],
    }));
    setErrors((prev) => ({ ...prev, scheduled_transfers: undefined }));
    setErrorMessage("");
  };

  const updateCustomTransfer = (index, field, value) => {
    setFormData((prev) => ({
      ...prev,
      scheduled_transfers: (prev.scheduled_transfers || []).map((transfer, i) =>
        i === index
          ? {
              ...transfer,
              [field]: field === "amount_in_usd" ? Number(value) : value,
            }
          : transfer
      ),
    }));
    setErrors((prev) => ({ ...prev, scheduled_transfers: undefined }));
    setErrorMessage("");
  };

  const removeCustomTransfer = (index) => {
    setFormData((prev) => ({
      ...prev,
      scheduled_transfers: (prev.scheduled_transfers || []).filter(
        (_, i) => i !== index
      ),
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

    if (formData.schedule_type === TransferScheduleType.FIXED) {
      // Validate amount for fixed schedule type
      if (!formData.amount_in_usd || formData.amount_in_usd <= 0) {
        newErrors.amount_in_usd = "Please enter a valid amount";
      }
      if (
        formData.amount_in_usd &&
        formData.amount_in_usd < MIN_ALLOWED_TRANSFER
      ) {
        newErrors.amount_in_usd =
          "Only orders with a minimum of $10 will be processed.";
      }
      if (!formData.frequency) {
        newErrors.frequency = "Please select a frequency";
      }
      if (!formData.start_date) {
        newErrors.start_date = "Please select a start date";
      }
      if (!formData.end_date) {
        newErrors.end_date = "Please select an end date";
      } else {
        const endDate = new Date(formData.end_date);
        if (endDate > oneYearFromNow) {
          newErrors.end_date = "End date cannot be more than 1 year from now";
        }
        if (formData.start_date && new Date(formData.start_date) >= endDate) {
          newErrors.end_date = "End date must be after start date";
        }
      }
    } else {
      // Validate custom schedule
      if (!formData.scheduled_transfers?.length) {
        newErrors.scheduled_transfers = "Please add at least one transfer";
      } else {
        const hasInvalidDate = formData.scheduled_transfers.some((transfer) => {
          const transferDate = new Date(transfer.scheduled_date);
          return transferDate > oneYearFromNow;
        });

        const hasInvalidAmount = formData.scheduled_transfers.some(
          (transfer) => !transfer.amount_in_usd || transfer.amount_in_usd <= 0
        );

        if (hasInvalidDate) {
          newErrors.scheduled_transfers = "All transfers must be within 1 year";
        }
        if (hasInvalidAmount) {
          newErrors.amount = "All transfers must have a valid amount";
        }
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleBack = () => {
    navigate("/autoSend");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // For fixed schedule, generate scheduled_transfers
      if (formData.schedule_type === TransferScheduleType.FIXED) {
        const frequencyValue = formData.frequency.value;
        const dates = calculateTransferDates(
          formData.start_date,
          formData.end_date,
          frequencyValue,
          formData.schedule_type
        );

        setFormData((prev) => ({
          ...prev,
          scheduled_transfers: dates.map((item) => ({
            scheduled_date: item.date.toISOString(),
            amount_in_usd: Number(formData.amount_in_usd),
            status: "PENDING",
          })),
          total_transfers_scheduled: dates.length,
          next_transfer_date: dates[0]?.date.toISOString(),
        }));
      } else {
        // For custom schedule, sort and set next transfer date
        const sortedTransfers = [...formData.scheduled_transfers].sort(
          (a, b) => new Date(a.scheduled_date) - new Date(b.scheduled_date)
        );

        setFormData((prev) => ({
          ...prev,
          next_transfer_date: sortedTransfers[0]?.scheduled_date,
          total_transfers_scheduled: sortedTransfers.length,
        }));
      }

      onNext();
    }
  };

  useEffect(() => {
    if (!formData.frequency) {
      setFormData((prev) => ({
        ...prev,
        frequency: "",
      }));
    }
  }, [formData.frequency, setFormData]);

  const handleChangeFrequency = (selectedFrequency) => {
    if (selectedFrequency) {
      setFormData((prev) => ({
        ...prev,
        frequency: selectedFrequency,
      }));
      setErrors((prev) => ({ ...prev, frequency: undefined }));
      setErrorMessage("");
    }
  };

  return (
    <div
      className={cx(styles.container, {
        [styles.containerMob]: isMobile,
      })}
    >
      <div
        className={cx(styles.setUpScheduleTitle, {
          [styles.setUpScheduleTitleMob]: isMobile,
        })}
      >
        {RECURRING_TRANSFER_ENUM.setUpScheduleTitle}
      </div>
      <div
        className={cx(styles.scheduleTypeButtons, {
          [styles.scheduleTypeButtonsMob]: isMobile,
        })}
      >
        <div
          onClick={() => handleScheduleTypeChange(TransferScheduleType.FIXED)}
          className={cx(styles.fixedScheduleOption, {
            [styles.selectedOptionFixedSchedule]:
              formData.schedule_type === TransferScheduleType.FIXED,
          })}
        >
          {RECURRING_TRANSFER_ENUM.fixedSchedule}
        </div>
        <div
          onClick={() => handleScheduleTypeChange(TransferScheduleType.CUSTOM)}
          className={cx(styles.customScheduleOption, {
            [styles.selectedOptionCustomSchedule]:
              formData.schedule_type === TransferScheduleType.CUSTOM,
          })}
        >
          {RECURRING_TRANSFER_ENUM.customSchedule}
        </div>
      </div>

      {formData.schedule_type === TransferScheduleType.FIXED && (
        <div className={styles.formGroup}>
          <div className={styles.amountWrapper}>
            <span className={styles.currencySymbol}>$</span>
            <input
              type="number"
              className={styles.amountInput}
              value={formData.amount_in_usd || ""}
              onChange={handleAmountChange}
              disabled={!customerActivityLimit}
              min="0"
              step="0.01"
              placeholder="Enter Amount"
            />
          </div>
          {errors.amount_in_usd && (
            <div className={styles.errorForAmountInUsd}>
              {errors.amount_in_usd}
            </div>
          )}
        </div>
      )}

      {formData.schedule_type === TransferScheduleType.FIXED ? (
        <div className={styles.formGroup}>
          <SelectBox
            placeHolder="Select Frequency"
            options={Object.values(TransferFrequency).map((freq) => ({
              label: freq,
              value: freq,
            }))}
            onChange={handleChangeFrequency}
            setSelectedValue={(value) =>
              handleFixedScheduleChange("frequency", value)
            }
            selectedValue={
              formData.frequency ? formData.frequency.value : "" || ""
            }
            errorDismissOnclick={() => setErrorMessage("")}
          />
          {errors.frequency && (
            <div className={styles.errorForFrequency}>{errors.frequency}</div>
          )}
          <div className={styles.formGroup}>
            <div className={styles.datePickerContainer}>
              <DatePicker
                ref={startDatePickerRef}
                selected={
                  formData.start_date ? new Date(formData.start_date) : null
                }
                onChange={(date) =>
                  handleFixedScheduleChange(
                    "start_date",
                    date ? date.toISOString() : null
                  )
                }
                minDate={new Date()}
                className={styles.datePicker}
                placeholderText="Select Start Date"
                dateFormat="MM-dd-yyyy"
              />
              <img
                src={calendarIcon}
                className={styles.calendarIcon}
                alt="calendarIcon"
                onClick={() => startDatePickerRef.current.setOpen(true)}
              />
            </div>
            {errors.start_date && (
              <div className={styles.error}>{errors.start_date}</div>
            )}
          </div>

          <div className={styles.formGroup}>
            <div className={styles.datePickerContainer}>
              <DatePicker
                ref={endDatePickerRef}
                selected={
                  formData.end_date ? new Date(formData.end_date) : null
                }
                onChange={(date) =>
                  handleFixedScheduleChange(
                    "end_date",
                    date ? date.toISOString() : null
                  )
                }
                minDate={
                  formData.start_date ? new Date(formData.start_date) : null
                }
                maxDate={
                  new Date(new Date().setFullYear(new Date().getFullYear() + 1))
                }
                className={styles.datePicker}
                placeholderText="Select End Date"
                dateFormat="MM-dd-yyyy"
              />
              <img
                src={calendarIcon}
                className={styles.calendarIcon}
                alt="calendarIcon"
                onClick={() => endDatePickerRef.current.setOpen(true)}
              />
            </div>
            {errors.end_date && (
              <div className={styles.error}>{errors.end_date}</div>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.customScheduleList}>
          {(formData.scheduled_transfers || []).map((transfer, index) => (
            <div key={index} className={styles.customScheduleItem}>
              <div className={styles.datePickerContainer}>
                <DatePicker
                  ref={(el) => (customDatePickerRefs.current[index] = el)}
                  selected={
                    transfer.scheduled_date
                      ? new Date(transfer.scheduled_date)
                      : null
                  }
                  onChange={(date) =>
                    updateCustomTransfer(
                      index,
                      "scheduled_date",
                      date ? date.toISOString() : null
                    )
                  }
                  minDate={new Date()}
                  maxDate={
                    new Date(
                      new Date().setFullYear(new Date().getFullYear() + 1)
                    )
                  }
                  className={styles.datePicker}
                  placeholderText="Select Scheduled Date"
                  dateFormat="MM-dd-yyyy"
                />
                <img
                  src={calendarIcon}
                  className={styles.calendarIcon}
                  alt="calendarIcon"
                  onClick={() =>
                    customDatePickerRefs.current[index].setOpen(true)
                  }
                />
              </div>
              <div className={styles.amountWrapper}>
                <span className={styles.currencySymbol}>$</span>
                <input
                  type="number"
                  className={styles.amountInput}
                  value={transfer.amount_in_usd || ""}
                  onChange={(e) =>
                    updateCustomTransfer(index, "amount_in_usd", e.target.value)
                  }
                  min="0"
                  step="0.01"
                  placeholder="Enter Amount"
                />
              </div>
              <ButtonRade
                className={styles.deleteButton}
                onClick={() => removeCustomTransfer(index)}
              >
                Delete
              </ButtonRade>
            </div>
          ))}
          {formData.scheduled_transfers &&
          formData.scheduled_transfers.length === 0 ? (
            <div className={styles.noTransfersMessage}>
              Add your first transfer to the schedule
              <ButtonRade
                customStyling={styles.smallAddButton}
                onClick={addCustomTransfer}
              >
                Add Transfer
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </ButtonRade>
            </div>
          ) : (
            <ButtonRade
              customStyling={cx(styles.addButton, {
                [styles.addButtonMob]: isMobile,
              })}
              onClick={addCustomTransfer}
            >
              Add Transfer
            </ButtonRade>
          )}
          {errors.scheduled_transfers && (
            <div className={styles.error}>{errors.scheduled_transfers}</div>
          )}
          {errors.amount && <div className={styles.error}>{errors.amount}</div>}
        </div>
      )}

      {schedulePreview.length > 0 && (
        <div className={styles.preview}>
          <h3>Schedule Preview</h3>
          <div className={styles.previewList}>
            {schedulePreview.map((item, index) => (
              <div key={index} className={styles.previewItem}>
                <span>
                  {item.date.toLocaleDateString("en-US", {
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                  })}
                </span>
                <span>${(Number(item.amount) || 0).toFixed(2)}</span>
              </div>
            ))}
          </div>
          <div className={styles.disclaimerNote}>
            Disclaimer - All transactions will be processed at UTC 00:00 hrs on
            the scheduled date.
          </div>
        </div>
      )}
      <div className={styles.buttonContainer}>
        <ButtonRade
          customStyling={cx(styles.backButton, {
            [styles.backButtonMob]: isMobile,
          })}
          onClick={handleBack}
        >
          Back
        </ButtonRade>
        <ButtonRade
          customStyling={cx(styles.nextButton, {
            [styles.nextButtonMob]: isMobile,
          })}
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Continue"}
        </ButtonRade>
      </div>
    </div>
  );
}
