import { auth } from "../firebase";

const BASE_URL = process.env.REACT_APP_PAYEZY_SERVER_URI;

// Get all recurring transfers for the current user
export const getRecurringTransfers = async () => {
  try {
    const idToken = await auth.currentUser.getIdToken(true);
    const response = await fetch(`${BASE_URL}/recurring-transfers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || "Failed to fetch recurring transfers");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching recurring transfers:", error);
    throw error;
  }
};

// Create a new recurring transfer
export const createRecurringTransfer = async (transferData) => {
  try {
    const idToken = await auth.currentUser.getIdToken(true);
    const response = await fetch(`${BASE_URL}/recurring-transfers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify(transferData),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || "Failed to create recurring transfer");
    }

    return await response.json();
  } catch (error) {
    console.error("Error creating recurring transfer:", error);
    throw error;
  }
};

// Update recurring transfer status
export const updateRecurringTransferStatus = async (transferId, status) => {
  try {
    const idToken = await auth.currentUser.getIdToken(true);
    const response = await fetch(
      `${BASE_URL}/recurring-transfers/${transferId}/status`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({ status }),
      }
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(
        error.error || "Failed to update recurring transfer status"
      );
    }

    return await response.json();
  } catch (error) {
    console.error("Error updating recurring transfer status:", error);
    throw error;
  }
};

// Process a recurring transfer immediately
export const processRecurringTransfer = async (transferId, txNum) => {
  try {
    const idToken = await auth.currentUser.getIdToken(true);
    const response = await fetch(
      `${BASE_URL}/recurring-transfers/${transferId}/process/${txNum}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || "Failed to process recurring transfer");
    }

    return await response.json();
  } catch (error) {
    console.error("Error processing recurring transfer:", error);
    throw error;
  }
};

// Cancel individual transaction in a recurring transfer set
export const cancelIndividualTransfer = async (transferId, txNum) => {
  try {
    const idToken = await auth.currentUser.getIdToken(true);
    const response = await fetch(
      `${BASE_URL}/recurring-transfers/${transferId}/cancel/${txNum}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || "Failed to cancel individual transfer");
    }

    return await response.json();
  } catch (error) {
    console.error("Error cancelling individual transfer:", error);
    throw error;
  }
};
