export const SEND_ENUM = {
  enterAmountOfUSDWishToSend: "Enter the amount of USD you wish to send. ",
  youSend: "You Send",
  RUD: "RUD",
  recipientGets: "Recipient Gets",
  INR: "INR",
  proceedButton: "Proceed",
  newToApp: "New to ",
  XXX: " Payezy?",
  learnMore: "Learn more",
  USD: "USD",
  login: "Sign Up/Login",
  loginUsingGoogleAccount: "Log in quickly using one of your google accounts.",
  continueWithGoogle: "Google",
  fullName: "Full Name",
  nickName: "Nick Name",
  phone: "Phone",
  accountNumber: "Account Number",
  email: "Email",
  confirmAccountNumber: "Confirm Account Number",
  IFSCCode: "IFSC Code",
  enterTheDetailsOfReciep: "Enter the details of the recipient",
  pleaseBePatient:
    "Please be patient. Recipient will receive your funds in the next 48 hours. ",
  viewStatus: "Check Status",
  yourRecentTransaction: "Transfer History",
  ID: "ID",
  date: "Date",
  amount: "Amount",
  recipient: "Recipient",
  receivers: "Receives (INR)",
  accNum: "Account Number",
  status: "Status",
  pleaseLoginToContinue: "Please login to continue!",
  accountNum: "Account No.",

  Name: "Name",
  pleaseBePatientYourTransfer:
    "Your payment has been received. Your transfer order will be delivered in 48 hours. Thank you for your patience.",
  pleaseEnterANick: "Please enter a nick name to continue!",
  pleaseEnterAPhone: "Please enter a phone number to continue!",
  pleaseEnterFirstName: "Please enter a first name to continue!",
  pleaseEnterLastName: "Please enter a last name to continue!",
  pleaseEnterAFullName: "Please enter a full name to continue!",
  pleaseEnterAAccountNumber: "Please enter an account number to continue!",
  pleaseConfirmAccountNumber: "Please confirm your account number to continue!",
  pleaseEnterAnIFSCCode: "Please enter a IFSC code to continue!",
  transferId: "Transfer ID",
  fee: "Fee",
  receiver: "Receiver",
  checkStatus: "Check status",
  logoutMessage: "Are you sure you want to Logout? ",
  logout: "Logout",
  cancel: "Cancel",
  transactionSuccesfull: "Transaction Successful",
  success: "Success",
  chain: "Chain",
  orderId: "Order ID",
  destination: "Destination",
  explorerButton: "View on explorer",
  walletCheck: "Check your wallet for USDT deposit.",
  cancelAndRefund: "Support",
  cancelledButton: "Cancelled",
  rejectedButton: "Rejected",
  completedButton: "Settled",
  processingButton: "Processing",
  settlementErrorButton: "Settlement Error",
  failedButton: "Failed",
  processingRefundButton: "Processing Refund",
  refundCompleteButton: "Refund Complete",
  settlementFailedButton: "Settlement Failed",
  yourOrderCompleted: "Your transfer order has been delivered successfully at",
  youHaveCancelledThisOrder:
    "You have cancelled this transfer order. Please be patient while we process your refund.",
  paymentRejectedMessage:
    "There has been an error in processing payment for your transaction from your bank. Please contact support immediately to avoid incurring further charges.",
  paymentFailedMessage:
    "Oops! Your payment has failed. Please contact support for further information.",
  refundProcessedMessage:
    "Your refund for this transaction has been initiated. Please wait upto three business days for the transaction to settle and reflect in your bank.",
  refundCompletedMessage: "Your refund has been successfully completed.",
  deliveryRejectedMessage:
    "Your settlement for benficiary has failed. Please contact support for further information.",
  settlementErrorMessage:
    "Your transfer request has been received. Please do not retry and contact support for further information on this transaction.",
  transactionInProgress:
    "Transaction is being processed, please do not close the window or refresh the page. You will be redirected automatically once the transaction is complete.",
  paymentRecievedButton: "Awaiting settlement",
  addNewReceiverDetails: "Add New Recipient",
  backButton: "Back",
  cannotUseMultipleTransactionSameTime:
    "Cannot use multiple transaction at the same time.",
  pleaseTryAgainLater: " Please refresh and  try again!",
  awaitingSettlement: " Awaiting Settlement",
  bankTransfer: "Bank Transfer",
  cardPayment: "Card Payment",
  feeBreakDown: "Charges Breakdown",
  bankTransferCharges: "Bank Transfer Charges (0.8%)",
  cardProcessingCharge: "Card Processing Charges (4%)",
  paymentReference: "INR Payment Reference",
  resubmit: "Re-submit",
  confirm: "Continue",
  paymentRef: "Payment Ref",
  time: "Time",
  closeButton: "Close",
  gettingMoney: "Getting Money to where it matters.",
  sendMoneyToIndia: "Send money from USA to India",
  with: "With",
  zeroFee: "$0",
  platformFee: " platform fees,",
  payezyIsTheCheapest: "Payezy is the cheapest money transfer platform in USA.",
  fullyTransparent: "Fully transparent",
  fastAndHasle: "Fast and hassle free",
  bestExchangeRate: "Best exchange rate",
  refreshButton: "Refresh",
  continueWithFacebook: "Meta",
  continueWithTwitter: "Sign in with",
  emailAndpswdLogin: "Continue with Email/Password",
  alreadySignedIn: "Already have an account? Sign In",
  newUser: "New User? Sign Up",
  forgotPswd: "Forgot Password?",
  signUp: "Sign Up",
  signIn: "Sign In",
  loginWithSocialAccounts: "Log in quickly using one of your social accounts",
  or: "Or",
  back: "Back",
  errorForRadioButton: "Select an option to proceed!",
  appClosedStateContent:
    "The App is temporarily down for maintenance. We should be back shortly. Thank you for your patience.",
  connectedBankAccounts: "Connected Bank Accounts",
  noBankAccountsConnected: "No bank accounts connected.",
  addBankAccButton: "Add Bank Account",
  sendMoneyButton: "Send Money",
  connectingPlaid:
    "Please wait while we redirect to Plaid for connecting your bank...",
  exchangeRate: "Exchange Rate",
  inrPerUsd: "INR/USD",
  firstName: "First Name",
  lastName: "Last Name",
  street: "Street",
  city: "City",
  state: "State",
  postalCode: "Postal Code",
  countryCode: "Country Code",
  dateOfBirth: "DOB",
  transferDetails: "Transfer Details",
  selectConnectedAccount: "Select your account.",
  confirmButton: "Confirm",
  promotionCode: "Promo Code",
  applyButton: "Apply",
  addressLineOne: "Address Line 1",
  addressLineTwo: "Address Line 2",
  applied: "Applied",
  appliedPromoRate: "Applied Promo Rate",
  connectBankButton: "Connect Bank",
  connectBankUsingPlaid:
    "Connect your bank account to Payezy using Plaid. Plaid uses best-in-class encryption to help protect your data.",
  payezyFxRate: "Payezy FX Rate",
  connectMoreBanksContent: "Connect more bank accounts.",
  addBank: "Add Bank",
  beneficiaryDisclaimer:
    "Disclaimer - Ony NRO/Savings account is supported. Please do not add NRE account as beneficiary. Also make sure the name of the beneficiary is as per the bank records.",
  promoRate: "Promo Rate",
  createdButton: "Created",
  selectBankAccount: "Select type of bank account",
  supportedBankAccountModalContent:
    "We do not support transfers to NRE accounts. Only NRO/Savings account is supported as beneficiary account.",
  connectedButton: "Connected",
  deleteButton: "Delete",
  reconnectButton: "Reconnect",
  emailPasswordNote:
    "Password must be at least 8 characters long, include at least one uppercase letter, one number, and one special character. Special characters allowed: @#$%^&*_",
  retryDeliveryMessage:
    "Your transfer request has been received. Please wait while we complete your settlement to the beneficiary.",
  reconnectRequired: "(Reconnect Required)",
  removeButton: "Remove",
  beneficiaryFullNameNote:
    "Also make sure the name of the beneficiary is as per the bank records.",
  maintenanceContent:
    "Our App is undergoing a maintenance. Sorry for the inconvenience. We will resume service soon.",
  verifyButton: "Verify",
  redirectingToPersona: "Redirecting to Persona...",
  verificationRequired: "(Verification Required)",
  usdEnterMode: "UsdEnterMode",
  inrEnterMode: "InrEnterMode",
};
