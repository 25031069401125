import React, { useState, useContext } from "react";
import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import { auth } from "../../firebase";
import styles from "./index.module.scss";
import passwordHideIcon from "../../assets/passwordHideIcon.svg";
import passwordShowIcon from "../../assets/passwordShowIcon.svg";
import { AppContext } from "../../context";
import cx from "classnames";
import ButtonRade from "../../components/RadeButtons";
import Modal from "react-modal";
import PasswordResetSuccessful from "../PasswordResetSuccessful/PasswordResetSuccessful";
import payezyLogo from "../../assets/payezyLogo.svg";
const PasswordReset = ({
  oobCode,
  setGoogleLoginPageNumber,
  setOnClickLoginButton,
}) => {
  const { isMobile } = useContext(AppContext);

  // State to track new password and confirmation
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  //state to set the modal to open or close
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // State to track whether password is visible
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  // State to track whether confirm password is visible
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  // toggle password visibility
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  // Separate toggle function for confirm password
  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };
  // Handle new password input
  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    setErrorMessage(""); // Clear errors when typing
  };

  // Handle confirm password input
  const handleConfirmPasswordChange = (e) => {
    setConfirmNewPassword(e.target.value);
    setErrorMessage(""); // Clear errors when typing
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    // Optionally, add regex validation for password complexity
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(newPassword)) {
      setErrorMessage(
        "Password must be at least 8 characters long, include at least one uppercase letter, one number, and one special character."
      );
      return;
    }

    try {
      // Verify the reset code before proceeding (optional, but recommended)
      await verifyPasswordResetCode(auth, oobCode);

      // Reset the password with the oobCode
      await confirmPasswordReset(auth, oobCode, newPassword);

      setModalIsOpen(true);
    } catch (error) {
      console.log("error", error);
      // Check for specific error codes
      if (error.code === "auth/expired-action-code") {
        setErrorMessage(
          "This password reset link has expired. Please request a new one."
        );
      } else {
        setErrorMessage(error.message);
      }
    }
  };

  return (
    <>
      <div
        className={cx(styles.modalContent, {
          [styles.modalContentMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.logoContainer, {
            [styles.logoContainerMob]: isMobile,
          })}
        >
          <img
            src={payezyLogo}
            alt="logo"
            className={cx(styles.payezyLogo, {
              [styles.payezyLogoMob]: isMobile,
            })}
          />
        </div>
        <div
          className={cx(styles.resetPswd, {
            [styles.resetPswdMob]: isMobile,
          })}
        >
          Reset Your Password
        </div>
        <div
          className={cx(styles.paswdInputField, {
            [styles.pswdInputFieldMob]: isMobile,
          })}
        >
          <input
            placeholder="New Password"
            type={isPasswordVisible ? "text" : "password"}
            value={newPassword}
            onChange={handleNewPasswordChange}
            className={cx(styles.input, {
              [styles.inputWeb]: !isMobile,
              [styles.inputMob]: isMobile,
            })}
          />
          <img
            src={isPasswordVisible ? passwordShowIcon : passwordHideIcon}
            alt="Toggle Password Visibility"
            className={styles.passwordIcon}
            onClick={togglePasswordVisibility}
          />
        </div>
        <div
          className={cx(styles.confirmPaswdInputField, {
            [styles.confirmPswdInputFieldMob]: isMobile,
          })}
        >
          <input
            placeholder="Confirm New Password"
            type={isConfirmPasswordVisible ? "text" : "password"}
            value={confirmNewPassword}
            onChange={handleConfirmPasswordChange}
            className={cx(styles.input, {
              [styles.inputWeb]: !isMobile,
              [styles.inputMob]: isMobile,
            })}
          />
          <img
            src={isConfirmPasswordVisible ? passwordShowIcon : passwordHideIcon}
            alt="Toggle Password Visibility"
            className={styles.passwordIcon}
            onClick={toggleConfirmPasswordVisibility}
          />
        </div>
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
        <div
          className={cx(styles.buttonContainer, {
            [styles.buttonContainerMob]: isMobile,
          })}
        >
          <ButtonRade
            className={cx(styles.continueButton, {
              [styles.continueButtonMob]: isMobile,
            })}
            onClick={handleResetPassword}
          >
            Reset Password
          </ButtonRade>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <PasswordResetSuccessful
          setGoogleLoginPageNumber={setGoogleLoginPageNumber}
          setOnClickLoginButton={setOnClickLoginButton}
        />
      </Modal>
    </>
  );
};

export default PasswordReset;
