import React, { useContext, useState } from "react";
import cx from "classnames";
import { AppContext } from "../../context";
import styles from "./index.module.scss";
import AddressDetailsFetch from "./AddressDetailsFetch";
import ButtonRade from "../../components/RadeButtons";
const ProfileDetailsFetch = ({
  state,
  firstName,
  setSubdivision,
  handleSubmitResubmitKYC,
  setEmailAddress,
  middleName,
  lastName,
  phoneNumber,
  emailAddress,
  street,
  street2,
  setDateOfBirth,
  city,
  subdivision,
  setKYCPageNumber,
  handleChangeInputMiddleName,
  handleChangeInputStreet,
  handleChangeInputStreet2,
  handleChangeInputSubdivision,
  handleChangeInputPostalCode,
  handleChangeInputCountryCode,
  handleChangeInputCity,
  setErrors,
  postalCode,
  countryCode,
  dateOfBirth,
  message,
  reSubmitKyc,
  modalIsOpen,
  setModalIsOpen,
  customerGUID,
  handlePhoneNumberChange,
  handleChangeInputLastName,
  handleChangeInputFieldName,
  handleChangeInputFieldEmail,
  handlesubmitUpdateProfile,
  handleDateOfBirthChange,
  validateForm,
  handleSubmit,
  errors,
  setReSubmitKyc,
}) => {
  const { isMobile, profileEmail } = useContext(AppContext);
  const [currentStep, setCurrentStep] = useState(1); // Step 1 for Profile, Step 2 for Address
  console.log("errors.firstName", errors.firstName);
  // useEffect(() => {
  //   const fetchUserData = async () => {
  //     const usersRef = collection(db, "userData");
  //     const documentId = profileEmail; // email for the logged-in user, to check if exists
  //     const userDocRef = doc(usersRef, documentId);

  //     try {
  //       const documentSnapshot = await getDoc(userDocRef);

  //       if (documentSnapshot.exists()) {
  //         const userData = documentSnapshot.data();
  //         console.log("userData", userData.date_of_birth);
  //         setEmailAddress(userData.email_address);
  //         setDateOfBirth(userData.date_of_birth);

  //         // Autofill the form fields with data from Firestore
  //       }
  //     } catch (error) {
  //       console.error("Error fetching user data:", error);
  //     }
  //   };

  //   fetchUserData();
  // }, [profileEmail, reSubmitKyc]);

  const validateFields = () => {
    const newErrors = {};

    // Validate first name
    if (!firstName.trim()) {
      newErrors.firstName = "First name is required.";
    }

    // Validate last name
    if (!lastName.trim()) {
      newErrors.lastName = "Last name is required.";
    }

    // Validate phone number
    const phoneRegex = /^\d{10}$/;
    if (!phoneNumber.trim()) {
      newErrors.phoneNumber = "Phone number is required.";
    } else if (!phoneRegex.test(phoneNumber)) {
      newErrors.phoneNumber = "Invalid phone number format (Include +)";
    }

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailAddress.trim()) {
      newErrors.emailAddress = "Email address is required.";
    } else if (!emailRegex.test(emailAddress)) {
      newErrors.emailAddress = "Invalid email address.";
    }

    // Validate date of birth
    if (!dateOfBirth.trim()) {
      newErrors.dateOfBirth = "Date of birth is required.";
    }

    return newErrors;
  };

  const handleNextStep = () => {
    const validationErrors = validateFields();
    console.log("validationErrors", validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setErrors([]);
      setCurrentStep(currentStep + 1);
    } else {
      setErrors(validationErrors);
    }
  };
  const handleSubmitBackButton = () => {
    setCurrentStep(currentStep - 1);
  };
  if (currentStep === 1) {
    return (
      <div>
        <div
          className={cx(styles.profileNameEmailContainer, {
            [styles.profileNameEmailContainerWeb]: !isMobile,
            [styles.profileNameEmailContainerMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.firstFlexInStep1, {
              [styles.firstFlexMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.IDDetailsDiv, {
                [styles.IDDetailsDivWeb]: !isMobile,
                [styles.IDDetailsDivMob]: isMobile,
              })}
            >
              <p
                className={cx(styles.profileNameContainer, {
                  [styles.profileNameContainerWeb]: !isMobile,
                  [styles.profileNameContainerMob]: isMobile,
                })}
              >
                First Name *
              </p>
              <input
                key="name"
                type="text"
                className={cx(styles.inputName, {
                  [styles.inputNameWeb]: !isMobile,
                  [styles.inputNameMob]: isMobile,
                  [styles.disabledInput]:
                    reSubmitKyc === "pending" || reSubmitKyc === "Verified",
                })}
                placeholder="Enter your first name (as per your ID)"
                onChange={handleChangeInputFieldName}
                value={firstName}
                disabled={
                  reSubmitKyc === "pending" || reSubmitKyc === "Verified"
                }
              />
              {errors && (
                <div className={styles.errorMessage}>{errors.firstName}</div>
              )}
            </div>

            <div
              className={cx(styles.email, {
                [styles.emailWeb]: !isMobile,
                [styles.emailMob]: isMobile,
              })}
            >
              <p
                className={cx(styles.emailContainer, {
                  [styles.emailContainerWeb]: !isMobile,
                  [styles.emailContainerMob]: isMobile,
                })}
              >
                Middle Name
              </p>
              <input
                key="email"
                type="email"
                className={cx(styles.inputName, {
                  [styles.inputNameWeb]: !isMobile,
                  [styles.inputNameMob]: isMobile,
                  [styles.disabledInput]:
                    reSubmitKyc === "pending" || reSubmitKyc === "Verified",
                })}
                placeholder="Enter your middle name (as per your ID)"
                onChange={handleChangeInputMiddleName}
                value={middleName}
                disabled={
                  reSubmitKyc === "pending" || reSubmitKyc === "Verified"
                }
              />
            </div>
          </div>
          <div
            className={cx(styles.secondFlex, {
              [styles.secondFlexMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.phoneNumber, {
                [styles.phoneNumberWeb]: !isMobile,
                [styles.phoneNumberMob]: isMobile,
              })}
            >
              <p
                className={cx(styles.profileNameContainer, {
                  [styles.profileNameContainerWeb]: !isMobile,
                  [styles.profileNameContainerMob]: isMobile,
                })}
              >
                Last Name *
              </p>
              <input
                key="name"
                type="text"
                className={cx(styles.inputName, {
                  [styles.inputNameWeb]: !isMobile,
                  [styles.inputNameMob]: isMobile,
                  [styles.disabledInput]:
                    reSubmitKyc === "pending" || reSubmitKyc === "Verified",
                })}
                placeholder="Enter your last name (as per your ID)"
                onChange={handleChangeInputLastName}
                value={lastName}
                disabled={
                  reSubmitKyc === "pending" || reSubmitKyc === "Verified"
                }
              />
              {errors && (
                <div className={styles.errorMessage}>{errors.lastName}</div>
              )}
            </div>
            <div
              className={cx(styles.email, {
                [styles.emailWeb]: !isMobile,
                [styles.emailMob]: isMobile,
              })}
            >
              <p
                className={cx(styles.profileNameContainer, {
                  [styles.profileNameContainerWeb]: !isMobile,
                  [styles.profileNameContainerMob]: isMobile,
                })}
              >
                Email *
              </p>
              <input
                key="name"
                type="text"
                className={cx(styles.inputName, {
                  [styles.inputNameWeb]: !isMobile,
                  [styles.inputNameMob]: isMobile,
                  [styles.disabledInput]:
                    reSubmitKyc === "pending" || reSubmitKyc === "Verified",
                })}
                placeholder="Enter your email "
                onChange={handleChangeInputFieldEmail}
                value={emailAddress}
                // disabled
              />
              {errors && (
                <div className={styles.errorMessage}>{errors.emailAddress}</div>
              )}
            </div>
          </div>
          <div
            className={cx(styles.secondFlex, {
              [styles.secondFlexMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.phoneNumber, {
                [styles.phoneNumberWeb]: !isMobile,
                [styles.phoneNumberMob]: isMobile,
              })}
            >
              <p
                className={cx(styles.profileNameContainer, {
                  [styles.profileNameContainerWeb]: !isMobile,
                  [styles.profileNameContainerMob]: isMobile,
                })}
              >
                Phone *
              </p>

              <span className={styles.phonePrefix}>
                +1{" "}
                <input
                  key="phone"
                  type="text"
                  className={cx(styles.inputPhone, {
                    [styles.inputPhoneWeb]: !isMobile,
                    [styles.inputPhoneMob]: isMobile,
                    [styles.disabledInput]:
                      reSubmitKyc === "pending" || reSubmitKyc === "Verified",
                  })}
                  placeholder="Enter your phone number"
                  onChange={handlePhoneNumberChange}
                  value={phoneNumber.replace(/^\+1/, "")} // Display value without "+1" prefix
                  disabled={
                    reSubmitKyc === "pending" || reSubmitKyc === "Verified"
                  }
                />
              </span>

              {/* Input field for the rest of the phone number */}

              {errors && (
                <div className={styles.errorMessage}>{errors.phoneNumber}</div>
              )}
            </div>
            <div
              className={cx(styles.email, {
                [styles.emailWeb]: !isMobile,
                [styles.emailMob]: isMobile,
              })}
            >
              <p
                className={cx(styles.profileNameContainer, {
                  [styles.profileNameContainerWeb]: !isMobile,
                  [styles.profileNameContainerMob]: isMobile,
                })}
              >
                DOB *
              </p>
              <input
                key="dob"
                type="date"
                className={cx(styles.inputName, {
                  [styles.inputNameWeb]: !isMobile,
                  [styles.inputNameMob]: isMobile,
                  [styles.disabledInput]:
                    reSubmitKyc === "pending" || reSubmitKyc === "Verified",
                })}
                onChange={handleDateOfBirthChange}
                value={dateOfBirth}
                disabled={
                  reSubmitKyc === "pending" || reSubmitKyc === "Verified"
                }
              />
              {errors && (
                <div className={styles.errorMessage}>{errors.dateOfBirth}</div>
              )}
            </div>
          </div>

          {/* {reSubmitKyc === "Not Initialized" && ( */}
          <div
            className={cx(styles.updateProfileButtonDiv, {
              [styles.updateProfileButtonDivWeb]: !isMobile,
              [styles.updateProfileButtonDivMob]: isMobile,
            })}
          >
            <ButtonRade
              outline
              customStyling={cx(styles.backButtonButton, {
                [styles.backButtonButtonWeb]: !isMobile,
                [styles.backButtonButtonMob]: isMobile,
              })}
              onClick={handleSubmitBackButton}
            >
              Back
            </ButtonRade>
            <ButtonRade
              outline
              customStyling={cx(styles.updateProfileButton, {
                [styles.updateProfileButtonWeb]: !isMobile,
                [styles.updateProfileButtonMob]: isMobile,
              })}
              onClick={handleNextStep} // Call handleNextStep on click
            >
              Proceed
            </ButtonRade>
          </div>
          {/* )} */}
        </div>
      </div>
    );
  } else if (currentStep === 2) {
    return (
      <AddressDetailsFetch
        state={state}
        setEmailAddress={setEmailAddress}
        setSubdivision={setSubdivision}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        firstName={firstName}
        middleName={middleName}
        lastName={lastName}
        phoneNumber={phoneNumber}
        emailAddress={emailAddress}
        street={street}
        street2={street2}
        city={city}
        subdivision={subdivision}
        postalCode={postalCode}
        countryCode={countryCode}
        dateOfBirth={dateOfBirth}
        message={message}
        reSubmitKyc={reSubmitKyc}
        customerGUID={customerGUID}
        handlePhoneNumberChange={handlePhoneNumberChange}
        handleChangeInputLastName={handleChangeInputLastName}
        handleChangeInputFieldName={handleChangeInputFieldName}
        handleChangeInputFieldEmail={handleChangeInputFieldEmail}
        handlesubmitUpdateProfile={handlesubmitUpdateProfile}
        handleDateOfBirthChange={handleDateOfBirthChange}
        validateForm={validateForm}
        handleSubmit={handleSubmit}
        errors={errors}
        setKYCPageNumber={setKYCPageNumber}
        handleChangeInputMiddleName={handleChangeInputMiddleName}
        handleChangeInputStreet={handleChangeInputStreet}
        handleChangeInputStreet2={handleChangeInputStreet2}
        handleChangeInputCity={handleChangeInputCity}
        handleChangeInputSubdivision={handleChangeInputSubdivision}
        handleChangeInputPostalCode={handleChangeInputPostalCode}
        handleChangeInputCountryCode={handleChangeInputCountryCode}
        setErrors={setErrors}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
      />
    );
  }
};

export default ProfileDetailsFetch;
