import React from "react";
import ButtonRade from "../../components/RadeButtons";
import { SEND_ENUM } from "../../enums/sendEnum";
import styles from "./index.module.scss";

const ClosedStateModal = ({ setClosedStateModalIsOpen }) => {
  //Function to close the Modal.
  const handleOnClickClose = () => {
    setClosedStateModalIsOpen(false);
  };

  return (
    <div>
      <div className={styles.popUpContent}>
        {SEND_ENUM.appClosedStateContent}
      </div>
      <div className={styles.popUpButtonDiv}>
        <ButtonRade
          outline
          customStyling={styles.closeButton}
          onClick={handleOnClickClose}
        >
          {SEND_ENUM.closeButton}
        </ButtonRade>
      </div>
    </div>
  );
};

export default ClosedStateModal;
