export async function createPlaidExtAccount(
  customerGUID,
  name,
  plaidPublicToken,
  plaidAccountId,
  idToken
) {
  if (
    !customerGUID ||
    !name ||
    !plaidPublicToken ||
    !plaidAccountId ||
    !idToken
  ) {
    throw new Error("Missing required parameters");
  }

  const url = `${process.env.REACT_APP_PAYEZY_SERVER_URI}/create-external-bank-account/${customerGUID}`;

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify({
      name,
      plaidPublicToken,
      plaidAccountId,
    }),
  };

  try {
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      const errorData = await response.json();
      console.error("Failed to create external bank account", errorData);
      
      if (errorData.failureCode === "duplicate") {
        throw new Error(
          "This bank account has already been connected. Please try connecting a different account."
        );
      }
      
      throw new Error(
        `Failed to create external bank account: ${
          errorData.message || response.statusText
        }`
      );
    }

    const responseData = await response.json();
    console.log("External bank account created successfully", responseData);
    return responseData;
  } catch (error) {
    console.error("Error creating external bank account:", error);
    throw error;
  }
}
