export async function verifyCybridCustomer(customerGUID, idToken) {
  // Validate the customerGUID
  if (!customerGUID) {
    throw new Error("customerGUID is required");
  }

  // Construct the URL with customerGUID
  const url = `${process.env.REACT_APP_PAYEZY_SERVER_URI}/verify-cybrid-customer/${customerGUID}`;

  // Call verification API
  const verifyRequestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
  };

  const response = await fetch(url, verifyRequestOptions);

  if (!response.ok) {
    const errorData = await response.json();
    console.error("Verification failed", errorData);
    throw new Error(
      `Verification failed: ${errorData.message || response.statusText}`
    );
  }

  const verifyResponseData = await response.json();

  // Handle KYC Status
  // Extract personaInquiryId and under_review from the response
  const { personaInquiryId, under_review } = verifyResponseData;

  // Return an object containing both personaInquiryId and under_review
  return { personaInquiryId, under_review };
}
