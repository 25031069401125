import React from "react";
import ButtonRade from "../../components/RadeButtons";
import { SEND_ENUM } from "../../enums/sendEnum";
import styles from "./index.module.scss";
import { deleteExtBankAccount } from "../../api/utilities/deleteExtBankAccount";

const ConfirmDeleteExtBankAccount = ({
  setModalIsOpen,
  selectedAccountId,
  setBankAccounts,
  selectedAccountName,
  setErrorMessage,
}) => {
  const handleClickConfirm = async () => {
    try {
      await deleteExtBankAccount(selectedAccountId);

      // Remove the deleted account from the state
      setBankAccounts((prevAccounts) => ({
        ...prevAccounts,
        objects: prevAccounts.objects.filter(
          (account) => account.guid !== selectedAccountId
        ),
        total: prevAccounts.total - 1,
      }));
      setModalIsOpen(false);
      console.log(`Successfully deleted account with ID: ${selectedAccountId}`);
    } catch (error) {
      console.error("Error deleting bank account:", error);
      setErrorMessage(
        "Something went wrong while deleting your bank account. Please try again."
      );
    }
  };

  //Function to close the Modal.
  const handleOnClickCancel = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <div className={styles.popUpContent}>
        Are you sure that you want to delete your {selectedAccountName} Account?
      </div>
      <div className={styles.popUpButtonDiv}>
        <ButtonRade
          outline
          customStyling={styles.cancelButton}
          onClick={handleOnClickCancel}
        >
          {SEND_ENUM.cancel}
        </ButtonRade>
        <ButtonRade
          outline
          customStyling={styles.continueButton}
          onClick={handleClickConfirm}
        >
          {SEND_ENUM.confirmButton}
        </ButtonRade>
      </div>
    </div>
  );
};

export default ConfirmDeleteExtBankAccount;
