export const SUPPORT_ENUM = {
  supportTitle: "Help & Support",
  supportTicket: "Create a support ticket",
  feedbackOrBug: "Send Feedback/Bug",
  exploreDocs: "Explore Docs",
  issueType: "Issue type",
  enterOrderId: "Enter your Order ID",
  message: "Message",
  create: "Create",
  sendButton: "Send",
  notRobot: "I’m not a robot",
  myTickets: "My tickets",
  ticket: "ticket #",
  type: "type",
  ticketStatus: "status",
  support: "Support",
  reply: "Reply",
  supportNote: "For any other queries, please write to us at ",
  issueFacing:
    "Please enter your feedback or briefly explain the bug you faced.",
};
