import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { POPUP_ENUM } from "../../enums/popUpEnum";
import ButtonRade from "../../components/RadeButtons";
import cx from "classnames";
import { AppContext } from "../../context";

const ConfirmUpdateProfile = ({
  reSubmitKyc,
  confirmUpdateModalOpen,
  setConfirmUpdateModalOpen,
  handleSubmitResubmitKYC,
  handlesubmitUpdateProfile,
  modalIsOpen,
  setModalIsOpen,
  handleSubmit,
  personaInquiryId,
  handleCreateCybridCustomer,
}) => {
  const { isMobile, setKYCPageNumber, userData } = useContext(AppContext);

  // State to handle loading state
  const [isLoading, setIsLoading] = useState(false);
  const [resubmiDetails, setResumbitDetails] = useState(null);
  // Function that handles the onclick of the cancel button
  const handleClickCancelButton = () => {
    if (confirmUpdateModalOpen) {
      setConfirmUpdateModalOpen(false);
    }
    if (modalIsOpen) {
      setModalIsOpen(false); // Close the modal
    }
  };
  useEffect(() => {
    handleClickConfirmButton();
  }, []);
  // Function that handles the onclick of the confirm button
  const handleClickConfirmButton = async () => {
    try {
      setIsLoading(true);

      if (reSubmitKyc !== "rejected") {
        const isResponse = await handleCreateCybridCustomer(); // Execute the handleSubmit function
        if (isResponse) {
          setKYCPageNumber(1); // Start loading
          setIsLoading(false);
          setModalIsOpen(false);
        } else {
          setResumbitDetails(true);
        }
      } else {
        const isResponse = await handleSubmitResubmitKYC();
        if (isResponse) {
          setKYCPageNumber(1); // Start loading
          setModalIsOpen(false);
          setConfirmUpdateModalOpen(false);
        } else {
          setResumbitDetails(true);
        }
      }
    } catch (error) {
      console.error("Error occurred during submission:", error);
    } finally {
      setIsLoading(false); // Stop loading after function execution
    }
  };

  const CardBody = () => (
    <>
      {" "}
      {!isLoading ? (
        <div>
          {!resubmiDetails ? (
            <>
              <div className={styles.loaderContainer}>
                You will be redirected to Persona for completion of KYC.
                <div className={styles.spinnerOverlay}>
                  <div className={styles.spinnerContainer} />
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className={cx(styles.acceptTerms, {
                  [styles.acceptTermsMob]: isMobile,
                })}
              >
                Something went wrong! Please resubmit details.
              </div>

              <div
                className={cx(styles.buttonContainer, {
                  [styles.buttonContainerMob]: isMobile,
                })}
              >
                <ButtonRade
                  className={cx(styles.cancelButton, {
                    [styles.cancelButtonMob]: isMobile,
                  })}
                  onClick={handleClickCancelButton}
                  disabled={isLoading}
                >
                  Okay
                </ButtonRade>

                {/* Show loader while loading */}
              </div>
            </>
          )}
        </div>
      ) : (
        <div className={styles.loaderContainer}>
          You will be redirected to Persona for completion of KYC.
          {userData.cybrid_customer_details.customer_guid && (
            <span>Please resume KYC</span>
          )}
          <div className={styles.spinnerOverlay}>
            <div className={styles.spinnerContainer} />
          </div>
        </div>
      )}
    </>
  );

  return CardBody();
};

export default ConfirmUpdateProfile;
