import React, { useContext } from "react";
import styles from "./RecurringTransferCreatedModal.module.scss";
import ButtonRade from "../../components/RadeButtons";
import cx from "classnames";
import { AppContext } from "../../context";
import { useNavigate } from "react-router-dom";
import { RECURRING_TRANSFER_ENUM } from "../../enums/transferEnums";

const RecurringTransferCreatedModal = ({ setModalIsOpen }) => {
  const { isMobile } = useContext(AppContext);
  let navigate = useNavigate();

  const handleClickCloseButton = () => {
    setModalIsOpen(false);
    navigate("/autoSend");
  };
  const CardBody = () => (
    <div>
      <div
        className={cx(styles.serviceTicketSubmittedTitle, {
          [styles.serviceTicketSubmittedTitleMob]: isMobile,
        })}
      >
        {RECURRING_TRANSFER_ENUM.recurringTransferCreated}
      </div>
      <div
        className={cx(styles.buttonContainer, {
          [styles.buttonContainerMob]: isMobile,
        })}
      >
        <ButtonRade
          className={cx(styles.continueButton, {
            [styles.continueButtonMob]: isMobile,
          })}
          onClick={handleClickCloseButton}
        >
          Close
        </ButtonRade>
      </div>
    </div>
  );
  return CardBody();
};

export default RecurringTransferCreatedModal;
