import React, { useContext } from "react";
import cx from "classnames";
import { AppContext } from "../../context";
import ConfirmUpdateProfile from "../ConfirmUpdateProfile/ConfirmUpdateProfile";
import Modal from "react-modal";
import ProfileDetailsFetch from "./profileDetailsFetch";
import styles from "./index.module.scss";

const Profile = ({
  state,
  emailAddress,
  setEmailAddress,
  setDateOfBirth,
  firstName,
  handleSubmitResubmitKYC,
  middleName,
  lastName,
  phoneNumber,
  street,
  street2,
  city,
  subdivision,
  setKYCPageNumber,
  handleChangeInputMiddleName,
  handleChangeInputStreet,
  handleChangeInputStreet2,
  handleChangeInputSubdivision,
  handleChangeInputPostalCode,
  handleChangeInputCountryCode,
  handleChangeInputCity,

  postalCode,
  countryCode,
  dateOfBirth,
  message,
  reSubmitKyc,
  modalIsOpen,
  setModalIsOpen,
  customerGUID,
  handlePhoneNumberChange,
  handleChangeInputLastName,
  handleChangeInputFieldName,
  handleChangeInputFieldEmail,
  handlesubmitUpdateProfile,
  handleDateOfBirthChange,
  validateForm,
  handleSubmit,
  errors,
  setErrors,
  setSubdivision,
  setReSubmitKyc,
}) => {
  const { isMobile } = useContext(AppContext);
  // console.log("modalIsOpen", modalIsOpen);
  const CardBody = () => (
    <div
      className={cx(styles.profileMainContainer, {
        [styles.profileMainContainerMob]: isMobile,
      })}
    >
      <ProfileDetailsFetch
        handleSubmitResubmitKYC={handleSubmitResubmitKYC}
        setDateOfBirth={setDateOfBirth}
        setEmailAddress={setEmailAddress}
        setReSubmitKyc={setReSubmitKyc}
        setSubdivision={setSubdivision}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        firstName={firstName}
        middleName={middleName}
        lastName={lastName}
        phoneNumber={phoneNumber}
        emailAddress={emailAddress}
        state={state}
        street={street}
        street2={street2}
        city={city}
        subdivision={subdivision}
        postalCode={postalCode}
        countryCode={countryCode}
        dateOfBirth={dateOfBirth}
        message={message}
        reSubmitKyc={reSubmitKyc}
        customerGUID={customerGUID}
        handlePhoneNumberChange={handlePhoneNumberChange}
        handleChangeInputLastName={handleChangeInputLastName}
        handleChangeInputFieldName={handleChangeInputFieldName}
        handleChangeInputFieldEmail={handleChangeInputFieldEmail}
        handlesubmitUpdateProfile={handlesubmitUpdateProfile}
        handleDateOfBirthChange={handleDateOfBirthChange}
        validateForm={validateForm}
        handleSubmit={handleSubmit}
        errors={errors}
        setErrors={setErrors}
        setKYCPageNumber={setKYCPageNumber}
        handleChangeInputMiddleName={handleChangeInputMiddleName}
        handleChangeInputStreet={handleChangeInputStreet}
        handleChangeInputStreet2={handleChangeInputStreet2}
        handleChangeInputCity={handleChangeInputCity}
        handleChangeInputSubdivision={handleChangeInputSubdivision}
        handleChangeInputPostalCode={handleChangeInputPostalCode}
        handleChangeInputCountryCode={handleChangeInputCountryCode}
      />
    </div>
  );

  return (
    <>
      <div
        className={cx(styles.routesContainer, {
          [styles.routesContainerMob]: isMobile,
        })}
      >
        {" "}
        {CardBody()}
      </div>

      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <ConfirmUpdateProfile
          handleSubmitResubmitKYC={handleSubmitResubmitKYC}
          handleSubmit={handleSubmit}
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          setKYCPageNumber={setKYCPageNumber}
          handlesubmitUpdateProfile={handlesubmitUpdateProfile}
        />
      </Modal>
    </>
  );
};

export default Profile;
