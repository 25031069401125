export const validateForm = (formData) => {
  const errors = {};
  const {
    firstName,
    middleName,
    lastName,
    street,
    street2,
    city,
    subdivision,
    postalCode,
    countryCode,
    dateOfBirth,
    phoneNumber,
    emailAddress,
  } = formData;

  // Validate Name
  if (!firstName) errors.firstName = "First name is required!";
  if (typeof firstName !== "string")
    errors.firstName = "First name must be string!";
  if (middleName && typeof middleName !== "string")
    errors.middleName = "Middle name should be a string";
  if (!lastName) errors.lastName = "Last name is required";

  // Validate Address
  if (!street) errors.street = "Street address is required";
  if (street2 && typeof street2 !== "string")
    errors.street2 = "Street2 should be a string";
  if (!city) errors.city = "City is required";
  if (!subdivision) errors.subdivision = "Subdivision is required";
  if (!postalCode || !/^\d{5}(-\d{4})?$/.test(postalCode))
    errors.postalCode = "Invalid postal code";
  if (!countryCode || countryCode.length !== 2)
    errors.countryCode = "Invalid country code";

  // Validate Date of Birth
  if (!dateOfBirth || !/^\d{4}-\d{2}-\d{2}$/.test(dateOfBirth))
    errors.dateOfBirth = "Invalid date of birth format";

  // Validate Phone Number
  if (!phoneNumber || !/^\+\d{10,15}$/.test(phoneNumber))
    errors.phoneNumber = "Invalid phone number format";

  // Validate Email Address
  if (!emailAddress || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAddress))
    errors.emailAddress = "Invalid email address";

  return Object.keys(errors).length === 0 ? true : errors;
};
