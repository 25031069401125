import React, { useContext, useState, useRef } from "react";
import cx from "classnames";
import { AppContext } from "../../context";
import styles from "./index.module.scss";
import ButtonRade from "../../components/RadeButtons";
import axios from "axios";
import { useEffect } from "react";
import SelectBox from "../../components/SelectBox/SelectBox";
import statesData from "../../Data/USStates.json";

const AddressDetailsFetch = ({
  street,
  state,
  setSubdivision,
  street2,
  city,
  subdivision,
  postalCode,
  countryCode,
  setKYCPageNumber,
  handleChangeInputStreet,
  handleChangeInputStreet2,
  handleChangeInputCity,
  handleChangeInputSubdivision,
  handleChangeInputPostalCode,
  handleChangeInputCountryCode,
  message,
  reSubmitKyc,
  modalIsOpen,
  setModalIsOpen,
  customerGUID,
  validateForm,
  handleSubmit,
  errors,
  setErrors,
  setCurrentStep,
  currentStep,
}) => {
  const { isMobile } = useContext(AppContext);
  const [localErrors, setLocalErrors] = useState("");
  const [autocompleteResults, setAutocompleteResults] = useState([]);
  const autocompleteRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedResult, setSelectedResult] = useState(null);
  const [states, setStates] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);

  console.log("subdivision", subdivision);
  useEffect(() => {
    setStates(statesData);
    setSelectedStates(state);
  }, []);
  useEffect(() => {
    if (street) {
      fetchAutocompleteResults(street);
    }
  }, [street]);
  console.log("state", state);
  // Handle clicks outside of the autocomplete container
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        autocompleteRef.current &&
        !autocompleteRef.current.contains(event.target)
      ) {
        setAutocompleteResults([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleChangeSelectState = (e) => {
    setSubdivision(e.target.value.value);
    setSelectedStates(e.target.value.label);
  };
  const fetchAutocompleteResults = async (query) => {
    setIsLoading(true);
    // if (autocompleteResults.length <= 1) {
    if (query && query.length >= 3 && subdivision) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ADDRESS_FETCH_SERVER_URI}/autocomplete`,
          {
            params: { q: query, state: subdivision },
          }
        );
        console.log("response", response);
        setAutocompleteResults(response.data.results || []);
      } catch (error) {
        console.error("Error fetching autocomplete results:", error);
      } finally {
        setIsLoading(false);
      }
    }
    // }
  };
  console.log("autocompleteResults", autocompleteResults.length);
  const renderAutocompleteResults = () =>
    autocompleteResults.length >= 1 && (
      <div className={styles.autocompleteResults} ref={autocompleteRef}>
        {autocompleteResults.length >= 1 &&
          autocompleteResults.map((result, index) => (
            <div
              key={index}
              className={styles.autocompleteResultItem}
              onClick={() => handleSelectResult(result)}
            >
              <p className={styles.autoCompleteDetails}>
                {result.street ? result.street : ""}{" "}
                {result.street2 ? result.street2 : ""}{" "}
                {result.city ? result.city : ""} {result.subdivision}
                {result.postalCode ? result.postalCode : ""}{" "}
                {result.countryCode ? result.countryCode : ""}
              </p>
            </div>
          ))}
      </div>
    );
  useEffect(() => {
    console.log("auto complete reuslt", autocompleteResults);
  }, [autocompleteResults]);
  const handleChange = (e, field) => {
    const { value } = e.target;

    if (field === "street") {
      handleChangeInputStreet(e);
      fetchAutocompleteResults(value);
    } else if (field === "street2") {
      handleChangeInputStreet2(e);
      fetchAutocompleteResults(value);
    } else if (field === "city") {
      handleChangeInputCity(e);
      fetchAutocompleteResults(value);
    } else if (field === "postcode") {
      handleChangeInputPostalCode(e);
      fetchAutocompleteResults(value);
    }
    // Add similar handling for other fields if needed
  };

  const handleSelectResult = (result) => {
    if (result) {
      setAutocompleteResults([]);
      handleChangeInputStreet({
        target: { value: result.number + " " + result.street || "" },
      });
      handleChangeInputStreet2({ target: { value: result.street2 || "" } });
      handleChangeInputCity({ target: { value: result.city || "" } });
      // handleChangeInputSubdivision({
      //   target: { value: result.subdivision || "" },
      // });
      handleChangeInputPostalCode({
        target: { value: result.zipcode || "" },
      });
      handleChangeInputCountryCode({
        target: { value: result.countryCode || "" },
      });
      setSelectedResult(result);
    }
  };

  const validateFields = () => {
    const newErrors = {};

    // Validate first name
    if (!street.trim()) {
      newErrors.street = "Street is required.";
    }

    // Validate last name
    if (!city.trim()) {
      newErrors.city = "City is required.";
    }
    if (!subdivision.trim()) {
      newErrors.subdivision = "Subdivision is required.";
    }
    if (!postalCode.trim()) {
      newErrors.postalCode = "Zipcode is required.";
    } else if (!/^\d{5}$/.test(postalCode)) {
      newErrors.postalCode = "Zipcode must be 5 digits.";
    }

    return newErrors;
  };

  const handleNextStep = () => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length === 0) {
      setErrors([]);
      setModalIsOpen(true);
    } else {
      setErrors(validationErrors);
    }
  };

  const handleSubmitBackButton = () => {
    setCurrentStep(currentStep - 1);
  };
  return (
    <div>
      <div
        className={cx(styles.profileNameEmailContainer, {
          [styles.profileNameEmailContainerWeb]: !isMobile,
          [styles.profileNameEmailContainerMob]: isMobile,
        })}
      >
        <div className={styles.selectBoxContainer}>
          <SelectBox
            placeHolder="Select State"
            options={states.map((state) => ({
              label: state.name,
              value: state.code,
            }))}
            onChange={(value) => handleChangeSelectState({ target: { value } })}
            selectedValue={selectedStates}
            setSelectedValue={(value) =>
              handleChangeSelectState({ target: { value } })
            }
            errorDismissOnclick={() => setLocalErrors("")}
          />
        </div>

        {/* <select
          onChange={(e) => handleChangeInputSubdivision(e)}
          value={subdivision}
          className={cx(styles.stateDropdown, {
            [styles.stateDropdownWeb]: !isMobile,
            [styles.stateDropdownMob]: isMobile,
          })}
        >
          <option value="">Select State</option>
          {states.map((state) => (
            <option key={state.code} value={state.code}>
              {state.name}
            </option>
          ))}
        </select> */}
        <div
          className={cx(styles.firstFlex, {
            [styles.firstFlexMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.profileName, {
              [styles.profileNameWeb]: !isMobile,
              [styles.profileNameMob]: isMobile,
            })}
          >
            <p
              className={cx(styles.profileNameContainer, {
                [styles.profileNameContainerWeb]: !isMobile,
                [styles.profileNameContainerMob]: isMobile,
              })}
            >
              Street *
            </p>
            <input
              placeholder="Enter your street"
              type="address"
              onChange={(e) => handleChangeInputStreet(e)}
              value={street}
              disabled={reSubmitKyc === "pending" || reSubmitKyc === "Verified"}
              className={cx(styles.inputName, {
                [styles.inputNameWeb]: !isMobile,
                [styles.inputNameMob]: isMobile,
                [styles.disabledInput]:
                  reSubmitKyc === "pending" || reSubmitKyc === "Verified",
              })}
            />
            {errors && (
              <div className={styles.errorMessage}>{errors.street}</div>
            )}
          </div>

          <div
            className={cx(styles.email, {
              [styles.emailWeb]: !isMobile,
              [styles.emailMob]: isMobile,
            })}
          >
            <p
              className={cx(styles.emailContainer, {
                [styles.emailContainerWeb]: !isMobile,
                [styles.emailContainerMob]: isMobile,
              })}
            >
              Street 2
            </p>
            <input
              key="street2"
              type="text"
              className={cx(styles.inputName, {
                [styles.inputNameWeb]: !isMobile,
                [styles.inputNameMob]: isMobile,
                [styles.disabledInput]:
                  reSubmitKyc === "pending" || reSubmitKyc === "Verified",
              })}
              placeholder="Enter your street 2"
              onChange={(e) => handleChange(e, "street2")}
              value={street2}
              disabled={reSubmitKyc === "pending" || reSubmitKyc === "Verified"}
            />
          </div>
        </div>
        {renderAutocompleteResults()}
        <div
          className={cx(styles.secondFlex, {
            [styles.secondFlexMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.phoneNumber, {
              [styles.phoneNumberWeb]: !isMobile,
              [styles.phoneNumberMob]: isMobile,
            })}
          >
            <p
              className={cx(styles.profileNameContainer, {
                [styles.profileNameContainerWeb]: !isMobile,
                [styles.profileNameContainerMob]: isMobile,
              })}
            >
              City *
            </p>
            <input
              key="city"
              type="text"
              className={cx(styles.inputName, {
                [styles.inputNameWeb]: !isMobile,
                [styles.inputNameMob]: isMobile,
                [styles.disabledInput]:
                  reSubmitKyc === "pending" || reSubmitKyc === "Verified",
              })}
              placeholder="Enter your city"
              onChange={(e) => handleChange(e, "city")}
              value={city}
              disabled={reSubmitKyc === "pending" || reSubmitKyc === "Verified"}
            />
            {errors && <div className={styles.errorMessage}>{errors.city}</div>}
          </div>
          <div
            className={cx(styles.email, {
              [styles.emailWeb]: !isMobile,
              [styles.emailMob]: isMobile,
            })}
          >
            <p
              className={cx(styles.profileNameContainer, {
                [styles.profileNameContainerWeb]: !isMobile,
                [styles.profileNameContainerMob]: isMobile,
              })}
            >
              Subdivision *
            </p>
            <input
              key="subdivision"
              type="text"
              className={cx(styles.inputName, {
                [styles.inputNameWeb]: !isMobile,
                [styles.inputNameMob]: isMobile,
                [styles.disabledInput]:
                  reSubmitKyc === "pending" || reSubmitKyc === "Verified",
              })}
              placeholder="Enter your subdivision"
              // onChange={handleChangeInputSubdivision}
              value={subdivision}
              // disabled={reSubmitKyc === "pending" || reSubmitKyc === "Verified"}
            />
            {errors && (
              <div className={styles.errorMessage}>{errors.subdivision}</div>
            )}
          </div>
        </div>
        <div
          className={cx(styles.secondFlex, {
            [styles.secondFlexMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.phoneNumber, {
              [styles.phoneNumberWeb]: !isMobile,
              [styles.phoneNumberMob]: isMobile,
            })}
          >
            <p
              className={cx(styles.profileNameContainer, {
                [styles.profileNameContainerWeb]: !isMobile,
                [styles.profileNameContainerMob]: isMobile,
              })}
            >
              Zip Code *
            </p>
            <input
              key="postalCode"
              type="text"
              className={cx(styles.inputName, {
                [styles.inputNameWeb]: !isMobile,
                [styles.inputNameMob]: isMobile,
                [styles.disabledInput]:
                  reSubmitKyc === "pending" || reSubmitKyc === "Verified",
              })}
              placeholder="Enter your postal code"
              onChange={handleChangeInputPostalCode}
              value={postalCode}
              disabled={reSubmitKyc === "pending" || reSubmitKyc === "Verified"}
            />
            {errors && (
              <div className={styles.errorMessage}>{errors.postalCode}</div>
            )}
          </div>
          <div
            className={cx(styles.email, {
              [styles.emailWeb]: !isMobile,
              [styles.emailMob]: isMobile,
            })}
          >
            <p
              className={cx(styles.profileNameContainer, {
                [styles.profileNameContainerWeb]: !isMobile,
                [styles.profileNameContainerMob]: isMobile,
              })}
            >
              Country Code *
            </p>
            <input
              key="countryCode"
              type="text"
              className={cx(styles.inputName, {
                [styles.inputNameWeb]: !isMobile,
                [styles.inputNameMob]: isMobile,
                [styles.disabledInput]:
                  reSubmitKyc === "pending" || reSubmitKyc === "Verified",
              })}
              placeholder="Enter your country code"
              onChange={handleChangeInputCountryCode}
              value="US"
              disabled
            />
          </div>
        </div>
      </div>
      {/* {reSubmitKyc === "Not Initialized" && ( */}
      <div
        className={cx(styles.updateProfileButtonDiv, {
          [styles.updateProfileButtonDivWeb]: !isMobile,
          [styles.updateProfileButtonDivMob]: isMobile,
        })}
      >
        <ButtonRade
          outline
          customStyling={cx(styles.backButtonButton, {
            [styles.backButtonButtonWeb]: !isMobile,
            [styles.backButtonButtonMob]: isMobile,
          })}
          onClick={handleSubmitBackButton}
        >
          Back
        </ButtonRade>
        <ButtonRade
          outline
          customStyling={cx(styles.updateProfileButton, {
            [styles.updateProfileButtonWeb]: !isMobile,
            [styles.updateProfileButtonMob]: isMobile,
          })}
          onClick={handleNextStep} // Call handleNextStep on click
        >
          Proceed
        </ButtonRade>
      </div>
      {/* )} */}
    </div>
  );
};

export default AddressDetailsFetch;
