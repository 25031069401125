import React, { useState, useEffect, useCallback, useContext } from "react";
import { auth } from "../../firebase";
import styles from "./index.module.scss";
import IndiaFlagIcon from "../../assets/indiaFlagIcon.svg";
import ReceiverIcon from "../../assets/recieverIcon.svg";
import USDFlagIcon from "../../assets/USFlag.svg";
import RadeButton from "../../components/RadeButtons";
import { useLocation } from "react-router-dom";
import { SEND_ENUM } from "../../enums/sendEnum";
import { AppContext } from "../../context";
import cx from "classnames";
import toolTipIcon from "../../assets/tooltip.svg";
import { useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import refreshIcon from "../../assets/refreshButton.svg";

const MAX_DECIMAL_PLACE = 2; //state to store maximum decimal place
const limit = 10; // Items per page
const TransferHistory = () => {
  const location = useLocation(); //variable for pagination
  let navigate = useNavigate();
  const { isMobile, setSupportFlowPageNumber } = useContext(AppContext);
  const [transactions, setTransactions] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [cache, setCache] = useState({});
  const [openTransactionDetails, setOpenTransactionDetails] = useState({}); //  state for expanding and closing the accordian
  const [tooltipStates1, setTooltipStates1] = useState({});
  const transactionIdParam = location.state?.transactionIdParam;

  useEffect(() => {
    if (transactionIdParam) {
      setOpenTransactionDetails((prevState) => {
        const updatedState = { ...prevState };
        updatedState[transactionIdParam] = true;
        return updatedState;
      });
    }
  }, [transactionIdParam]);
  useEffect(() => {
    // Scroll to the top of the page when currentPage changes
    window.scrollTo(0, 0);
  }, [currentPage]);
  // Function that format the epoch time
  function formatEpochTime(epochTime) {
    const date = new Date(epochTime);

    // Define the desired date and time formats
    const dateFormat = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    };

    const timeFormat = {
      hour: "2-digit",
      minute: "2-digit",
    };

    // Get the date and time strings in the desired format
    const dateStr = date.toLocaleDateString("en-US", dateFormat);
    const timeStr = date.toLocaleTimeString("en-US", timeFormat);

    // Combine the date and time strings
    const formattedTime = `${dateStr} ${timeStr}`;

    return formattedTime;
  }
  // Function to toggle the tooltip for a specific transaction
  const toggleTooltip = (transactionId, show, tooltipStateSetter) => {
    tooltipStateSetter((prevState) => ({
      ...prevState,
      [transactionId]: show,
    }));
  };
  // Function for opening and closing the accordian
  const handleClickTransactionDetails = (postId) => {
    setOpenTransactionDetails((prevState) => {
      const updatedState = {};

      // Close all other divs
      for (const key in prevState) {
        updatedState[key] = false;
      }

      // Toggle the clicked div
      updatedState[postId] = !prevState[postId];

      return updatedState;
    });
  };

  const handleClickSupport = (orderId) => {
    navigate("/support", { state: { orderIds: [orderId] } });
    setSupportFlowPageNumber(2);
  };

  const fetchTransactions = useCallback(
    async (page = 1) => {
      setIsLoading(true);
      setError(null);

      // Check if the page is already in cache
      if (cache[page]) {
        setTransactions(cache[page].transactions);
        setLastEvaluatedKey(cache[page].lastEvaluatedKey);
        setIsLoading(false);
        return;
      }

      try {
        const key = page === 1 ? null : lastEvaluatedKey;

        // Obtain ID token
        const idToken = await auth.currentUser.getIdToken(true);
        var requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        };

        // Include lastEvaluatedKey in the URL if it exists
        const url = new URL(
          process.env.REACT_APP_PAYEZY_SERVER_URI + `/get-user-transfer-history`
        );
        url.searchParams.append("limit", limit);
        if (key) {
          url.searchParams.append(
            "lastEvaluatedKey",
            encodeURIComponent(JSON.stringify(key))
          );
        }

        const response = await fetch(url, requestOptions);

        if (response.ok) {
          const responseData = await response.json();
          console.log("responseData", responseData);

          // Update cache
          setCache((prevCache) => ({
            ...prevCache,
            [page]: {
              transactions: responseData.transactions,
              lastEvaluatedKey: responseData.lastEvaluatedKey,
            },
          }));

          setTransactions(responseData.transactions);
          // Parse the lastEvaluatedKey if it's a string
          setLastEvaluatedKey(
            responseData.lastEvaluatedKey
              ? typeof responseData.lastEvaluatedKey === "string"
                ? JSON.parse(responseData.lastEvaluatedKey)
                : responseData.lastEvaluatedKey
              : null
          );
        } else {
          const errorData = await response.json();
          console.log("errorData, purchase details:", errorData);
          setError("Failed to fetch transactions");
        }
      } catch (err) {
        console.error("Error fetching transactions:", err);
        setError("Failed to fetch transactions");
      }
      setIsLoading(false);
    },
    [lastEvaluatedKey, cache]
  );

  useEffect(() => {
    fetchTransactions(currentPage);
  }, [currentPage, fetchTransactions]);

  const handleNextPage = () => {
    if (lastEvaluatedKey) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  // Function to clear cache (useful when you want to refresh data)
  const clearCache = () => {
    if (process.env.REACT_APP_DEPLOYMENT_MODE === "PRODUCTION") {
      mixpanel.track("Transfer History Refresh!");
    }
    setCache({});
    setCurrentPage(1);
    setLastEvaluatedKey(null);
    fetchTransactions(1);
  };

  if (isLoading) {
    return (
      <>
        <div className={styles.fetchHistoryDetails}>
          {" "}
          <div
            className={cx(styles.noTransactionToDisplay, {
              [styles.noTransactionToDisplayMob]: isMobile,
            })}
          >
            {SEND_ENUM.yourRecentTransaction}
          </div>
          <div className={styles.spinnerOverlay}>
            <div className={styles.spinnerContainer} />
          </div>
        </div>
      </>
    );
  } else if (error) {
    return (
      <>
        <div className={styles.fetchHistoryDetails}>
          {" "}
          <div
            className={cx(styles.noTransactionToDisplay, {
              [styles.noTransactionToDisplayMob]: isMobile,
            })}
          >
            {SEND_ENUM.yourRecentTransaction}
          </div>
          <div className={styles.errorContainer}>{error}</div>
        </div>
      </>
    );
  } else if (transactions.length === 0) {
    return (
      <>
        <div className={styles.fetchHistoryDetails}>
          {" "}
          <div
            className={cx(styles.noTransactionToDisplay, {
              [styles.noTransactionToDisplayMob]: isMobile,
            })}
          >
            {SEND_ENUM.yourRecentTransaction}
          </div>
          <div className={styles.youHaveNoTransaction}>
            {" "}
            You have no transfer requests.
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className={styles.fetchHistoryDetails}>
          <div
            className={cx(styles.yourRecentTransaction, {
              [styles.yourRecentTransactionMob]: isMobile,
            })}
          >
            {SEND_ENUM.yourRecentTransaction}
            <RadeButton className={styles.refreshButton} onClick={clearCache}>
              Refresh
              <img
                src={refreshIcon}
                alt="refreshIcon"
                className={styles.refreshIcon}
              />
            </RadeButton>
          </div>

          <div className={styles.transferHistory}>
            {transactions.map((post) => (
              <div key={post.tx_id} className={styles.transactionMain}>
                <div
                  className={cx(styles.transactionDetailsContainer, {
                    [styles.transactionDetailsContainerMob]: isMobile,
                    [styles.isTransactionDetailsOpen]:
                      openTransactionDetails[post.tx_id],
                  })}
                  onClick={() => handleClickTransactionDetails(post.tx_id)}
                >
                  <div
                    className={cx(styles.amountContainer, {
                      [styles.amountContainerMob]: isMobile,
                      [styles.amountContainerIfTxDetailsOpen]:
                        !isMobile && !openTransactionDetails[post.tx_id],
                    })}
                  >
                    <img
                      src={IndiaFlagIcon}
                      alt="IndiaFlagIcon"
                      className={styles.indiaFlagIcon}
                    />
                    ₹
                    {Number(post.tx_data.inr_amount).toFixed(MAX_DECIMAL_PLACE)}
                  </div>
                  <div
                    className={cx(styles.receiverContainer, {
                      [styles.receiverContainerMob]: isMobile,
                      [styles.isTransferDetailsOpen]:
                        openTransactionDetails[post.tx_id],
                      [styles.receiverContainerIfTxDetailsOpen]:
                        !isMobile && !openTransactionDetails[post.tx_id],
                    })}
                  >
                    <img
                      src={USDFlagIcon}
                      alt="USD icon"
                      className={styles.USDFlagIcon}
                    />
                    $
                    {post.tx_data.usd_amount_net === 0
                      ? Number(post.tx_data.usd_amount_net).toFixed(
                          MAX_DECIMAL_PLACE
                        )
                      : Number(
                          post.tx_data.usd_amount - post.tx_data.tx_cost
                        ).toFixed(MAX_DECIMAL_PLACE)}
                    <div className={styles.container}>
                      <img
                        src={toolTipIcon}
                        className={styles.toolTipIcon}
                        alt="Tool tip"
                        onMouseEnter={() =>
                          toggleTooltip(post.tx_id, true, setTooltipStates1)
                        }
                        onMouseLeave={() =>
                          toggleTooltip(post.tx_id, false, setTooltipStates1)
                        }
                      />
                      {tooltipStates1[post.tx_id] && (
                        <div
                          className={`${styles.customTooltip} ${styles.active}`}
                        >
                          <div className={styles.radeFeeContainer}>
                            {" "}
                            <div className={styles.bankTransferCharge}>
                              Amount Paid (Due)
                            </div>
                            <div className={styles.bankTransferCharge}>
                              <>
                                $
                                {Number(post.tx_data.usd_amount).toFixed(
                                  MAX_DECIMAL_PLACE
                                )}
                              </>
                            </div>
                          </div>
                          <div className={styles.radeFeeContainer}>
                            {" "}
                            <div className={styles.bankTransferCharge}>
                              Payezy Platform Fees
                            </div>
                            <div className={styles.bankTransferCharge}>
                              <>$0.00</>
                            </div>
                          </div>

                          <div className={styles.radeFeeContainer}>
                            <div className={styles.bankTransferCharge}>
                              Amount Exchanged
                            </div>
                            <div className={styles.bankTransferCharge}>
                              <>
                                $
                                {Number(post.tx_data.usd_amount_net).toFixed(
                                  MAX_DECIMAL_PLACE
                                )}
                              </>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {!openTransactionDetails[post.tx_id] && !isMobile && (
                    <>
                      <div
                        className={cx(styles.recipientContainer, {
                          [styles.recipientContainerIfTxDetailsOpen]:
                            !openTransactionDetails[post.tx_id],
                        })}
                      >
                        <img
                          src={ReceiverIcon}
                          alt="ReceiverIcon"
                          className={styles.receiverIcon}
                        />
                        {post.receiver_data.full_name}
                      </div>
                    </>
                  )}
                  {!isMobile && (
                    <div className={styles.dateContainer}>
                      {formatEpochTime(Number(post.tx_data.created_at))}
                    </div>
                  )}{" "}
                </div>
                {openTransactionDetails[post.tx_id] && (
                  <div className={styles.transactionDetailsDisplay}>
                    <div
                      className={cx(styles.statusButtonContainer, {
                        [styles.statusButtonContainerMob]: isMobile,
                      })}
                    >
                      <div>
                        <div className={styles.heading}>
                          {SEND_ENUM.transferId}
                        </div>
                        <div className={styles.value}>{post.tx_id}</div>
                      </div>
                      {post.tx_status && (
                        <div className={styles.statusButton}>
                          {/* Display only for "initiated" or "payment_init" statuses */}
                          {(post.tx_status === "initiated" ||
                            post.tx_status === "payment_init") && (
                            <RadeButton
                              customStyling={cx(styles.statusButtonProcessing, {
                                [styles.statusButtonProcessingMob]: isMobile,
                              })}
                            >
                              {SEND_ENUM.processingButton}
                            </RadeButton>
                          )}
                          {/* Display only for "payment_received" or "usd_exchange_complete" statuses */}
                          {(post.tx_status === "payment_received" ||
                            post.tx_status === "usd_exchange_complete") && (
                            <RadeButton
                              customStyling={cx(styles.statusButtonProcessing, {
                                [styles.statusButtonProcessingMob]: isMobile,
                              })}
                            >
                              {SEND_ENUM.paymentRecievedButton}
                            </RadeButton>
                          )}
                          {/* Display only for "delivery_complete" status */}
                          {post.tx_status === "delivery_complete" && (
                            <RadeButton
                              customStyling={cx(styles.statusButtonCompleted, {
                                [styles.statusButtonCompletedMob]: isMobile,
                              })}
                            >
                              {SEND_ENUM.completedButton}
                            </RadeButton>
                          )}
                          {/* Display only for "payment_rejected" status */}
                          {post.tx_status === "payment_rejected" && (
                            <RadeButton
                              customStyling={cx(styles.statusButtonCancelled, {
                                [styles.statusButtonCancelledMob]: isMobile,
                              })}
                            >
                              {SEND_ENUM.rejectedButton}
                            </RadeButton>
                          )}
                          {/* Display only for "payment_failed" status */}
                          {post.tx_status === "payment_failed" && (
                            <RadeButton
                              customStyling={cx(styles.statusButtonCancelled, {
                                [styles.statusButtonCancelledMob]: isMobile,
                              })}
                            >
                              {SEND_ENUM.failedButton}
                            </RadeButton>
                          )}
                          {/* Display only for "refund_processed" or "refund_initiated" statuses */}
                          {(post.tx_status === "refund_processed" ||
                            post.tx_status === "refund_initiated") && (
                            <RadeButton
                              customStyling={cx(styles.statusButtonProcessing, {
                                [styles.statusButtonProcessingMob]: isMobile,
                              })}
                            >
                              {SEND_ENUM.processingRefundButton}
                            </RadeButton>
                          )}
                          {/* Display only for "refund_complete" status */}
                          {post.tx_status === "refund_complete" && (
                            <RadeButton
                              customStyling={cx(styles.statusButtonCompleted, {
                                [styles.statusButtonCompletedMob]: isMobile,
                              })}
                            >
                              {SEND_ENUM.refundCompleteButton}
                            </RadeButton>
                          )}
                          {/* Display only for "delivery_rejected" status */}
                          {post.tx_status === "delivery_rejected" && (
                            <RadeButton
                              customStyling={cx(styles.statusButtonCancelled, {
                                [styles.statusButtonCancelledMob]: isMobile,
                              })}
                            >
                              {SEND_ENUM.settlementFailedButton}
                            </RadeButton>
                          )}
                          {/* Display only for "retry_delivery" status */}
                          {post.tx_status === "retry_delivery" && (
                            <RadeButton
                              customStyling={cx(styles.statusButtonProcessing, {
                                [styles.statusButtonProcessingMob]: isMobile,
                              })}
                            >
                              {SEND_ENUM.paymentRecievedButton}
                            </RadeButton>
                          )}
                          {/* Display "unauthorized" for "created" or any undefined status */}
                          {(post.tx_status === "created" ||
                            ![
                              "initiated",
                              "payment_init",
                              "payment_received",
                              "usd_exchange_complete",
                              "delivery_complete",
                              "payment_rejected",
                              "delivery_rejected",
                              "refund_processed",
                              "refund_initiated",
                              "refund_complete",
                              "payment_failed",
                              "retry_delivery",
                            ].includes(post.tx_status)) && (
                            <RadeButton
                              customStyling={cx(styles.statusButtonProcessing, {
                                [styles.statusButtonProcessingMob]: isMobile,
                              })}
                            >
                              {SEND_ENUM.processingButton}
                            </RadeButton>
                          )}
                        </div>
                      )}
                    </div>
                    <div
                      className={cx(styles.nickNameContainer, {
                        [styles.nickNameContainerMob]: isMobile,
                      })}
                    >
                      <div>
                        <div className={styles.heading}>
                          {SEND_ENUM.nickName}
                        </div>
                        <div className={styles.value}>
                          {post.receiver_data.nick_name
                            ? post.receiver_data.nick_name
                            : "-"}
                        </div>
                      </div>
                      <div className={styles.fullNameContainer}>
                        <div className={styles.heading}>
                          {SEND_ENUM.fullName}
                        </div>
                        <div className={styles.value}>
                          {post.receiver_data.full_name}
                        </div>
                      </div>
                    </div>
                    <div className={styles.transferIDContainer}>
                      <div
                        className={cx(styles.accountNumberContainer, {
                          [styles.accountNumberContainerMob]: isMobile,
                        })}
                      >
                        <div
                          className={cx(styles.heading, {
                            [styles.headingMob]: isMobile,
                          })}
                        >
                          {SEND_ENUM.accountNum}
                        </div>
                        <div className={styles.value}>
                          {post.receiver_data.account_number}
                        </div>
                      </div>

                      <div className={styles.transactionCostContainer}>
                        <div className={styles.heading}>Transaction Fee</div>
                        <div className={styles.transactionCostValue}>
                          ${" "}
                          {Number(post.tx_data.tx_cost).toFixed(
                            MAX_DECIMAL_PLACE
                          )}
                        </div>
                      </div>
                      {!isMobile && (
                        <div>
                          <div className={styles.headingIFSC}>
                            {SEND_ENUM.IFSCCode}
                          </div>
                          <div className={styles.value}>
                            {post.receiver_data.ifsc_code}
                          </div>
                        </div>
                      )}
                    </div>
                    {post.tx_data.usd_amount && isMobile && (
                      <>
                        <div className={styles.exchangeRateContainer}>
                          <div>
                            <div className={styles.heading}>Total Cost</div>
                            <div className={styles.value}>
                              ${" "}
                              {Number(post.tx_data.usd_amount).toFixed(
                                MAX_DECIMAL_PLACE
                              )}
                            </div>
                          </div>
                          <div className={styles.transactionCostContainer}>
                            <div>
                              <div className={styles.heading}>
                                {SEND_ENUM.paymentRef}
                              </div>
                              <div className={styles.paymentReferenceValue}>
                                {post.payment_reference ? (
                                  post.payment_reference
                                ) : (
                                  <>-</>
                                )}
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </>
                    )}
                    {isMobile && (
                      <div className={styles.exchangeRateContainerMob}>
                        {" "}
                        <div>
                          <div className={styles.heading}>Exchange Rate</div>
                          <div className={styles.valueExchange}>
                            ₹
                            {Number(post.tx_data.exchange_rate).toFixed(
                              MAX_DECIMAL_PLACE
                            )}{" "}
                          </div>
                        </div>
                        <div className={styles.cancelAndRefundButtonContainer}>
                          <RadeButton
                            customStyling={cx(styles.cancelAndRefund, {
                              [styles.cancelAndRefundMob]: isMobile,
                            })}
                            onClick={() => handleClickSupport(post.tx_id)}
                          >
                            {SEND_ENUM.cancelAndRefund}
                          </RadeButton>
                        </div>
                      </div>
                    )}
                    {post.tx_data.usd_amount && !isMobile && (
                      <div className={styles.exchangeRateContainer}>
                        <div className={styles.paymentRefContainer}>
                          <div className={styles.heading}>
                            {SEND_ENUM.paymentRef}
                          </div>
                          <div className={styles.value}>
                            {post.payment_reference ? (
                              post.payment_reference
                            ) : (
                              <>-</>
                            )}
                          </div>{" "}
                        </div>
                        <div className={styles.totalCostContainer}>
                          <div className={styles.heading}>Total Cost</div>
                          <div className={styles.value}>
                            ${" "}
                            {Number(post.tx_data.usd_amount).toFixed(
                              MAX_DECIMAL_PLACE
                            )}
                          </div>
                        </div>

                        <div>
                          <div className={styles.heading}>Exchange Rate</div>
                          <div className={styles.valueExchange}>
                            ₹
                            {Number(post.tx_data.exchange_rate).toFixed(
                              MAX_DECIMAL_PLACE
                            )}{" "}
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className={cx(styles.nameContainer, {
                        [styles.nameContainerMob]: isMobile,
                      })}
                    >
                      {!isMobile && (
                        <div className={styles.promoRateContainer}>
                          <div className={styles.heading}>
                            {SEND_ENUM.promoRate}
                          </div>
                          {post.tx_data.promo_rate ? (
                            <div className={styles.value}>
                              {post.tx_data.promo_rate ===
                              post.tx_data.exchange_rate
                                ? "-"
                                : post.tx_data.promo_rate}
                            </div>
                          ) : (
                            <div className={styles.value}>{"-"}</div>
                          )}
                        </div>
                      )}
                      <div
                        className={cx(styles.phoneNumberContainer, {
                          [styles.phoneNumberContainerMob]: isMobile,
                        })}
                      >
                        <div className={styles.heading}>{SEND_ENUM.phone}</div>
                        <div className={styles.value}>
                          {post.receiver_data.phone_number}
                        </div>
                      </div>

                      {isMobile && (
                        <div>
                          <div className={styles.heading}>
                            {SEND_ENUM.IFSCCode}
                          </div>
                          <div className={styles.value}>
                            {post.receiver_data.ifsc_code}
                          </div>
                        </div>
                      )}
                      {/* )} */}
                      {!isMobile && (
                        <div>
                          {" "}
                          <div>
                            <RadeButton
                              customStyling={cx(styles.cancelAndRefund, {
                                [styles.cancelAndRefundMob]: isMobile,
                              })}
                              onClick={() => handleClickSupport(post.tx_id)}
                            >
                              {SEND_ENUM.cancelAndRefund}
                            </RadeButton>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={styles.fullNameContainerMob}>
                      <div>
                        {isMobile && (
                          <div>
                            <div className={styles.heading}>
                              {SEND_ENUM.promoRate}
                            </div>
                            {post.tx_data.promo_rate ? (
                              <div className={styles.value}>
                                {post.tx_data.promo_rate ===
                                post.tx_data.exchange_rate
                                  ? "-"
                                  : post.tx_data.promo_rate}
                              </div>
                            ) : (
                              <div className={styles.value}>{"-"}</div>
                            )}
                          </div>
                        )}
                      </div>
                      {isMobile && (
                        <div className={styles.dateContainerMob}>
                          <div className={styles.headingTime}>
                            {SEND_ENUM.time}
                          </div>
                          <div className={styles.value}>
                            {" "}
                            {formatEpochTime(Number(post.tx_data.created_at))}
                          </div>
                        </div>
                      )}{" "}
                    </div>
                    {(post.tx_status === "initiated" ||
                      post.tx_status === "payment_init" ||
                      post.tx_status === "payment_received" ||
                      post.tx_status === "usd_exchange_complete") && (
                      <div className={styles.pleaseBePatientYourTransfer}>
                        {SEND_ENUM.pleaseBePatientYourTransfer}
                      </div>
                    )}
                    {post.tx_status === "delivery_complete" && (
                      <div className={styles.yourOrderCompleted}>
                        {SEND_ENUM.yourOrderCompleted}{" "}
                        {post.tx_data.last_modified_at
                          ? formatEpochTime(
                              Number(post.tx_data.last_modified_at)
                            )
                          : "-"}
                      </div>
                    )}
                    {post.tx_status === "payment_rejected" && (
                      <div className={styles.youHaveCancelledThisOrder}>
                        {SEND_ENUM.paymentRejectedMessage}
                      </div>
                    )}
                    {post.tx_status === "payment_failed" && (
                      <div className={styles.youHaveCancelledThisOrder}>
                        {SEND_ENUM.paymentFailedMessage}
                      </div>
                    )}
                    {(post.tx_status === "refund_processed" ||
                      post.tx_status === "refund_initiated") && (
                      <div className={styles.pleaseBePatientYourTransfer}>
                        {SEND_ENUM.refundProcessedMessage}
                      </div>
                    )}
                    {post.tx_status === "refund_complete" && (
                      <div className={styles.yourOrderCompleted}>
                        {SEND_ENUM.refundCompletedMessage}
                      </div>
                    )}
                    {post.tx_status === "delivery_rejected" && (
                      <div className={styles.youHaveCancelledThisOrder}>
                        {SEND_ENUM.deliveryRejectedMessage}
                      </div>
                    )}
                    {post.tx_status === "retry_delivery" && (
                      <div className={styles.pleaseBePatientYourTransfer}>
                        {SEND_ENUM.retryDeliveryMessage}
                      </div>
                    )}
                    {(post.tx_status === "created" ||
                      ![
                        "initiated",
                        "payment_init",
                        "payment_received",
                        "usd_exchange_complete",
                        "delivery_complete",
                        "payment_rejected",
                        "delivery_rejected",
                        "refund_processed",
                        "refund_initiated",
                        "refund_complete",
                        "payment_failed",
                        "retry_delivery",
                      ].includes(post.tx_status)) && (
                      <div className={styles.youHaveCancelledThisOrder}>
                        {SEND_ENUM.settlementErrorMessage}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
            <div
              className={cx(styles.paginationContainer, {
                [styles.paginationContainerMob]: isMobile,
              })}
            >
              <RadeButton
                customStyling={cx(styles.previousButton, {
                  [styles.previousButtonMob]: isMobile,
                  [styles.disabledButton]: currentPage === 1,
                  [styles.disabledAndMob]: isMobile && currentPage === 1,
                })}
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </RadeButton>
              <div
                className={cx(styles.pageNumber, {
                  [styles.pageNumberMob]: isMobile,
                })}
              >
                Page {currentPage}
              </div>
              <RadeButton
                customStyling={cx(styles.nextButton, {
                  [styles.nextButtonMob]: isMobile,
                  [styles.disabledButton]: !lastEvaluatedKey,
                  [styles.disabledAndMob]: isMobile && !lastEvaluatedKey,
                })}
                onClick={handleNextPage}
                disabled={!lastEvaluatedKey}
              >
                Next
              </RadeButton>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default TransferHistory;
