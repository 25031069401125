import React, { useContext } from "react";
import styles from "./index.module.scss";
import { POPUP_ENUM } from "../../enums/popUpEnum";
import ButtonRade from "../../components/RadeButtons";
import cx from "classnames";
import { AppContext } from "../../context";
import exclamationIcon from "../../assets/exclamationTraingle.svg";
import { useNavigate } from "react-router-dom";

// Component for displaying a modal to complete KYC
const CompleteKycModal = ({ setModalIsOpen }) => {
  const { isMobile, setKYCPageNumber } = useContext(AppContext);
  const navigate = useNavigate();

  // Function to handle complete KYC button click
  const handleClickCompleteKycButton = () => {
    setModalIsOpen(false);
    setKYCPageNumber(2);
    // Navigate to profile route
    navigate("/profile");
  };

  // Inner component for the card body
  const CardBody = () => (
    <div>
      <div
        className={cx(styles.acceptTerms, {
          [styles.acceptTermsMob]: isMobile,
        })}
      >
        {POPUP_ENUM.completeKycContent}
        <img
          src={exclamationIcon}
          alt="exclamationIcon"
          className={styles.exclamationIcon}
        />
      </div>

      <div
        className={cx(styles.buttonContainer, {
          [styles.buttonContainerMob]: isMobile,
        })}
      >
        <ButtonRade
          className={cx(styles.closeButton, {
            [styles.closeButtonMob]: isMobile,
          })}
          onClick={() => {
            setModalIsOpen(false);
          }}
        >
          Close
        </ButtonRade>
        <ButtonRade
          className={cx(styles.continueButton, {
            [styles.continueButtonMob]: isMobile,
          })}
          onClick={() => {
            setModalIsOpen(false);
            handleClickCompleteKycButton();
          }}
        >
          {POPUP_ENUM.completeKycButton}
        </ButtonRade>
      </div>
    </div>
  );

  return <CardBody />;
};

export default CompleteKycModal;
