export const TransferFrequency = {
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
};

export const TransferScheduleType = {
  FIXED: "FIXED",
  CUSTOM: "CUSTOM",
};

export const TransferExecutionStatus = {
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  CANCELLED: "CANCELLED",
};

export const RecurringTransferStatus = {
  ACTIVE: "ACTIVE",
  PAUSED: "PAUSED",
  COMPLETED: "COMPLETED",
  CANCELLED: "CANCELLED",
  FAILED: "FAILED",
};

export const RECURRING_TRANSFER_ENUM = {
  createRecurringTransfer: "Create Recurring Transfer",
  setUpScheduleTitle: "Set Up Transfer Schedule",
  fixedSchedule: "Fixed Schedule",
  customSchedule: "Custom Schedule",
  confirmRecurringTransfer: "Confirm Recurring Transfer",
  recurringTransfers: "Recurring Transfers",
  creatingTransfer:
    "Please wait while we process and confirm your recurring transfer request.",
  recurringTransferCreated: "Your recurring transfer created successfully!",
  confirmProcessingTransfer:
    "Are you sure that you want to process the scheduled transaction now?",
  confirmCancelTransfer:
    "Are you sure that you want to cancel the scheduled transaction?",
  confirmCancelAllTransfer:
    "Are you sure that you want to cancel all the scheduled transactions?",
  processingTransaction: "Your transaction is being processed.",
  cancellingTransaction: "Your transaction is being cancelled.",
  cancellingAllTransactions: "Your transactions are being cancelled.",
};
