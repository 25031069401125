import React, { useContext, useState } from "react";
import cx from "classnames";
import Modal from "react-modal";
import styles from "./index.module.scss";
import { AppContext } from "../../context";
import { SEND_ENUM } from "../../enums/sendEnum";
import { SUPPORT_ENUM } from "../../enums/supportEnum";
import ButtonRade from "../../components/RadeButtons";
import NextIcon from "../../assets/NextIcon";
import Card from "../../components/Card/Card";
import { db } from "../../firebase"; // Assuming Firebase is configured in a file named firebase.js
import { doc, setDoc, collection, addDoc } from "firebase/firestore";
import ServiceTicketSubmit from "../ServiceTicketSubmitModal/ServiceTicketSubmitModal";
import mixpanel from "mixpanel-browser";

const KYCSUpport = ({ setUserModeSelection, setOrderId }) => {
  const { isMobile, profileEmail, setSupportFlowPageNumber } =
    useContext(AppContext);

  const [email, setEmail] = useState(profileEmail || "");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false); // Assuming a modal state

  // Handle input changes and reset error message
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrorMessage("");
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    setErrorMessage("");
  };

  const handleSubmitProceedButton = async () => {
    if (!message.trim()) {
      setErrorMessage("Please enter your message!");
    } else {
      setErrorMessage("");
      setModalIsOpen(true);
      try {
        // Prepare the support ticket data
        const supportTicketData = {
          message: message,
          ticketType: "KYC", // Replace with appropriate type if required
          ticketStatus: "Submitted",
          ticketReply: "",
          createdTimestamp: new Date().getTime(),
        };

        // Create a reference to the user's profile using the email
        const profileRef = doc(db, "ticketCollection", profileEmail);

        // Create the user's profile document if it doesn't exist
        await setDoc(profileRef, {}); // Create an empty document

        // Create a reference to the user's ticket list under their profile
        const ticketListRef = collection(profileRef, "ticketList");

        // Add the support ticket data to the ticket list
        await addDoc(ticketListRef, supportTicketData);

        console.log("Support ticket submitted successfully!");

        if (process.env.REACT_APP_DEPLOYMENT_MODE === "PRODUCTION") {
          mixpanel.track("Raising KYC Service Ticket!", {
            ticketType: "KYC",
            messageLength: message.length,
            ticketStatus: "Submitted",
            createdTimestamp: supportTicketData.createdTimestamp, // Track the creation timestamp
          });
        }
      } catch (error) {
        console.error("Error adding support ticket document: ", error);
        setErrorMessage(
          "Failed to submit the support ticket. Please try again."
        );
        setModalIsOpen(false);
      }
    }
  };

  const handleSubmitBackButton = () => {
    setSupportFlowPageNumber(1);
  };

  const CardTitle = () => (
    <div
      className={cx(styles.supportTicketTitle, {
        [styles.supportTicketTitleMob]: isMobile,
      })}
    >
      {SUPPORT_ENUM.supportTicket}
    </div>
  );

  const CardBody = () => (
    <div
      className={cx(styles.failedTransactionMainContainer, {
        [styles.failedTransactionMainContainerMob]: isMobile,
      })}
    >
      <div
        className={cx(styles.fillInputField, {
          [styles.fillInputFieldMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.inputkey, {
            [styles.inputkeyMob]: isMobile,
          })}
        >
          Email
        </div>
        <div
          className={cx(styles.inputValue, {
            [styles.inputValueMob]: isMobile,
          })}
        >
          <input
            value={email}
            onChange={handleEmailChange}
            autoComplete="off"
            className={styles.orderId}
          />
        </div>
      </div>
      <div
        className={cx(styles.fillInputField, {
          [styles.fillInputFieldMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.inputkey, {
            [styles.inputkeyMob]: isMobile,
          })}
        >
          {SUPPORT_ENUM.message}
        </div>
        <div
          className={cx(styles.inputValue, {
            [styles.inputValueMob]: isMobile,
          })}
        >
          <textarea
            type="text"
            value={message}
            onChange={handleMessageChange}
            autoComplete="off"
            className={styles.message}
            rows={3} // Display up to 3 lines
          />
        </div>
      </div>

      {/* Display error message if any */}
      <div
        className={cx(styles.errorMessage, {
          [styles.errorMessageMob]: isMobile,
        })}
      >
        {errorMessage}
      </div>

      <div
        className={cx(styles.proceedButtonDiv, {
          [styles.proceedButtonDivMob]: isMobile,
        })}
      >
        {/* Custom styled cancel button */}
        <ButtonRade
          outline
          customStyling={cx(styles.cancelButton, {
            [styles.cancelButtonMob]: isMobile,
          })}
          onClick={handleSubmitBackButton}
        >
          {SEND_ENUM.back}
        </ButtonRade>
        {/* Custom styled button for proceed */}
        <ButtonRade
          outline
          customStyling={cx(styles.proceedButton, {
            [styles.proceedButtonMob]: isMobile,
          })}
          onClick={handleSubmitProceedButton}
        >
          {SUPPORT_ENUM.create}
          <NextIcon />
        </ButtonRade>
      </div>
    </div>
  );

  return (
    <>
      <Card titleComponent={<CardTitle />}>{CardBody()}</Card>
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <ServiceTicketSubmit
          setModalIsOpen={setModalIsOpen}
          setUserModeSelection={setUserModeSelection}
          setOrderId={setOrderId}
          setMessage={setMessage}
        />
      </Modal>
    </>
  );
};

export default KYCSUpport;
