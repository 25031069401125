import React, { useContext } from "react";
import styles from "./index.module.scss";
import maintenanceIcon from "../../assets/maintenanceIcon.svg";
import cx from "classnames";
import { AppContext } from "../../context";
import { SEND_ENUM } from "../../enums/sendEnum";

const MaintenanceCardBody = () => {
  const { isMobile } = useContext(AppContext);
  return (
    <div
      className={cx(styles.maintenanceContainer, {
        [styles.maintenanceContainerMob]: isMobile,
      })}
    >
      <div
        className={cx(styles.iconContainer, {
          [styles.iconContainerMob]: isMobile,
        })}
      >
        <img src={maintenanceIcon} alt="maintenanceIcon" />
      </div>
      <div
        className={cx(styles.maintenanceContent, {
          [styles.maintenanceContentMob]: isMobile,
        })}
      >
        {SEND_ENUM.maintenanceContent}
      </div>
    </div>
  );
};

export default MaintenanceCardBody;
