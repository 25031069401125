import React, { useContext, useState, useEffect } from "react";
import cx from "classnames";
import { getDoc, doc, collection, updateDoc } from "firebase/firestore";
import { auth, db } from "../../firebase";
import { PROFILE_ENUM } from "../../enums/profileEnum";
import styles from "./index.module.scss";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import KYC from "../Kyc/Kyc";
import Card from "../../components/Card/Card";
import ButtonRade from "../../components/RadeButtons";
import refreshButton from "../../assets/refreshButton.svg";
import { getCybridCustomerState } from "../../api/utilities/getCybridCustomerState";
import { verifyCybridCustomer } from "../../api/utilities/verifyCybridCustomer";
import { AppContext } from "../../context";
import Spinner from "../../components/Spinner/Spinner";
import Modal from "react-modal";
import ConfirmReSubmitKYC from "../ConfirmReSubmitKYC/ConfirmReSubmitKYC";
import { getCybridCustomer } from "../../api/utilities/getCybridCustomer";
import mixpanel from "mixpanel-browser";

const KycLandingPage = ({ profileEmail }) => {
  const {
    isMobile,
    KYCPageNumber,
    setKYCPageNumber,
    customerState,
    setCustomerState,
    userData,
    setUserData,
    showResumeButton,
    setShowResumeButton,
  } = useContext(AppContext);
  const [personaInquiryId, setPersonaInquiryId] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [underReview, setUnderReview] = useState(null);
  const [loading, setLoading] = useState(false); // New loading state
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loadingResubmitKYC, setLoadingResubmitKYC] = useState(null);
  const renderTooltip1 = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle}>
      Refresh
    </Tooltip>
  );
  const handleClickResumeKYC = async () => {
    try {
      setLoadingResubmitKYC(true); // Start loader
      setModalIsOpen(true);
      if (userData.cybrid_customer_details.customer_guid) {
        const idToken = await auth.currentUser.getIdToken(true);
        const verifyCustomerResponse = await verifyCybridCustomer(
          userData.cybrid_customer_details.customer_guid,
          idToken
        );
        if (process.env.REACT_APP_DEPLOYMENT_MODE === "PRODUCTION") {
          mixpanel.track("KYC Resume!");
        }
        if (verifyCustomerResponse.personaInquiryId) {
          setPersonaInquiryId(verifyCustomerResponse.personaInquiryId);
        }
      }
    } catch (error) {
      console.error("Error resuming KYC process:", error);
    } finally {
      setLoadingResubmitKYC(false); // Stop loader after process
    }
  };
  const cachedVerificationStatus = localStorage.getItem("verificationStatus");
  useEffect(() => {
    const fetchVerificationCustomer = async () => {
      setLoading(true);

      if (
        userData?.user_pii?.name?.first !== "" &&
        cachedVerificationStatus === "verified"
      ) {
        setLoading(false);
        setKYCPageNumber(1);
        return;
      }
      if (!auth.currentUser) {
        // Check if currentUser is available
        console.warn("No user is currently logged in.");
        return; // Exit early if no user is authenticated
      }

      const idToken = await auth.currentUser.getIdToken(true);

      if (idToken) {
        try {
          const usersRef = collection(db, "userData");
          const userDocRef = doc(usersRef, profileEmail);
          const documentSnapshot = await getDoc(userDocRef);

          if (documentSnapshot.exists()) {
            const userData = documentSnapshot.data();

            if (userData.cybrid_customer_details.customer_guid) {
              const verificationStatus = await getCybridCustomerState(
                idToken,
                userData.cybrid_customer_details.customer_guid
              );

              if (verificationStatus === "verified") {
                const customerDetailsResponse = await getCybridCustomer(
                  idToken,
                  userData.cybrid_customer_details.customer_guid
                );
                if (
                  customerDetailsResponse &&
                  customerDetailsResponse.customer
                ) {
                  const user_pii = {
                    name: {
                      first: customerDetailsResponse?.customer?.name?.first
                        ? customerDetailsResponse.customer.name.first
                        : "",
                      middle: customerDetailsResponse?.customer?.name?.middle
                        ? customerDetailsResponse.customer.name.middle
                        : "",
                      last: customerDetailsResponse.customer?.name?.last
                        ? customerDetailsResponse.customer.name.last
                        : "",
                    },
                    address: {
                      street: customerDetailsResponse?.customer?.address?.street
                        ? customerDetailsResponse.customer.address.street
                        : "",
                      street2: customerDetailsResponse?.customer?.address
                        ?.street2
                        ? customerDetailsResponse.customer.address.street2
                        : "",
                      city: customerDetailsResponse?.customer?.address?.city
                        ? customerDetailsResponse.customer.address.city
                        : "",
                      subdivision: customerDetailsResponse?.customer?.address
                        ?.subdivision
                        ? customerDetailsResponse?.customer?.address
                            ?.subdivision
                        : "",
                      postal_code: customerDetailsResponse?.customer?.address
                        ?.postal_code
                        ? customerDetailsResponse?.customer?.address
                            ?.postal_code
                        : "",
                      country_code: customerDetailsResponse?.customer?.address
                        ?.country_code
                        ? customerDetailsResponse?.customer?.address
                            ?.country_code
                        : "",
                    },
                    date_of_birth: customerDetailsResponse?.customer
                      ?.date_of_birth
                      ? customerDetailsResponse?.customer?.date_of_birth
                      : "",
                    phone_number: customerDetailsResponse?.customer
                      ?.phone_number
                      ? customerDetailsResponse.customer.phone_number
                      : "",
                    email_address: customerDetailsResponse?.customer
                      ?.email_address
                      ? customerDetailsResponse.customer.email_address
                      : "",
                    document_submitted: customerDetailsResponse?.customer
                      ?.identification_numbers[0]?.type
                      ? customerDetailsResponse.customer
                          .identification_numbers[0].type
                      : "",
                    document_id: customerDetailsResponse?.customer
                      ?.identification_numbers[0]?.identification_number
                      ? customerDetailsResponse.customer
                          .identification_numbers[0].identification_number
                      : "",
                  };
                  if (userData?.user_pii?.name?.first === "") {
                    await updateDoc(userDocRef, {
                      user_pii,
                    });
                  }
                }

                // Updating the Firestore document with the extracted customer details
              }

              setUserData(userData);
              setCustomerState(verificationStatus);
              // setCustomerState("rejected");

              if (customerState === "verified") {
                setShowResumeButton(false);
              }
              setKYCPageNumber(1);
              setLoading(false);

              // Merge the existing userPii with the updated customerState
              if (verificationStatus) {
                // Update only the userPii field with the modified customerState
                await updateDoc(userDocRef, {
                  customer_state: verificationStatus,
                });
              } else {
                // setCustomerState("unverified");

                // Update customer data for KYC as 'Not Initialized'
                await updateDoc(userDocRef, {
                  customer_state: "Not Initialized",
                });
              }
            } else {
              setCustomerState("Not Initialized");
            }
            if (!customerState || customerState === "Not Initialized") {
              setKYCPageNumber(2);
              setLoading(false);
            }
          }
        } catch (err) {
          setLoading(false);
          console.error("Error fetching customer data:", err);
        }
      }
    };

    fetchVerificationCustomer();
  }, [KYCPageNumber]);
  // useEffect(() => {
  //   fetchKYCStatus();
  // }, [KYCPageNumber, customerState]);
  const fetchKYCStatus = async () => {
    if (
      customerState === "unverified" &&
      userData &&
      userData.cybrid_customer_details.customer_guid
    ) {
      try {
        // Fetch the personaInquiryId on component load
        const idToken = await auth.currentUser.getIdToken(true);
        const verifyCustomerResponse = await verifyCybridCustomer(
          userData.cybrid_customer_details.customer_guid,
          idToken
        );
        if (verifyCustomerResponse.personaInquiryId) {
          setKYCPageNumber(1);

          setPersonaInquiryId(verifyCustomerResponse.personaInquiryId);
          setUnderReview(verifyCustomerResponse.under_review);
        }
      } catch (error) {
        console.error("Error fetching Persona Inquiry ID:", error);
      }
    }
  };
  useEffect(() => {
    fetchKYCStatus();
  }, [
    profileEmail,
    setKYCPageNumber,
    customerState,
    KYCPageNumber,
    setCustomerState,
  ]);

  const handleRefreshKYCStatus = async () => {
    try {
      const idToken = await auth.currentUser.getIdToken(true);

      try {
        const usersRef = collection(db, "userData");
        const userDocRef = doc(usersRef, profileEmail);
        const documentSnapshot = await getDoc(userDocRef);

        if (documentSnapshot.exists()) {
          const userData = documentSnapshot.data();

          if (
            userData.cybrid_customer_details &&
            userData.cybrid_customer_details.customer_guid
          ) {
            const verificationStatus = await getCybridCustomerState(
              idToken,
              userData.cybrid_customer_details.customer_guid
            );
            setCustomerState(verificationStatus);

            await updateDoc(userDocRef, {
              customer_state: verificationStatus,
            });
          } else {
            const customerDataForKYC = {
              customer_state: "unverified",
            };
            setCustomerState("unverified");
            await updateDoc(userDocRef, customerDataForKYC);
          }
        }
      } catch (err) {
        console.error("Error fetching customer data:", err);
      }
    } catch (error) {
      console.error("Error fetching verification customer:", error);
    }
  };
  const CardTitle = () => (
    <div
      className={cx(styles.profileButtonDiv, {
        [styles.profileButtonDivMob]: isMobile,
      })}
      // style={{
      //   position: "sticky",
      //   top: 0,
      //   zIndex: 1000,
      // }}
    >
      <div>{PROFILE_ENUM.profile}</div>

      {((customerState !== "verified" &&
        cachedVerificationStatus !== "verified" &&
        customerState !== "rejected" &&
        userData.cybrid_customer_details &&
        userData.cybrid_customer_details.customer_guid) ||
        showResumeButton) && (
        <div className={styles.reSubmitKycButtonContainer}>
          <OverlayTrigger placement="top" overlay={renderTooltip1}>
            <span>
              {" "}
              <ButtonRade
                className={styles.refreshButton}
                onClick={handleRefreshKYCStatus}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <img
                  src={refreshButton}
                  alt="refresh button"
                  className={styles.refreshButtonIcon}
                />
              </ButtonRade>
            </span>
          </OverlayTrigger>
          {/* {showTooltip && (
              <div className={styles.tooltip}>
                Please refresh to check your current status!
              </div>
            )} */}
          {!underReview && cachedVerificationStatus !== "verified" && (
            <ButtonRade
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              onClick={handleClickResumeKYC}
              customStyling={cx(
                styles.reSubmitKycButton,
                { [styles.reSubmitKycButtonMob]: isMobile }
                // { [styles.reSubmitKycButtonDisabled]: !personaInquiryId }
              )}
              // disabled={!personaInquiryId}
            >
              Resume KYC{" "}
            </ButtonRade>
          )}
        </div>
      )}
      {/* {customerState === "rejected" && (
        <ButtonRade
          onClick={handleClickResumeKYC}
          customStyling={cx(styles.reSubmitKycButton, {
            [styles.reSubmitKycButtonMob]: isMobile,
          })}
        >
          Resubmit KYC
        </ButtonRade>
      )} */}
      {/* )} */}
    </div>
  );

  return (
    <>
      {!loading ? ( // Check loading state here
        <div
          className={cx(styles.buttonTableDiv, {
            [styles.buttonTableDivMob]: isMobile,
          })}
        >
          <Card titleComponent={<CardTitle />}>
            <KYC
              handleRefreshKYCStatus={handleRefreshKYCStatus}
              showResumeButton={showResumeButton}
              setShowResumeButton={setShowResumeButton}
              profileEmail={profileEmail}
              KYCPageNumber={KYCPageNumber}
              setKYCPageNumber={setKYCPageNumber}
              setPersonaInquiryId={setPersonaInquiryId}
              personaInquiryId={personaInquiryId}
              fetchKYCStatus={fetchKYCStatus}
              underReview={underReview}
            />
          </Card>
        </div>
      ) : (
        <div className={styles.spinnerDiv}>
          <Spinner />
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <ConfirmReSubmitKYC
          loadingResubmitKYC={loading}
          handleClickResumeKYC={handleClickResumeKYC}
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          personaInquiryId={personaInquiryId}
        />
      </Modal>
    </>
  );
};

export default KycLandingPage;
