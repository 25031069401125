export const POPUP_ENUM = {
  acceptTerms: "Accept Terms to Continue",
  beforeContinuing:
    "Before proceeding to use our services, please agree to our terms and conditions.",
  cancel: "Cancel",
  continue: "Continue",
  areYouSure:
    "Note: You will not be able to change your details until the verification process ends. Are you sure you want to submit?",
  pleaseAgreeToContinue:
    "Please agree to the terms of use, privacy policy and partner agreement to continue.",
  areYouSureInUpdate: "Are you sure?",
  cancellingYourOrder:
    "Cancelling your order with incur some charges. Once cancelled, it will take 48 working hours to process your request.",
  backButton: "Back",
  submit: "Submit",
  areYouWantToContinue: "Are you sure want to continue?",
  completeKycButton: "Complete KYC",
  completeKycContent: "Please complete KYC to proceed",
  areYouWantToConfirm: "Are you sure want to confirm?",
  updateSuccesfully: "Update Successful",
  serviceTicketSubmitted:
    "Service ticket has been successfully created. Please be patient while we fix your issue.",
  close: "Close",
  serviceTicketSubmittedTitle: "Request Submitted",
  feedbackSentTitle: "Feedback Sent",
  feedbackSentContent:
    "Thank you for giving your valuable feedback. Our team will look into it and shall take necessary steps.",
  signUpSuccessful: "You have signed up successfully.",
  welcomeToPayezy: "Welcome to Payezy!",
  signUpWaitingContent: "Please wait while we create your account...",
  passwordResetSuccessful: "Your password has been successfully reset.",
  loginToContinueToApp: "Now please login to continue to the app.",
  selectNickname: "Select Nickname",
  selectState: "Select State",
};
