import { auth } from "../../firebase";

export const fetchExtBankAccounts = async (customerGuid) => {
  try {
    const idToken = await auth.currentUser.getIdToken(true);
    const response = await fetch(
      `${process.env.REACT_APP_PAYEZY_SERVER_URI}/get-external-bank-accounts/${customerGuid}`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch bank accounts");
    }

    const data = await response.json();
    return data; // Return the data to be handled in the components
  } catch (error) {
    console.error("Error fetching bank accounts:", error);
    throw error; // Propagate the error to the caller
  }
};
