import React, { useContext, useEffect, useState } from "react";
import styles from "./PlaidConnect.module.scss";
import { plaidConnect } from "../../api/utilities/plaidConnect";
import { usePlaidLink } from "react-plaid-link";
import { auth } from "../../firebase";
import { AppContext } from "../../context";
import { createPlaidExtAccount } from "../../api/utilities/createPlaidExtAccount";
import { SEND_ENUM } from "../../enums/sendEnum";
import cx from "classnames";

const PlaidConnect = ({
  setModalIsOpen,
  fetchAccounts,
  setIsLoading,
  setErrorMessage,
}) => {
  const { userData, isMobile } = useContext(AppContext);
  const [linkToken, setLinkToken] = useState(null);

  useEffect(() => {
    const fetchLinkToken = async () => {
      try {
        const idToken = await auth.currentUser.getIdToken(true);
        const linkToken = await plaidConnect(
          userData.cybrid_customer_details.customer_guid,
          idToken,
          "link_token_create"
        );
        setLinkToken(linkToken.plaid_link_token);
      } catch (error) {
        console.error("Error fetching link token:", error);
        setModalIsOpen(false);
        setErrorMessage(error.message);
      }
    };
    fetchLinkToken();
  }, []);
  // The usePlaidLink hook manages Plaid Link creation
  // It does not return a destroy function;
  // instead, on unmount it automatically destroys the Link instance
  const [accountName, setAccountName] = useState("");

  const handleSuccess = async (public_token, metadata) => {
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const plaid_account_id = metadata.accounts[0].id;

      // You might want to prompt the user for an account name
      // For now, we'll use a default name
      const name = accountName || "My Plaid Account";
      setIsLoading(true);
      const response = await createPlaidExtAccount(
        userData.cybrid_customer_details.customer_guid,
        name,
        public_token,
        plaid_account_id,
        idToken
      );
      await fetchAccounts();
      setIsLoading(false);
      // Re-enable scrolling after the process is complete
      document.body.style.overflow = "";
      console.log("External bank account created:", response);
    } catch (error) {
      console.error("Error creating external bank account:", error);
      // Handle error (e.g., show error message to user)
      setIsLoading(false);
      setErrorMessage(error.message);
    }
  };

  const handleExit = (err, metadata) => {
    // Handle the case when a user exits the Plaid Link flow
    console.log("User exited Plaid Link flow", err || "No error", metadata);
  };

  const handleEvent = (eventName, metadata) => {
    console.log("Plaid Link event", eventName, metadata);
  };

  const config = {
    onSuccess: handleSuccess,
    onExit: handleExit,
    onEvent: handleEvent,
    token: linkToken,
  };

  const { open, exit, ready, error } = usePlaidLink(config);
  if (ready) {
    open();
    setModalIsOpen(false);
  }
  return (
    <div
      className={cx(styles.plaidConnectMainConatiner, {
        [styles.plaidConnectMainConatinerMob]: isMobile,
      })}
    >
      {/* Animated Circle Loader*/}
      <ul className={styles.loaderList}>
        <li>
          <div
            className={cx(styles.loaderCircle, {
              [styles.loaderCircleMob]: isMobile,
            })}
          >
            <span></span>
          </div>
        </li>
      </ul>
      <div
        className={cx(styles.plaidConnectContent, {
          [styles.plaidConnectContentMob]: isMobile,
        })}
      >
        {SEND_ENUM.connectingPlaid}
      </div>
    </div>
  );
};

export default PlaidConnect;
