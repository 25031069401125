export async function updatePlaidExtAccount(
  customerGUID,
  extBankGuid,
  idToken
) {
  if (!customerGUID || !extBankGuid || !idToken) {
    throw new Error("Missing required parameters");
  }

  const url = `${process.env.REACT_APP_PAYEZY_SERVER_URI}/create-external-bank-account/reconnect/${extBankGuid}/patch`;

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
  };

  try {
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      const errorData = await response.json();
      console.error("Failed to create external bank account", errorData);
      throw new Error(
        `Failed to create external bank account: ${
          errorData.message || response.statusText
        }`
      );
    }

    const responseData = await response.json();
    console.log("External bank account created successfully", responseData);
    return responseData;
  } catch (error) {
    console.error("Error creating external bank account:", error);
    throw error;
  }
}
