import React, { useState, useRef } from "react";
import SelectArrow from "../../assets/SelectArrow";
import SelectArrowDown from "../../assets/SelectArrowDown";
import styles from "./index.scss";

const StateDropdown = ({
  placeHolder,
  options,
  onChange,
  setSelectedValue,
  selectedValue,
  errorDismissOnclick,
  disabled,
}) => {
  // State to manage whether the dropdown is open
  const [showMenu, setShowMenu] = useState(false);

  // Function to handle dropdown click and toggle the menu
  const handleInputClick = (e) => {
    // Do nothing if the dropdown is disabled
    if (disabled) return;
    setShowMenu(!showMenu);
    errorDismissOnclick(); // Dismiss any errors on click
  };

  const menuRef = useRef(null);

  // Get the display value (either the selected value or the placeholder)
  const getDisplay = () => {
    return selectedValue || placeHolder; // Show placeholder if no selection
  };

  // Handle when a dropdown item is clicked
  const onItemClick = (option) => {
    setSelectedValue(option.label); // Set the state name for display
    onChange(option); // Pass the selected option (state code and name) to parent component
    setShowMenu(false); // Close the dropdown
  };

  // Check if the item is selected based on state name
  const isSelected = (option) => {
    return selectedValue === option.label; // Check if selected state name matches the option
  };

  // Function to get the options (state names)
  const getOptions = () => {
    return options;
  };

  return (
    <div className="stateDropdownContainer" ref={menuRef}>
      <div
        onClick={handleInputClick}
        className={`stateDropdownInput ${disabled ? "disabled" : ""}`}
      >
        <div className="stateKey">State *</div>
        <div className="flexContainer">
          <div
            className={`dropdownSelected ${disabled ? "disabledStyle" : ""}`}
          >
            {getDisplay()}
          </div>
          <div className="stateDropdownTool">
            {!showMenu ? <SelectArrow /> : <SelectArrowDown />}
          </div>
        </div>
      </div>
      {/* Show the dropdown menu */}
      {!disabled && showMenu && (
        <div className="stateDropdownMenu">
          {getOptions().map((option) => (
            <div
              key={option.value}
              className={`state-dropdown-item ${
                isSelected(option) ? "selected" : ""
              }`}
              onClick={() => onItemClick(option)}
            >
              {option.label} {/* Display state name */}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default StateDropdown;
