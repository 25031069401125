import React, { useState, useEffect, useRef } from "react";
import { useContext } from "react";
import { AppContext } from "../../context";
import styles from "./RecurringTransfersList.module.scss";
import {
  processRecurringTransfer,
  updateRecurringTransferStatus,
  cancelIndividualTransfer,
  getRecurringTransfers,
} from "../../api/recurringTransfers";
import { TransferExecutionStatus , RecurringTransferStatus} from "../../enums/transferEnums";
import cx from "classnames";
import ButtonRade from "../../components/RadeButtons";
import { useNavigate } from "react-router-dom";
import hyperLinkIcon from "../../assets/hyperlinkIcon.svg";
import Modal from "react-modal";
import ProcessTransferConfirm from "./ProcessTransferConfirmation";
import CancelTransferConfirm from "./CancelTransferConfirm";
import ProcessingTransaction from "./ProcessingTransaction";
import CancellingTransaction from "./CancellingTransaction";
import CancelAllTransactions from "./CancelAllTransactionsConfirm";
import CancellingAllTransactions from "./CancellingAllTransactions";
import trashBinIcon from "../../assets/trashBinLogo.svg";

export default function RecurringTransfersList({
  transfers: initialTransfers,
  onStatusChange,
  tabCounts,
  activeTab,
  usdToInrExRate,
}) {
  const { isMobile } = useContext(AppContext);
  const navigate = useNavigate();

  const [transfers, setTransfers] = useState(initialTransfers);
  const [expandedId, setExpandedId] = useState(null);
  const [processingTransfers, setProcessingTransfers] = useState({});
  const [errorMessages, setErrorMessages] = useState({});
  const [isCancelling, setIsCancelling] = useState({});
  // State to set the Modal open or close
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // State for controlling different modal open based on page number
  const [modalPageNumber, setModalPageNumber] = useState(false);
  const [event, setEvent] = useState(null);
  const [transferId, setTransferId] = useState(null);
  const [index, setIndex] = useState(null);
  const [scheduledDate, setScheduledDate] = useState(null);

  // Keep track of the expanded state
  const expandedIdRef = useRef(null);
  const skipUpdateRef = useRef(false);

  // Update transfers without affecting expanded state
  useEffect(() => {
    if (skipUpdateRef.current) {
      skipUpdateRef.current = false;
      return;
    }

    console.log("Initial transfers updated:", initialTransfers);
    console.log("Current expandedId:", expandedId);
    console.log("Ref expandedId:", expandedIdRef.current);

    setTransfers(initialTransfers);

    // Only update expanded state if the current expanded transfer no longer exists
    if (expandedIdRef.current) {
      const transferStillExists = initialTransfers.some(
        (t) => t.transfer_id === expandedIdRef.current
      );
      if (!transferStillExists) {
        expandedIdRef.current = null;
        setExpandedId(null);
      } else {
        setExpandedId(expandedIdRef.current);
      }
    }
  }, [initialTransfers]);

  const handleAccordionClick = (transferId, event) => {
    if (event.target.tagName === "BUTTON" || event.target.closest("button")) {
      event.stopPropagation();
      return;
    }

    const newExpandedId = expandedId === transferId ? null : transferId;
    console.log("Setting expanded state:", {
      newExpandedId,
      currentExpanded: expandedId,
    });

    expandedIdRef.current = newExpandedId;
    setExpandedId(newExpandedId);
  };

  const updateTransferStatus = async (transferId, txNum, newStatus) => {
    console.log("Updating transfer status:", {
      transferId,
      txNum,
      newStatus,
      currentExpanded: expandedId,
    });

    skipUpdateRef.current = true;

    try {
      const updatedTransfers = transfers.map((transfer) => {
        if (transfer.transfer_id === transferId) {
          const updatedScheduledTransfers = [...transfer.scheduled_transfers];
          updatedScheduledTransfers[txNum] = {
            ...updatedScheduledTransfers[txNum],
            status: newStatus,
          };

          // Check if all transfers are cancelled
          const allCancelled = updatedScheduledTransfers.every(
            (t) => t.status === "CANCELLED"
          );

          // Check if all transfers are either completed or cancelled
          const allCompletedOrCancelled = updatedScheduledTransfers.every(
            (t) => t.status === "COMPLETED" || t.status === "CANCELLED"
          );

          // Check if at least one transfer is completed
          const hasCompletedTransfers = updatedScheduledTransfers.some(
            (t) => t.status === "COMPLETED"
          );

          // Determine the new main status
          let newMainStatus = transfer.status;
          if (allCancelled) {
            newMainStatus = RecurringTransferStatus.CANCELLED;
          } else if (allCompletedOrCancelled && hasCompletedTransfers) {
            // If all transfers are completed/cancelled and at least one is completed,
            // mark the set as completed
            newMainStatus = RecurringTransferStatus.COMPLETED;
          }

          const updatedTransfer = {
            ...transfer,
            scheduled_transfers: updatedScheduledTransfers,
            status: newMainStatus,
          };

          // If the status has changed, update it in the backend
          if (newMainStatus !== transfer.status) {
            updateRecurringTransferStatus(transferId, newMainStatus).catch(
              console.error
            );
          }

          return updatedTransfer;
        }
        return transfer;
      });

      setTransfers(updatedTransfers);

      if (onStatusChange) {
        onStatusChange(updatedTransfers);
      }
    } finally {
      skipUpdateRef.current = false;
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  };

  const handleOnClickProcess = (e, transferId, index, scheduledDate) => {
    setModalIsOpen(true);
    setModalPageNumber(0);
    setEvent(e);
    setTransferId(transferId);
    setIndex(index);
    setScheduledDate(scheduledDate);
  };
  const handleProcessTransfer = async (e, transferId, index, scheduledDate) => {
    console.log("Process transfer clicked:", {
      transferId,
      index,
      scheduledDate,
      event: e,
    });
    e.preventDefault();
    e.stopPropagation();

    const transferKey = `${transferId}_${index}_${scheduledDate}`;
    if (processingTransfers[transferKey]) {
      console.log("Already processing, skipping");
      return;
    }

    setProcessingTransfers((prev) => ({ ...prev, [transferKey]: true }));
    setErrorMessages((prev) => ({ ...prev, [transferKey]: null }));

    try {
      console.log("Starting process transfer");
      await processRecurringTransfer(transferId, index);

      // Fetch the updated transfer data to get the latest history
      const updatedTransfers = await getRecurringTransfers();
      const updatedTransfer = updatedTransfers.find(
        (t) => t.transfer_id === transferId
      );

      if (updatedTransfer) {
        // Update the local state with the new transfer data
        setTransfers((prevTransfers) =>
          prevTransfers.map((t) =>
            t.transfer_id === transferId ? updatedTransfer : t
          )
        );

        // Notify parent of the update with all transfers
        if (onStatusChange) {
          onStatusChange(updatedTransfers);
        }
      } else {
        // If we couldn't find the updated transfer, just update the status
        await updateTransferStatus(transferId, index, "COMPLETED");
      }

      console.log("Process transfer completed");
      setModalIsOpen(false);
    } catch (error) {
      console.error("Process transfer error:", error);
      setErrorMessages((prev) => ({
        ...prev,
        [transferKey]: error.message || "Failed to process transfer",
      }));
      await updateTransferStatus(transferId, index, "FAILED");
      setModalIsOpen(false);
    } finally {
      setProcessingTransfers((prev) => ({ ...prev, [transferKey]: false }));
      setModalIsOpen(false);
    }
  };

  const handleOnClickCancel = (e, transferId, index, scheduledDate) => {
    setModalIsOpen(true);
    setModalPageNumber(2);
    setEvent(e);
    setTransferId(transferId);
    setIndex(index);
    setScheduledDate(scheduledDate);
  };

  const handleCancelTransfer = async (e, transferId, index, scheduledDate) => {
    console.log("Cancel transfer clicked:", {
      transferId,
      index,
      scheduledDate,
      event: e,
    });
    e.preventDefault();
    e.stopPropagation();

    const transferKey = `${transferId}-${index}-${scheduledDate}`;
    if (isCancelling[transferKey]) {
      console.log("Already cancelling, skipping");
      return;
    }

    setIsCancelling((prev) => ({ ...prev, [transferKey]: true }));
    setErrorMessages((prev) => ({ ...prev, [transferKey]: null }));

    try {
      console.log("Starting cancel transfer");
      await cancelIndividualTransfer(transferId, index);
      await updateTransferStatus(transferId, index, "CANCELLED");
      console.log("Cancel transfer completed");
      setModalIsOpen(false);
    } catch (error) {
      console.error("Cancel transfer error:", error);
      setErrorMessages((prev) => ({
        ...prev,
        [transferKey]: error.message || "Failed to cancel transfer",
      }));
      setModalIsOpen(false);
    } finally {
      setIsCancelling((prev) => ({ ...prev, [transferKey]: false }));
      setModalIsOpen(false);
    }
  };
  const handleOnClickCancelAll = async (transferId) => {
    setModalIsOpen(true);
    setModalPageNumber(4);
    setTransferId(transferId);
  };
  const handleCancelRecurringTransfer = async (transferId) => {
    setIsCancelling((prev) => ({ ...prev, [transferId]: true }));
    setErrorMessages((prev) => ({ ...prev, [transferId]: null }));

    try {
      await updateRecurringTransferStatus(
        transferId,
        RecurringTransferStatus.CANCELLED
      );

      // Fetch fresh data to ensure we have the latest state
      const updatedTransfers = await getRecurringTransfers();

      // Keep the accordion expanded
      expandedIdRef.current = transferId;
      setExpandedId(transferId);

      // Update local state
      setTransfers(updatedTransfers);

      // Notify parent of the update with all transfers and set active tab to CANCELLED
      if (onStatusChange) {
        onStatusChange(updatedTransfers, "CANCELLED");
      }
      setModalIsOpen(false);
    } catch (error) {
      setErrorMessages((prev) => ({
        ...prev,
        [transferId]: error.message || "Failed to cancel recurring transfer",
      }));
      setModalIsOpen(false);
    } finally {
      setIsCancelling((prev) => ({ ...prev, [transferId]: false }));
      setModalIsOpen(false);
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case TransferExecutionStatus.COMPLETED:
        return styles.statusCompleted;
      case TransferExecutionStatus.FAILED:
        return styles.statusFailed;
      case TransferExecutionStatus.CANCELLED:
        return styles.statusFailed;
      default:
        return styles.statusPending;
    }
  };

  const renderCancelAllButton = (transfer) => {
    const pending = transfer.scheduled_transfers.filter(
      (t) => t.status === TransferExecutionStatus.PENDING
    ).length;
    return (
      <div className={styles.cancelAllButtonContainer}>
        {transfer.status === "ACTIVE" && pending > 0 && (
          <ButtonRade
            customStyling={styles.cancelAllButton}
            onClick={() => handleOnClickCancelAll(transfer.transfer_id)}
            disabled={isCancelling[transfer.transfer_id]}
          >
            {isCancelling[transfer.transfer_id]
              ? "Cancelling..."
              : "Cancel All"}
          </ButtonRade>
        )}
      </div>
    );
  };
  const renderTransferSummary = (transfer) => {
    const completed = transfer.scheduled_transfers.filter(
      (t) => t.status === TransferExecutionStatus.COMPLETED
    ).length;
    const pending = transfer.scheduled_transfers.filter(
      (t) => t.status === TransferExecutionStatus.PENDING
    ).length;
    const failed = transfer.scheduled_transfers.filter(
      (t) => t.status === TransferExecutionStatus.FAILED
    ).length;

    return (
      <div className={styles.summaryMainContainer}>
        {isMobile ? (
          <div className={styles.summaryMob}>
            <div className={styles.flexContainer}>
              <div className={styles.summaryItem}>
                <div className={styles.summaryLabel}>Total Transfers</div>
                <div className={styles.summaryValue}>
                  {transfer.total_transfers_scheduled}
                </div>
              </div>
              <div className={styles.summaryItem}>
                <div className={styles.summaryLabel}>Completed</div>
                <div className={styles.summaryValueCompletedMob}>
                  {completed}
                </div>
              </div>
            </div>
            <div className={styles.flexContainer}>
              <div className={styles.summaryItem}>
                <div className={styles.summaryLabel}>Pending</div>
                <div className={styles.summaryValue}>{pending}</div>
              </div>
              <div className={styles.summaryItem}>
                <div className={styles.summaryLabel}>Failed</div>
                <div className={styles.summaryValueFailedMob}>{failed}</div>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.summary}>
            <div className={styles.summaryItem}>
              <div className={styles.summaryLabel}>Total Transfers</div>
              <div className={styles.summaryValue}>
                {transfer.total_transfers_scheduled}
              </div>
            </div>
            <div className={styles.summaryItem}>
              <div className={styles.summaryLabel}>Completed</div>
              <div className={styles.summaryValue}>{completed}</div>
            </div>
            <div className={styles.summaryItem}>
              <div className={styles.summaryLabel}>Pending</div>
              <div className={styles.summaryValue}>{pending}</div>
            </div>
            <div className={styles.summaryItem}>
              <div className={styles.summaryLabel}>Failed</div>
              <div className={styles.summaryValue}>{failed}</div>
            </div>
          </div>
        )}
        {errorMessages[transfer.transfer_id] && (
          <div className={styles.error}>
            {errorMessages[transfer.transfer_id]}
          </div>
        )}
      </div>
    );
  };

  // const renderTransferHistory = (transfer) => {
  //   return transfer.transfer_history?.map((history, index) => (
  //     <div key={index} className={styles.transferHistoryDetails}>
  //       <div className={styles.flexContainerTransferId}>
  //         <div className={styles.transferDetailsKey}>Transfer ID: </div>
  //         <div
  //           className={styles.transferIdValue}
  //           onClick={() =>
  //             navigate("/transfers", {
  //               state: { transactionIdParam: history.transfer_id },
  //             })
  //           }
  //         >
  //           {history.transfer_id}
  //           <img
  //             src={hyperLinkIcon}
  //             alt="hyperLinkIcon"
  //             className={styles.hyperLinkIcon}
  //           />
  //         </div>
  //       </div>
  //       <div className={styles.flexContainer}>
  //         <div className={styles.transferDetailsKey}>Executed: </div>
  //         <div className={styles.transferDetailsValue}>
  //           {formatDate(history.executed_at)}
  //         </div>
  //       </div>
  //       <div className={styles.flexContainer}>
  //         <div className={styles.transferDetailsKey}>Amount: </div>
  //         <div className={styles.transferDetailsValue}>
  //           {formatCurrency(history.amount_in_usd)} (₹{history.amount_in_inr})
  //         </div>
  //       </div>
  //       <div className={styles.flexContainer}>
  //         <div className={styles.transferDetailsKey}>Exchange Rate: </div>
  //         <div className={styles.transferDetailsValue}>
  //           ₹{history.exchange_rate}
  //         </div>
  //       </div>
  //     </div>
  //   ));
  // };

  const renderScheduledTransfers = (transfer) => {
    return transfer.scheduled_transfers.map((scheduledTransfer, index) => {
      const transferKey = `${transfer.transfer_id}_${index}_${scheduledTransfer.scheduled_date}`;
      const isProcessing = processingTransfers[transferKey];
      const isCancellingTransfer = isCancelling[transferKey];
      const errorMessage = errorMessages[transferKey];
      const matchingHistory = transfer.transfer_history?.find(
        (h) => h.scheduled_date === scheduledTransfer.scheduled_date
      );

      // console.log("matchingHistory", matchingHistory);
      // console.log("transferHistory", transfer.transfer_history);
      // Get the corresponding transfer_id based on the index
      const transferIdFromHistory =
        transfer.transfer_history[index]?.transfer_id;
      console.log("transferIdFromHistory", transferIdFromHistory);
      // Only show action buttons if the main transfer set is not cancelled
      const showActionButtons =
        transfer.status !== RecurringTransferStatus.CANCELLED &&
        scheduledTransfer.status === TransferExecutionStatus.PENDING;

      return (
        <>
          <div key={index} className={styles.transferItem}>
            <div
              className={cx(styles.mainTransferDetails, {
                [styles.transferItemWithoutActions]: !showActionButtons,
                [styles.transferItemWithActions]: showActionButtons,
              })}
            >
              <div className={styles.transferDate}>
                {formatDate(scheduledTransfer.scheduled_date)}
              </div>
              <div className={styles.transferAmount}>
                {formatCurrency(scheduledTransfer.amount_in_usd)}
                <div
                  className={`${styles.transferStatus} ${getStatusClass(
                    scheduledTransfer.status
                  )}`}
                >
                  ({scheduledTransfer.status})
                </div>
              </div>
            </div>
            {scheduledTransfer.status === "COMPLETED" && (
              <div
                className={styles.transferIdValue}
                onClick={() =>
                  navigate("/transfers", {
                    state: {
                      transactionIdParam: transferIdFromHistory, // Use the transfer_id from history based on index
                    },
                  })
                }
              >
                Settled
                <img
                  src={hyperLinkIcon}
                  alt="hyperLinkIcon"
                  className={styles.hyperLinkIcon}
                />
              </div>
            )}
            {showActionButtons && (
              <div className={styles.transferActions}>
                <ButtonRade
                  customStyling={styles.processButton}
                  onClick={(e) =>
                    handleOnClickProcess(
                      e,
                      transfer.transfer_id,
                      index,
                      scheduledTransfer.scheduled_date
                    )
                  }
                  disabled={isProcessing || isCancellingTransfer}
                >
                  {isProcessing ? <>Processing...</> : "Process Now"}
                </ButtonRade>
                <ButtonRade
                  customStyling={styles.cancelButton}
                  onClick={(e) =>
                    handleOnClickCancel(
                      e,
                      transfer.transfer_id,
                      index,
                      scheduledTransfer.scheduled_date
                    )
                  }
                  disabled={isCancellingTransfer || isProcessing}
                >
                  <img
                    src={trashBinIcon}
                    alt="trashBinIcon"
                    className={styles.trashBinIcon}
                  />
                </ButtonRade>
              </div>
            )}
          </div>

          {errorMessage && <div className={styles.error}>{errorMessage}</div>}
          {matchingHistory && (
            <div className={styles.transferDetails}>
              <div>
                Transfer ID:{" "}
                <span className={styles.transferId}>
                  {matchingHistory.transfer_id}
                </span>
              </div>
            </div>
          )}
        </>
      );
    });
  };

  if (!transfers || transfers.length === 0) {
    return (
      <>
        {activeTab === "ACTIVE" && tabCounts.ACTIVE === 0 && (
          <div
            className={cx(styles.noTransfers, {
              [styles.noTransfersMob]: isMobile,
            })}
          >
            No recurring transfers found. Create one to get started!
          </div>
        )}
        {activeTab === "COMPLETED" && tabCounts.COMPLETED === 0 && (
          <div
            className={cx(styles.noTransfers, {
              [styles.noTransfersMob]: isMobile,
            })}
          >
            You have no completed transfers.
          </div>
        )}
        {activeTab === "CANCELLED" && tabCounts.CANCELLED === 0 && (
          <div
            className={cx(styles.noTransfers, {
              [styles.noTransfersMob]: isMobile,
            })}
          >
            You have no cancelled transfers.
          </div>
        )}
        {activeTab === "ACTIVE" && (
          <div className={styles.createButtonContainer}>
            <ButtonRade
              customStyling={cx(styles.createButton, {
                [styles.createButtonMob]: isMobile,
              })}
              onClick={() => navigate("/autoSend/create")}
            >
              Create New
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  clipRule="evenodd"
                />
              </svg>
            </ButtonRade>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <div
        className={cx(styles.container, {
          [styles.containerMob]: isMobile,
        })}
      >
        <div className={styles.accordion}>
          {transfers.map((transfer) => (
            <div key={transfer.transfer_id} className={styles.accordionItem}>
              <div
                className={styles.accordionHeader}
                onClick={(e) => handleAccordionClick(transfer.transfer_id, e)}
              >
                <div
                  className={cx(styles.receiverNameAndStatus, {
                    [styles.receiverNameAndStatusIfExpanded]:
                      expandedId === transfer.transfer_id,
                  })}
                >
                  <div>
                    {transfer.receiver_details.receiver_name} (
                    {transfer.receiver_details.receiver_nickname})
                  </div>
                  <div
                    className={cx(styles.transferStatusColor, {
                      [styles.transferStatusActive]:
                        transfer.status === "ACTIVE",
                      [styles.transferStatusCompleted]:
                        transfer.status === "COMPLETED",
                      [styles.transferStatusCancelled]:
                        transfer.status === "CANCELLED",
                    })}
                  >
                    {transfer.status}
                  </div>
                </div>
                <div
                  className={`${styles.accordionContent} ${
                    expandedId === transfer.transfer_id ? styles.expanded : ""
                  }`}
                >
                  {renderTransferSummary(transfer)}
                  <div className={styles.transferList}>
                    <div className={styles.scheduledTransfersTitle}>
                      Scheduled Transfers
                      {renderCancelAllButton(transfer)}
                    </div>
                    {renderScheduledTransfers(transfer)}
                  </div>
                  {/* {transfer.transfer_history?.length > 0 && (
                    <div className={styles.transferHistory}>
                      <div className={styles.scheduledTransfersTitle}>
                        Transfer History
                      </div>
                      {renderTransferHistory(transfer)}
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        {(() => {
          switch (modalPageNumber) {
            case 0:
              return (
                <ProcessTransferConfirm
                  setModalIsOpen={setModalIsOpen}
                  handleProcessTransfer={handleProcessTransfer}
                  event={event}
                  transferId={transferId}
                  index={index}
                  scheduledDate={scheduledDate}
                  setModalPageNumber={setModalPageNumber}
                  usdToInrExRate={usdToInrExRate}
                />
              );
            case 1:
              return <ProcessingTransaction />;
            case 2:
              return (
                <CancelTransferConfirm
                  setModalIsOpen={setModalIsOpen}
                  handleCancelTransfer={handleCancelTransfer}
                  event={event}
                  transferId={transferId}
                  index={index}
                  scheduledDate={scheduledDate}
                  setModalPageNumber={setModalPageNumber}
                />
              );
            case 3:
              return <CancellingTransaction />;
            case 4:
              return (
                <CancelAllTransactions
                  setModalIsOpen={setModalIsOpen}
                  transferId={transferId}
                  handleCancelRecurringTransfer={handleCancelRecurringTransfer}
                  setModalPageNumber={setModalPageNumber}
                />
              );
            case 5:
              return <CancellingAllTransactions />;
            default:
              return null;
          }
        })()}
      </Modal>
    </>
  );
}
