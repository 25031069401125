import React, { useContext, useState, useEffect } from "react";
import cx from "classnames";
import { CSSTransition } from "react-transition-group";
import styles from "./mobile.module.scss";
import { useRef } from "react";
import { AppContext } from "../../context";
import { NavLink, useLocation } from "react-router-dom";
import hamburgerClose from "../../assets/hamburgerClose.svg";
import hamburgerMenu from "../../assets/hamburgerMenu.svg";
import FooterMain from "../../components/FooterMain/FooterMain";
import GoogleLogout from "../../components/GoogleLogout/GoogleLogout";
import Modal from "react-modal";
import { SEND_ENUM } from "../../enums/sendEnum";
const durationEmail = 1000;
const durationNavbar = 100;

const NavBarMobile = ({
  profileEmail,
  setProfileEmail,
  setAmountInINR,
  setAmountInUSD,
  setProfile,
  setSendFlowPageNumber,
}) => {
  // default mobile view navbar
  const [showMenu, setShowMenu] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const nodeRef = useRef();
  const location = useLocation();
  const transitionStyles = {
    enterActive: styles.menuBarEnterActive,
    enterDone: styles.menuBarEnter,
    exitActive: styles.menuBarExitActive,
    exitDone: styles.menuBarExit,
  };

  const { isMobile, customerState } = useContext(AppContext);
  // State to set the Modal open or close
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    setShowMenu(false);
    setModalIsOpen(true);
  };
  //Function that handles on on click of navlink
  const handleNavLinkClick = () => {
    setShowMenu(false);
  };

  useEffect(() => {
    if (showMenu) {
      setShowEmail(true);
    } else {
      setShowEmail(false);
    }
  }, [showMenu]);

  return (
    <>
      <div className={styles.mainDiv}>
        <div
          className={cx(styles.mobileButtons, {
            [styles.mobileButtonsMob]: showMenu,
          })}
        >
          {showMenu && (
            <CSSTransition
              in={showEmail}
              timeout={durationEmail}
              classNames={{
                enter: styles.profileEmailContainerEnter,
                enterActive: styles.profileEmailContainerEnterActive,
                exit: styles.profileEmailContainerExit,
                exitActive: styles.profileEmailContainerExitActive,
              }}
              mountOnEnter
              unmountOnExit
            >
              <div
                className={cx(styles.profileEmailContainer, {
                  [styles.profileEmailContainerMob]: isMobile,
                })}
              >
                <div
                  className={cx(styles.profileEmail, {
                    [styles.profileEmailMob]: isMobile,
                  })}
                >
                  {profileEmail}
                </div>
              </div>
            </CSSTransition>
          )}
          <button
            className={cx(styles.hamburgerButton, {
              [styles.hbWhite]: !showMenu,
              [styles.hbGreen]: showMenu,
            })}
            onClick={() => setShowMenu((pre) => !pre)}
          >
            {showMenu ? (
              <img
                src={hamburgerClose}
                alt="cross"
                className={styles.menuIcon}
              />
            ) : (
              <img
                src={hamburgerMenu}
                alt="menu icon"
                className={styles.menuIcon}
              />
            )}
          </button>
        </div>
        <CSSTransition
          nodeRef={nodeRef}
          in={showMenu}
          timeout={durationNavbar}
          classNames={transitionStyles}
          mountOnEnter
        >
          <div ref={nodeRef} className={styles.menuBar}>
            <nav className={styles.navBar}>
              <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                <li className={styles.navBarItems}>
                  <NavLink
                    className={`${styles.navLink} ${
                      location.pathname === "/send" ? styles.activeNavLink : ""
                    }`}
                    to="/send"
                    onClick={handleNavLinkClick}
                  >
                    Send
                  </NavLink>
                </li>
                <li className={styles.navBarItems}>
                  <NavLink
                    className={`${styles.navLink} ${
                      location.pathname === "/profile"
                        ? styles.activeNavLink
                        : ""
                    }`}
                    to="/profile"
                    onClick={handleNavLinkClick}
                  >
                    Profile
                  </NavLink>
                </li>
                {customerState === "verified" && (
                  <li className={styles.navBarItems}>
                    <NavLink
                      className={`${styles.navLink} ${
                        location.pathname === "/connectBank"
                          ? styles.activeNavLink
                          : ""
                      }`}
                      to="/connectBank"
                      onClick={handleNavLinkClick}
                    >
                      Connect Bank
                    </NavLink>
                  </li>
                )}
                <li className={styles.navBarItems}>
                  <NavLink
                    className={`${styles.navLink} ${
                      location.pathname === "/transfers"
                        ? styles.activeNavLink
                        : ""
                    }`}
                    to="/transfers"
                    onClick={handleNavLinkClick}
                  >
                    Transfers
                  </NavLink>
                </li>
                {customerState === "verified" && (
                  <li className={styles.navBarItems}>
                    <NavLink
                      className={`${styles.navLink} ${
                        location.pathname === "/autoSend"
                          ? styles.activeNavLink
                          : ""
                      }`}
                      to="/autoSend"
                      onClick={handleNavLinkClick}
                    >
                      AutoSend
                      <div className={styles.betaBadge}>BETA</div>
                    </NavLink>
                  </li>
                )}
                <li className={styles.navBarItems}>
                  <NavLink
                    className={`${styles.navLink} ${
                      location.pathname === "/support"
                        ? styles.activeNavLink
                        : ""
                    }`}
                    to="/support"
                    onClick={handleNavLinkClick}
                  >
                    Support
                  </NavLink>
                </li>
                <li className={styles.navBarItems}>
                  <a
                    className={styles.discordNavLink}
                    href="https://discord.com/invite/YSMxERM3me"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Discord
                  </a>
                </li>
                <li className={styles.navBarItems}>
                  <a
                    className={styles.discordNavLink}
                    href="https://docs.payezy.io/overview/payezy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Docs
                  </a>
                </li>
                <div className={styles.logoutButtonContainer}>
                  <button
                    onClick={logOut}
                    className={cx(styles.logoutButton, {
                      [styles.logoutButtonMob]: isMobile,
                    })}
                  >
                    {SEND_ENUM.logout}
                  </button>
                </div>
                {/* Footer component */}
                <div className={styles.footerMob}>
                  <FooterMain />
                </div>
              </ul>
            </nav>
          </div>
        </CSSTransition>
      </div>
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <GoogleLogout
          setModalIsOpen={setModalIsOpen}
          setProfile={setProfile}
          setProfileEmail={setProfileEmail}
          setAmountInINR={setAmountInINR}
          setAmountInUSD={setAmountInUSD}
          setSendFlowPageNumber={setSendFlowPageNumber}
        />
      </Modal>
    </>
  );
};

export default NavBarMobile;
