import React, { useState } from "react";
import styles from "./index.module.scss";
import cx from "classnames";
import { useContext } from "react";
import { AppContext } from "../../context";
import { SEND_ENUM } from "../../enums/sendEnum";
import RadeButton from "../../components/RadeButtons";
import ButtonRade from "../../components/RadeButtons";
import { auth } from "../../firebase";
import mixpanel from "mixpanel-browser";
import ToggleIcon from "../../assets/toggleIcon.svg";

const MAX_DECIMAL_PLACE = 2; //varibale that defines maximum decimal place after integer
const SendINR = ({
  amountInUSD,
  amountInINR,
  setAmountInINR,
  usdToInrExRate,
  fetchingPrice,
  errorForLogin,
  handleClickProceedButton,
  promotionCode,
  setPromotionCode,
  secureToken,
  setSecureToken,
  setNewExchangeRate,
  newExchangeRate,
  customerActivityLimit,
  isLoading,
  userSelectionMode,
  setUserSelectionMode,
  setErrorForLogin,
  setAmountInUSD,
  setFetchingPrice,
}) => {
  const { isMobile, profileEmail } = useContext(AppContext);
  const [processingCharge, setProcessingCharge] = useState(null); //state that store processing charge
  const [errorMessage, setErrorMessage] = useState("");

  const handlePromotionCodeChange = (e) => {
    setErrorMessage("");
    // Regular expression to allow only capital letters and numbers
    const value = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, "");
    setPromotionCode(value);
  };

  //Function to handle the toggling
  const handleOnClickToggleButton = () => {
    setErrorForLogin("");
    // Reset amounts if they are empty or zero
    if (!amountInUSD || Number(amountInUSD) === 0) {
      setAmountInUSD("");
    }
    if (!amountInINR || Number(amountInINR) === 0) {
      setAmountInINR("");
    }
    // Toggle the user selection mode
    setUserSelectionMode((prev) =>
      prev === SEND_ENUM.usdEnterMode
        ? SEND_ENUM.inrEnterMode
        : SEND_ENUM.usdEnterMode
    );
  };

  async function checkPromoCode(code) {
    const idToken = await auth.currentUser.getIdToken(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`, // Include the ID token in the headers
      },
      body: JSON.stringify({ code }),
    };
    const response = await fetch(
      process.env.REACT_APP_PAYEZY_SERVER_URI + `/check-promo-code`,
      requestOptions
    );
    return response.json();
  }

  async function applyPromoCode(baseExchangeRate, promotionCode, secureToken) {
    const idToken = await auth.currentUser.getIdToken(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`, // Include the ID token in the headers
      },
      body: JSON.stringify({ baseExchangeRate, promotionCode, secureToken }),
    };
    const response = await fetch(
      process.env.REACT_APP_PAYEZY_SERVER_URI + `/apply-promo-code`,
      requestOptions
    );
    return response.json();
  }

  const handlePromoCode = async (promotionCode, baseExchangeRate) => {
    if (!promotionCode) {
      setErrorMessage("Please enter the promo code!");
    } else if (!amountInUSD) {
      setErrorMessage(
        "Please enter the USD amount before applying the promo code."
      );
    } else {
      try {
        const checkResult = await checkPromoCode(promotionCode);
        if (checkResult.isValid) {
          // Store the secure token temporarily (e.g., in component state)
          const secureToken = checkResult.secureToken;

          // Apply the promo code
          const promoAppliedResponse = await applyPromoCode(
            baseExchangeRate,
            promotionCode,
            secureToken
          );
          const finalRate = promoAppliedResponse.finalRate;
          const finalRateFixed = Number(finalRate).toFixed(MAX_DECIMAL_PLACE);
          // If finalRate exists, update the amount and save the transaction to Firebase
          if (finalRate) {
            setNewExchangeRate(finalRate);
            setAmountInINR(
              (finalRateFixed * Number(amountInUSD)).toFixed(MAX_DECIMAL_PLACE)
            );
            if (process.env.REACT_APP_DEPLOYMENT_MODE === "PRODUCTION") {
              mixpanel.track("clicking promo code apply!");
            }
            setPromotionCode(promotionCode);
            setSecureToken(secureToken);
          } else {
            // If no finalRate is returned, use the base exchange rate
            setAmountInINR(
              (baseExchangeRate * Number(amountInUSD)).toFixed(
                MAX_DECIMAL_PLACE
              )
            );
          }
        } else {
          setErrorMessage("Invalid promo code!");
          setAmountInINR(
            (baseExchangeRate * Number(amountInUSD)).toFixed(MAX_DECIMAL_PLACE)
          );
        }
      } catch (error) {
        console.log("Error:", error);
      }
    }
  };

  const handleInputChange = (e) => {
    const enteredValue = e.target.value;
    let limitedValue;

    // Use newExchangeRate if it exists, otherwise fall back to usdToInrExRate
    const currentExchangeRate = newExchangeRate || usdToInrExRate;

    if (userSelectionMode === SEND_ENUM.usdEnterMode) {
      // Check if the user is logged in
      if (profileEmail) {
        // If customerActivityLimit is 0, allow 5 digits before decimal and 2 after
        if (customerActivityLimit === 0) {
          const regex = /^\d{0,5}(\.\d{0,2})?/;
          limitedValue = enteredValue.match(regex)?.[0];
        } else {
          // Dynamically calculate max digits based on customerActivityLimit
          const maxIntegerDigits = String(
            Math.floor(customerActivityLimit)
          ).length;

          // Limit to max integer digits before decimal and 2 after
          const regex = new RegExp(`^\\d{0,${maxIntegerDigits}}(\\.\\d{0,2})?`);
          limitedValue = enteredValue.match(regex)?.[0];

          // Additional check to ensure value doesn't exceed customerActivityLimit
          const numValue = parseFloat(limitedValue || "0");
          if (numValue > customerActivityLimit) {
            limitedValue = customerActivityLimit.toFixed(2);
          }
        }
      } else {
        const regex = /^\d{0,5}(\.\d{0,2})?/;
        // If not logged in, allow any value before decimal point and 2 digits after decimal point
        limitedValue = enteredValue.match(regex)?.[0];
      }

      setAmountInUSD(limitedValue);

      if (currentExchangeRate > 0) {
        setAmountInINR(
          (currentExchangeRate * limitedValue).toFixed(MAX_DECIMAL_PLACE)
        );
      } else {
        setFetchingPrice(true);
      }
    } else {
      // Limit INR input to 2 digits after decimal point
      const regex = /^\d*(\.\d{0,2})?/;
      limitedValue = enteredValue.match(regex)?.[0];

      setAmountInINR(limitedValue);

      if (currentExchangeRate > 0) {
        setAmountInUSD(
          (limitedValue / currentExchangeRate).toFixed(MAX_DECIMAL_PLACE)
        );
      } else {
        setFetchingPrice(true);
      }
    }
    setFetchingPrice(false);
    setErrorForLogin(false);
    if (process.env.REACT_APP_DEPLOYMENT_MODE === "PRODUCTION") {
      mixpanel.track("Price Discovery");
    }
  };

  return (
    <div>
      <div className={styles.enterAmountOfUSDWishToSend}>
        {SEND_ENUM.enterAmountOfUSDWishToSend}
      </div>{" "}
      <div
        className={cx(styles.sendRUDMainContainer, {
          [styles.sendRUDMainContainerMob]: isMobile,
        })}
      >
        {/* Input Value entering Section */}
        <div
          className={cx(styles.sendRUD, {
            [styles.sendRUDMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.sendRUDDiv, {
              [styles.sendRUDDivMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.sendingRUD, {
                [styles.sendingRUDMob]: isMobile,
              })}
            >
              {userSelectionMode === SEND_ENUM.usdEnterMode
                ? SEND_ENUM.youSend
                : SEND_ENUM.recipientGets}
            </div>
            {profileEmail && userSelectionMode === SEND_ENUM.usdEnterMode && (
              <div
                className={cx(styles.treasuryBalance, {
                  [styles.treasuryBalanceMob]: isMobile,
                })}
              >
                Maximum allowed: ${customerActivityLimit}
              </div>
            )}
          </div>

          <div
            className={cx(styles.sendingRUDValue, {
              [styles.sendingRUDValueMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.dollarSymbol, {
                [styles.dollarSymbolMob]: isMobile,
              })}
            >
              {userSelectionMode === SEND_ENUM.usdEnterMode ? "$" : "₹"}
            </div>
            <input
              type="number"
              placeholder={
                userSelectionMode === SEND_ENUM.usdEnterMode ? "00.00" : "00.00"
              }
              value={
                userSelectionMode === SEND_ENUM.usdEnterMode
                  ? amountInUSD
                  : amountInINR
              }
              disabled={customerActivityLimit === undefined && profileEmail}
              onChange={handleInputChange}
              onKeyDown={(e) => {
                if (e.key === "-") {
                  e.preventDefault();
                }
              }}
              autoComplete="off"
              className={cx(styles.inputAmount, {
                [styles.inputAmountMob]: isMobile,
              })}
            />
            <span
              className={cx(styles.USD, {
                [styles.USDMob]: isMobile,
              })}
            >
              {userSelectionMode === SEND_ENUM.usdEnterMode ? "USD" : "INR"}
            </span>
          </div>
        </div>
        {/* Toggle Button */}
        <div
          className={cx(styles.toggleButtonDiv, {
            [styles.toggleButtonDivMob]: isMobile,
          })}
        >
          <button
            className={cx(styles.toggleButton, {
              [styles.toggleButtonMob]: isMobile,
            })}
            onClick={handleOnClickToggleButton}
          >
            <img
              src={ToggleIcon}
              alt="Toggle_Icon"
              className={styles.toggleIcon}
            />
          </button>
        </div>
        {/* Display Section */}
        <div
          className={cx(styles.getINRContainer, {
            [styles.getINRContainerMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.sendRUDDiv, {
              [styles.sendRUDDivMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.sendingRUD, {
                [styles.sendingRUDMob]: isMobile,
              })}
            >
              {userSelectionMode === SEND_ENUM.usdEnterMode
                ? SEND_ENUM.recipientGets
                : SEND_ENUM.youSend}
            </div>
            {profileEmail && userSelectionMode === SEND_ENUM.inrEnterMode && (
              <div
                className={cx(styles.treasuryBalance, {
                  [styles.treasuryBalanceMob]: isMobile,
                })}
              >
                Maximum allowed: ${customerActivityLimit}
              </div>
            )}
          </div>
          <div
            className={cx(styles.getINRAmountValue, {
              [styles.getINRAmountValueMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.inputAmount, {
                [styles.inputAmountMob]: isMobile,
              })}
            >
              {userSelectionMode === SEND_ENUM.usdEnterMode
                ? `₹${Number(amountInINR || "00.00")
                    .toFixed(MAX_DECIMAL_PLACE)
                    .padStart(5, "0")}`
                : `$${Number(amountInUSD || "00.00")
                    .toFixed(MAX_DECIMAL_PLACE)
                    .padStart(5, "0")}`}{" "}
            </div>
            <div
              className={cx(styles.INR, {
                [styles.INRMob]: isMobile,
              })}
            >
              {userSelectionMode === SEND_ENUM.usdEnterMode ? "INR" : "USD"}
            </div>
          </div>
        </div>
        {usdToInrExRate && (
          <div className={styles.exchangeRateContainer}>
            {promotionCode && secureToken ? (
              <>
                {/* Strike through both the label and value */}

                <div className={styles.strikedText}>
                  <s>
                    <div className={styles.feeBreakDownContainerHighlighted}>
                      <div className={styles.bankTransferChargeHighlighted}>
                        Exchange Rate (Best Price)
                      </div>
                      <div
                        className={styles.bankTransferChargeValueHighlighted}
                      >
                        ₹{usdToInrExRate.toFixed(MAX_DECIMAL_PLACE)}
                      </div>
                    </div>
                  </s>
                </div>

                {/* Display the new final rate */}
                <div className={styles.feeBreakDownContainerHighlighted}>
                  <div className={styles.bankTransferChargeHighlighted}>
                    Applied Promo Rate
                  </div>
                  <div className={styles.bankTransferChargeValueHighlighted}>
                    ₹{newExchangeRate.toFixed(MAX_DECIMAL_PLACE)}
                  </div>
                </div>
              </>
            ) : (
              <div className={styles.feeBreakDownContainerHighlighted}>
                <div className={styles.bankTransferChargeHighlighted}>
                  Exchange Rate (Best Price)
                </div>
                <div className={styles.bankTransferChargeValueHighlighted}>
                  ₹{usdToInrExRate.toFixed(MAX_DECIMAL_PLACE)}
                </div>
              </div>
            )}
          </div>
        )}
        <div
          className={cx(styles.getPromotionCodeContainer, {
            [styles.getPromotionCodeContainerMob]: isMobile,
          })}
        >
          <div className={styles.flexContainer}>
            <div
              className={cx(styles.getPromotionCode, {
                [styles.getPromotionCodeMob]: isMobile,
              })}
            >
              {" "}
              {SEND_ENUM.promotionCode}
            </div>
            <input
              type="text"
              value={promotionCode}
              disabled={newExchangeRate}
              onChange={handlePromotionCodeChange}
              autoComplete="off"
              className={cx(styles.promotionInput, {
                [styles.promotionInputMob]: isMobile,
                [styles.disabledInput]: newExchangeRate,
              })}
            ></input>
          </div>

          <ButtonRade
            customStyling={cx(styles.applyButton, {
              [styles.applyButtonMob]: isMobile,
              [styles.appliedButton]: secureToken && promotionCode,
            })}
            onClick={() => handlePromoCode(promotionCode, usdToInrExRate)}
            disabled={newExchangeRate}
          >
            {secureToken && promotionCode
              ? SEND_ENUM.applied
              : SEND_ENUM.applyButton}
          </ButtonRade>
        </div>
        {fetchingPrice && !usdToInrExRate && amountInUSD > 0 && (
          <div className={styles.USDConvertionRate}>
            Fetching USD to INR conversion rate
            <div className={styles.spinnerOverlay}>
              <div className={styles.spinnerContainer} />
            </div>
          </div>
        )}

        {amountInUSD > 0 && (
          <div className={styles.feeBreakDown}>{SEND_ENUM.feeBreakDown}</div>
        )}
        {amountInUSD > 0 && (
          <div className={styles.feeBreakDownContainerAmountPaid}>
            {" "}
            <div className={styles.bankTransferCharge}>Amount Paid (Due)</div>
            <div className={styles.bankTransferCharge}>
              <>$ {Number(amountInUSD).toFixed(MAX_DECIMAL_PLACE)}</>
            </div>
          </div>
        )}
        {amountInUSD > 0 && (
          <div className={styles.feeBreakDownContainerAmountPaid}>
            {" "}
            <div className={styles.bankTransferCharge}>Amount Exchanged</div>
            <div className={styles.bankTransferCharge}>
              <>
                ${" "}
                {processingCharge < 5
                  ? (amountInUSD - processingCharge).toFixed(MAX_DECIMAL_PLACE)
                  : (amountInUSD - 5).toFixed(MAX_DECIMAL_PLACE)}
              </>
            </div>
          </div>
        )}
        {amountInUSD > 0 && (
          <div className={styles.radeFeeContainer}>
            {" "}
            <div className={styles.payezyPlatformFees}>
              Payezy Platform Fees
            </div>
            <div className={styles.payezyPlatformFees}>
              <>$ 00.00</>
            </div>
          </div>
        )}

        {amountInINR > 0 && processingCharge && (
          <div className={styles.radeFeeContainerInclucingTax}>
            {" "}
            <div className={styles.bankTransferChargeTotal}>
              Effective mid-market rate
              <div className={styles.includingTaxes}>
                {" "}
                (inc. taxes and charges)
              </div>
            </div>
            <div className={styles.bankTransferChargeTotal}>
              <>₹{(amountInINR / amountInUSD).toFixed(MAX_DECIMAL_PLACE)}</>
            </div>
          </div>
        )}
        {errorForLogin && (
          <div className={styles.errorMessageForLogin}>{errorForLogin}</div>
        )}
        {errorMessage && (
          <div className={styles.errorMessageForLogin}>{errorMessage}</div>
        )}
        <div
          className={cx(styles.buttonContainer, {
            [styles.buttonContainerMob]: isMobile,
          })}
        >
          <RadeButton
            onClick={handleClickProceedButton}
            disabled={fetchingPrice || isLoading}
            customStyling={cx(
              styles.proceedButton,
              {
                [styles.proceedButtonMob]: isMobile,
              },
              {
                [styles.disabledButton]: fetchingPrice || isLoading, // Apply the disabledButton class when fetchingPrice is true
              }
            )}
          >
            <span>{SEND_ENUM.proceedButton}</span>
            {isLoading && (
              <div className={styles.proceedButtonSpinnerOverlay}>
                <div className={styles.proceedButtonSpinnerContainer} />
              </div>
            )}
          </RadeButton>
        </div>
      </div>
    </div>
  );
};

export default SendINR;
