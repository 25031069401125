import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../context";
import RecurringTransfersList from "./RecurringTransfersList";
import { getRecurringTransfers } from "../../api/recurringTransfers";
import styles from "./index.module.scss";
import {
  RECURRING_TRANSFER_ENUM,
  RecurringTransferStatus,
} from "../../enums/transferEnums";
import Card from "../../components/Card/Card";
import cx from "classnames";
import ButtonRade from "../../components/RadeButtons";

export default function RecurringTransfers({ usdToInrExRate }) {
  const navigate = useNavigate();
  const { isMobile } = useContext(AppContext);
  const [transfers, setTransfers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("ACTIVE");

  const fetchTransfers = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const data = await getRecurringTransfers();
      setTransfers(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTransfers();
  }, []);

  const handleStatusChange = async (updatedTransfers, targetTab) => {
    console.log("Starting handleStatusChange with:", {
      updatedTransfers,
      currentTransfers: transfers,
      currentActiveTab: activeTab,
      targetTab,
    });

    // If a specific tab is requested, switch to it immediately
    if (targetTab) {
      console.log(`Switching to explicitly requested tab: ${targetTab}`);
      setActiveTab(targetTab);
    }

    // Find the transfer that was updated by comparing scheduled transfers
    const updatedTransfer = updatedTransfers.find((updatedT) => {
      const currentT = transfers.find(
        (t) => t.transfer_id === updatedT.transfer_id
      );
      if (!currentT) return false;

      // Compare scheduled transfers to detect changes
      return updatedT.scheduled_transfers.some(
        (st, idx) => st.status !== currentT.scheduled_transfers[idx]?.status
      );
    });

    console.log("Found updated transfer:", updatedTransfer);

    if (updatedTransfer) {
      // Check if all scheduled transfers are cancelled
      const allScheduledCancelled = updatedTransfer.scheduled_transfers.every(
        (st) => st.status === "CANCELLED"
      );

      console.log("Transfer status check:", {
        transferId: updatedTransfer.transfer_id,
        allScheduledCancelled,
        mainStatus: updatedTransfer.status,
        scheduledStatuses: updatedTransfer.scheduled_transfers.map(
          (st) => st.status
        ),
      });

      // Update transfers state
      setTransfers((prevTransfers) =>
        prevTransfers.map((transfer) =>
          transfer.transfer_id === updatedTransfer.transfer_id
            ? {
                ...updatedTransfer,
                // Ensure status is CANCELLED if all scheduled transfers are cancelled
                status: allScheduledCancelled
                  ? RecurringTransferStatus.CANCELLED
                  : updatedTransfer.status,
              }
            : transfer
        )
      );

      // Only update tab if no specific tab was requested
      if (!targetTab) {
        if (allScheduledCancelled) {
          console.log("All transfers are cancelled, setting tab to CANCELLED");
          setActiveTab("CANCELLED");
        } else if (
          updatedTransfer.status === RecurringTransferStatus.COMPLETED
        ) {
          console.log("Transfer is completed, setting tab to COMPLETED");
          setActiveTab("COMPLETED");
        }
      }
    } else {
      // If no specific transfer was found to be updated, just update the state
      setTransfers(updatedTransfers);
    }
  };

  // Memoize the filtered transfers to prevent unnecessary recalculations
  const filteredTransfers = transfers.filter((transfer) => {
    if (activeTab === "ACTIVE") {
      return transfer.status === RecurringTransferStatus.ACTIVE;
    } else if (activeTab === "COMPLETED") {
      return transfer.status === RecurringTransferStatus.COMPLETED;
    } else if (activeTab === "CANCELLED") {
      return transfer.status === RecurringTransferStatus.CANCELLED;
    }
    return true;
  });
  // Calculate tab counts
  const tabCounts = {
    ACTIVE: transfers.filter((t) => t.status === RecurringTransferStatus.ACTIVE)
      .length,
    COMPLETED: transfers.filter(
      (t) => t.status === RecurringTransferStatus.COMPLETED
    ).length,
    CANCELLED: transfers.filter(
      (t) => t.status === RecurringTransferStatus.CANCELLED
    ).length,
  };
  console.log("transfers in main component", transfers);
  const CardTitle = () => (
    <div
      className={cx(styles.cardTitleContainer, {
        [styles.cardTitleContainerMob]: isMobile,
      })}
    >
      <div
        className={cx(styles.title, {
          [styles.titleMob]: isMobile,
        })}
      >
        {RECURRING_TRANSFER_ENUM.recurringTransfers}
      </div>
      {transfers.length > 0 && (
        <ButtonRade
          customStyling={cx(styles.createButton, {
            [styles.createButtonMob]: isMobile,
          })}
          onClick={() => navigate("/autoSend/create")}
        >
          New
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
        </ButtonRade>
      )}
    </div>
  );

  const CardBody = () => (
    <div
      className={cx(styles.recurringTransfersMainContainer, {
        [styles.recurringTransfersMainContainerMob]: isMobile,
      })}
    >
      {isLoading ? (
        <div className={styles.spinnerOverlay}>
          <div className={styles.spinnerContainer} />
        </div>
      ) : (
        <>
          <div
            className={cx(styles.tabs, {
              [styles.tabsMob]: isMobile,
            })}
          >
            <div
              onClick={() => setActiveTab("ACTIVE")}
              className={cx(styles.active, {
                [styles.selectedActive]: activeTab === "ACTIVE",
              })}
            >
              Active ({tabCounts.ACTIVE})
            </div>
            <div
              onClick={() => setActiveTab("COMPLETED")}
              className={cx(styles.completed, {
                [styles.selectedCompleted]: activeTab === "COMPLETED",
              })}
            >
              Completed ({tabCounts.COMPLETED})
            </div>
            <div
              onClick={() => setActiveTab("CANCELLED")}
              className={cx(styles.failed, {
                [styles.selectedFailed]: activeTab === "CANCELLED",
              })}
            >
              Cancelled ({tabCounts.CANCELLED})
            </div>
          </div>
          <div className={styles.tabContent}>
            <RecurringTransfersList
              transfers={filteredTransfers}
              onStatusChange={handleStatusChange}
              tabCounts={tabCounts}
              activeTab={activeTab}
              usdToInrExRate={usdToInrExRate}
            />
          </div>
          {error && <div className={styles.error}>{error}</div>}
        </>
      )}
    </div>
  );

  return <Card titleComponent={<CardTitle />}>{CardBody()}</Card>;
}
