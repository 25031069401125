import React, { useContext, useEffect, useState } from "react";
import styles from "./Card.module.scss";
import { AppContext } from "../../context";
import cx from "classnames";

const Card = ({ titleComponent, children }) => {
  const {
    isMobile,
    isNavBarClicked,
    setIsAnimationRequired,
    isAnimationRequired,
  } = useContext(AppContext);
  useEffect(() => {
    if (isNavBarClicked) {
      setIsAnimationRequired(true);
    }
    // Delaying the appearance of the card by 100ms for better effect
  }, []);

  return (
    <div
      className={cx(styles.rudMainDiv, {
        [styles.rudMainDivMob]: isMobile,
        [styles.appear]: isAnimationRequired,
      })}
    >
      <div className={styles.titleContainer}>{titleComponent}</div>
      <div className={styles.bodyContainer}>{children}</div>
    </div>
  );
};

export default Card;
