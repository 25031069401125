import React from "react";
import styles from "./index.module.scss";
import { POPUP_ENUM } from "../../enums/popUpEnum";

const SelectStateModal = ({ states, onSelectState }) => {
  return (
    <div className={styles.recipientsList}>
      <div className={styles.headerContainer}>{POPUP_ENUM.selectState}</div>
      {states.map((state) => (
        <div
          className={styles.recipientsName}
          key={state.code}
          onClick={() => onSelectState(state)}
        >
          {state.name}
        </div>
      ))}
    </div>
  );
};

export default SelectStateModal;
