import React from "react";
import ButtonRade from "../../components/RadeButtons";
import { PROFILE_ENUM } from "../../enums/profileEnum";
import styles from "../KycStatus/index.module.scss";
import cx from "classnames";
import { AppContext } from "../../context";
import { useContext } from "react";
import KYCRejected from "../../assets/KYCRejectedIcon.svg";
import { useNavigate } from "react-router-dom";
import Card from "../../components/Card/Card";

function KYCFrozen() {
  const { isMobile, setSupportFlowPageNumber, profileEmail } =
    useContext(AppContext);
  // status to store KYC status
  const navigate = useNavigate(); // Initialize the useNavigate hook

  // Function to handle button click and navigate to the support route
  const handleNavigateToSupport = () => {
    setSupportFlowPageNumber(5);
    navigate("/support"); // Replace '/support' with your desired route
  };
  const CardTitle = () => (
    <div
      className={cx(styles.profileButtonDiv, {
        [styles.profileButtonDivMob]: isMobile,
      })}
    >
      <div>{PROFILE_ENUM.profile}</div>
    </div>
  );
  return (
    <Card titleComponent={<CardTitle />}>
      <div
        className={cx(styles.KYCInProgressContainer, {
          [styles.KYCInProgressContainerWeb]: !isMobile,
          [styles.KYCInProgressContainerMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.statusContainer, {
            [styles.statusContainerWeb]: !isMobile,
            [styles.statusContainerMob]: isMobile,
          })}
        >
          {" "}
          <div
            className={cx(styles.statusOfKYC, {
              [styles.statusOfKYCWeb]: !isMobile,
              [styles.statusOfKYCMob]: isMobile,
            })}
          >
            {PROFILE_ENUM.statusOfKYC}
          </div>
          <div
            className={cx(styles.submittedAndWaiting, {
              [styles.submittedAndWaitingWeb]: !isMobile,
              [styles.submittedAndWaitingMob]: isMobile,
            })}
          >
            <>
              {" "}
              <div
                className={cx(styles.yourApplicationIsFrozen, {
                  [styles.yourApplicationIsFrozenWeb]: !isMobile,
                  [styles.yourApplicationIsFrozenMob]: isMobile,
                })}
              >
                {PROFILE_ENUM.yourKYCIsInFroxen}
              </div>
              <ButtonRade
                customStyling={styles.submitTicketButton}
                onClick={handleNavigateToSupport}
              >
                Submit Ticket
              </ButtonRade>
            </>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default KYCFrozen;
