export async function createCybridCustomer(
  profileEmail,
  db,
  idToken,
  collection,
  doc,
  updateDoc
) {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({
        type: "individual", // Set type as "individual"
      }),
    };

    const response = await fetch(
      `${process.env.REACT_APP_PAYEZY_SERVER_URI}/create-cybrid-customer/${profileEmail}`,
      requestOptions
    );

    if (response.ok) {
      const data = await response.json();
      // Ensure that the data contains the expected fields
      if (
        !data.customer_guid ||
        !data.usd_account_guid ||
        !data.usdc_account_guid
      ) {
        throw new Error(
          "Invalid response from Cybrid API, missing required fields."
        );
      }

      // Reference to user document
      const usersRef = collection(db, "userData");
      const userDocRef = doc(usersRef, profileEmail);

      // Update the userPii object to include KYC status and customer state
      const customer_state = "unverified";

      // Create an object to store customer details under cybridCustomerDetails
      const cybrid_customer_details = {
        customer_guid: data.customer_guid,
        usd_account_guid: data.usd_account_guid,
        usdc_account_guid: data.usdc_account_guid,
      };

      // Update the user document with the combined data
      await updateDoc(userDocRef, {
        cybrid_customer_details, // Store separate customer account details
        customer_state,
      });

      return {
        customer_guid: data.customer_guid,
        usd_account_guid: data.usd_account_guid,
        usdc_account_guid: data.usdc_account_guid,
      };
    } else {
      const errorMessage = await response.text();
      console.error("Error:", errorMessage);
      throw new Error(errorMessage); // Propagate error for better debugging
    }
  } catch (err) {
    console.error("Error:", err.message || err);
  }
}
