export async function plaidConnect(
  customerGUID,
  idToken,
  kind,
  extBankGuid = null
) {
  // Validate the customerGUID
  if (!customerGUID || !idToken) {
    throw new Error("customerGUID and idToken is required");
  }

  // Construct the URL with customerGUID
  const url = `${process.env.REACT_APP_PAYEZY_SERVER_URI}/create-plaid-workflow/${customerGUID}`;

  // Call verification API
  const verifyRequestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify({
      kind,
      ...(kind === "link_token_update" && { external_bank_guid: extBankGuid }),
    }),
  };

  const response = await fetch(url, verifyRequestOptions);

  if (!response.ok) {
    const errorData = await response.json();
    console.error("Verification failed", errorData);
    throw new Error(
      `Verification failed: ${errorData.message || response.statusText}`
    );
  }

  const verifyResponseData = await response.json();

  // Handle KYC Status

  return verifyResponseData;
}
