import { auth } from "../../firebase";

export const deleteExtBankAccount = async (accountGuid) => {
  try {
    if (!auth.currentUser) {
      throw new Error("User not authenticated");
    }

    const idToken = await auth.currentUser.getIdToken(true);

    const response = await fetch(
      `${process.env.REACT_APP_PAYEZY_SERVER_URI}/delete-external-bank-account/${accountGuid}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.error || "Failed to delete external bank account"
      );
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error deleting external bank account:", error);
    throw error;
  }
};
