import React, { useContext, useState, useRef } from "react";
import cx from "classnames";
import styles from "./index.module.scss";
import { AppContext } from "../../context";
import { SEND_ENUM } from "../../enums/sendEnum";
import { SUPPORT_ENUM } from "../../enums/supportEnum";
import ButtonRade from "../../components/RadeButtons";
import NextIcon from "../../assets/NextIcon";
import Card from "../../components/Card/Card";
import AttachFile from "./AttachFile";
import Modal from "react-modal";
import ReCAPTCHA from "react-google-recaptcha";
import { collection, addDoc, doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import FeedbackSentModal from "../FeedbackSentModal/FeedbackSentModal";
import mixpanel from "mixpanel-browser";

const FeedBack = ({
  feedbackMessage,
  setFeedbackMessage,
  userModeSelection,
  setUserModeSelection,
}) => {
  const { isMobile, profileEmail, setSupportFlowPageNumber } =
    useContext(AppContext);
  // state to set error message
  const [errorMessage, setErrorMessage] = useState("");
  //state to set the modal to open or close
  const [modalIsOpen, setModalIsOpen] = useState(false);
  //state to check if reCAPTCHA is verified or not
  const [isVerified, setIsVerified] = useState(false);

  // handle reCaptcha change
  const handleRecaptchaChange = (response) => {
    if (response) {
      setIsVerified(true);
    } else {
      setIsVerified(false);
    }
  };

  const handleMessageChange = (e) => {
    setErrorMessage("");
    const enteredValue = e.target.value;
    const limitedValue = enteredValue.slice(0, 1000); // Limit to 1000 characters
    setFeedbackMessage(limitedValue);
  };
  const inputRef = useRef(null);
  const handleDivClick = () => {
    // When the div is clicked, focus the input field
    inputRef.current.focus();
  };
  const handleSubmitProceedButton = async () => {
    if (!feedbackMessage) {
      setErrorMessage("Please enter your message!");
    } else if (!isVerified) {
      setErrorMessage("reCAPTCHA verification failed. Please try again.");
    } else {
      setErrorMessage("");
      setModalIsOpen(true);
      try {
        // Prepare the support ticket data
        const supportTicketData = {
          message: feedbackMessage,
          ticketType: userModeSelection,
          ticketStatus: "Submitted",
          ticketReply: "",
          createdTimestamp: new Date().getTime(),
        };

        // Create a reference to the user's profile using the email
        const profileRef = doc(db, "ticketCollection", profileEmail); // Assuming "profiles" is the collection name

        // Create the user's profile document if it doesn't exist
        await setDoc(profileRef, {}); // Create an empty document

        // Create a reference to the user's ticket list under their profile
        const ticketListRef = collection(profileRef, "ticketList");

        // Add the support ticket data to the ticket list
        await addDoc(ticketListRef, supportTicketData);

        console.log("Support ticket submitted successfully!");
        if (process.env.REACT_APP_DEPLOYMENT_MODE === "PRODUCTION") {
          mixpanel.track("Providing Feedback!", {
            ticketType: userModeSelection,
            messageLength: feedbackMessage.length,
            ticketStatus: "Submitted",
            createdTimestamp: supportTicketData.createdTimestamp, // Track the creation timestamp
          });
        }

        // Fetch updated tickets after submission
      } catch (error) {
        console.error("Error adding support ticket document: ", error);
        setErrorMessage(
          "Failed to submit the support ticket. Please try again."
        );
        setModalIsOpen(false);
      }
    }
  };

  const handleSubmitBackButton = () => {
    setSupportFlowPageNumber(1);
  };
  const CardTitle = () => (
    <div
      className={cx(styles.supportTicketTitle, {
        [styles.supportTicketTitleMob]: isMobile,
      })}
    >
      {SUPPORT_ENUM.feedbackOrBug}
    </div>
  );
  const CardBody = () => (
    <div
      className={cx(styles.failedTransactionMainContainer, {
        [styles.failedTransactionMainContainerMob]: isMobile,
      })}
    >
      <div
        className={cx(styles.fillInputField, {
          [styles.fillInputFieldMob]: isMobile,
        })}
        onClick={handleDivClick}
      >
        <div
          className={cx(styles.inputkey, {
            [styles.inputkeyMob]: isMobile,
          })}
        >
          {SUPPORT_ENUM.issueFacing}
        </div>
        <div
          className={cx(styles.inputValue, {
            [styles.inputValueMob]: isMobile,
          })}
        >
          <textarea
            type="text"
            ref={inputRef} // Attach the ref to the input element
            value={feedbackMessage}
            onChange={handleMessageChange}
            autoComplete="off"
            className={styles.message}
            rows={3}
          />
        </div>
      </div>
      <div
        className={cx(styles.fileAttachDiv, {
          [styles.fileAttachDivMob]: isMobile,
        })}
      >
        <AttachFile />
      </div>
      {/* reCAPTCHA v2 widget section*/}
      <div className={styles.reCaptchaContainer}>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={handleRecaptchaChange}
          theme="dark"
        />
      </div>

      <div
        className={cx(styles.errorMessage, {
          [styles.errorMessageMob]: isMobile,
        })}
      >
        {errorMessage}
      </div>
      <div
        className={cx(styles.proceedButtonDiv, {
          [styles.proceedButtonDivMob]: isMobile,
        })}
      >
        {/* Custom styled cancel button */}
        <ButtonRade
          outline
          customStyling={cx(styles.cancelButton, {
            [styles.cancelButtonMob]: isMobile,
          })}
          onClick={handleSubmitBackButton}
        >
          {SEND_ENUM.cancel}
        </ButtonRade>
        {/* Custom styled button for proceed */}
        <ButtonRade
          outline
          customStyling={cx(styles.proceedButton, {
            [styles.proceedButtonMob]: isMobile,
          })}
          onClick={() => {
            handleSubmitProceedButton();
          }}
        >
          {SUPPORT_ENUM.sendButton}
          <NextIcon />
        </ButtonRade>
      </div>
    </div>
  );

  return (
    <>
      <Card titleComponent={<CardTitle />}>{CardBody()}</Card>
      {/* Code for opening the modal */}
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <FeedbackSentModal
          setModalIsOpen={setModalIsOpen}
          setFeedbackMessage={setFeedbackMessage}
          setUserModeSelection={setUserModeSelection}
        />
      </Modal>
    </>
  );
};

export default FeedBack;
