import React, { useContext } from "react";
import styles from "./index.module.scss";
import { POPUP_ENUM } from "../../enums/popUpEnum";
import cx from "classnames";
import { AppContext } from "../../context";

const SignUpSuccessfulModal = () => {
  const { isMobile } = useContext(AppContext);

  const CardBody = () => (
    <div>
      <div
        className={cx(styles.serviceTicketSubmittedTitle, {
          [styles.serviceTicketSubmittedTitleMob]: isMobile,
        })}
      >
        {POPUP_ENUM.signUpSuccessful}
      </div>
      <div
        className={cx(styles.serviceTicketSubmittedContent, {
          [styles.serviceTicketSubmittedContentMob]: isMobile,
        })}
      >
        {POPUP_ENUM.welcomeToPayezy}
      </div>
    </div>
  );
  return CardBody();
};

export default SignUpSuccessfulModal;
