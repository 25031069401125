import React from "react";
import cx from "classnames";
import styles from "./index.module.scss";
import { SEND_ENUM } from "../../enums/sendEnum";
import ButtonRade from "../../components/RadeButtons";
import { useContext } from "react";
import { AppContext } from "../../context";
const LoginToContinue = ({
  setGoogleLoginPageNumber,
  setOnClickLoginButton,
}) => {
  const { isMobile } = useContext(AppContext);
  const handleClickLogin = () => {
    setGoogleLoginPageNumber(0);
    setOnClickLoginButton(true);
  };
  const CardBody = () => (
    <div
      className={cx(styles.loginToContinueContainer, {
        [styles.loginToContinueContainerMob]: isMobile,
      })}
    >
      <div
        className={cx(styles.loginContent, {
          [styles.loginContentMob]: isMobile,
        })}
      >
        {SEND_ENUM.pleaseLoginToContinue}
      </div>
      <div
        className={cx(styles.buttonContainer, {
          [styles.buttonContainerMob]: isMobile,
        })}
      >
        <ButtonRade
          className={cx(styles.loginButton, {
            [styles.loginButtonMob]: isMobile,
          })}
          onClick={handleClickLogin}
        >
          {SEND_ENUM.login}
        </ButtonRade>
      </div>
    </div>
  );
  return CardBody();
};

export default LoginToContinue;
