import React from "react";
import styles from "./index.module.scss";
import { POPUP_ENUM } from "../../enums/popUpEnum";

const SelectRecipientModal = ({ recipients, onSelectRecipient }) => {
  return (
    <div className={styles.recipientsList}>
      <div className={styles.headerContainer}>{POPUP_ENUM.selectNickname}</div>
      {recipients.map((recipient) => (
        <div
          className={styles.recipientsName}
          key={recipient.counterparty_pii.nick_name}
          onClick={() => onSelectRecipient(recipient)}
        >
          {recipient.counterparty_pii.nick_name}
        </div>
      ))}
    </div>
  );
};

export default SelectRecipientModal;
