import React, { useContext } from "react";
import cx from "classnames";
import { AppContext } from "../../context";
import googleIcon from "../../assets/googleIcon.svg";
import { auth, googleProvider, db, twitterProvider } from "../../firebase";
import {
  signInWithPopup,
  linkWithCredential,
  TwitterAuthProvider,
} from "firebase/auth";
import Card from "../../components/Card/Card";
import { SEND_ENUM } from "../../enums/sendEnum";
import { collection, getDoc, doc, setDoc } from "firebase/firestore";
import styles from "./index.module.scss";
import xIcon from "../../assets/xIcon.svg";
import mixpanel from "mixpanel-browser";

const LoginOptions = ({
  setProfileEmail,
  setIsLoggedIn,
  setGoogleLoginPageNumber,
  errorMessage,
  setErrorMessage,
  hideTwitterButton,
  setHideTwitterButton,
  appState,
  setClosedStateModalIsOpen,
  setOnClickLoginButton,
}) => {
  const { isMobile, setLoading, setStrId } = useContext(AppContext);

  // internal function for retrieving the stored pendingCredential in local storage
  const retrievePendingCred = () => {
    const pendingCredential = localStorage.getItem("pendingCredential");
    // remove localstorage item
    localStorage.removeItem("pendingCredential");
    return pendingCredential;
  };

  // Function to reset states after successful login
  const resetStates = () => {
    setErrorMessage("");
    setHideTwitterButton(false);
  };

  // Function that handles the google login
  const loginWithGoogle = async () => {
    localStorage.removeItem("verificationStatus");
    setLoading(true);
    try {
      // Attempt to sign in with Google popup
      const result = await signInWithPopup(auth, googleProvider);
      const { user } = result;
      let stripeCustomerId;
      // Check if user exists in userData collection in Firebase
      const usersRef = collection(db, "userData");
      const documentId = user.email; // Use email as the document ID
      const userDocRef = doc(usersRef, documentId);
      const documentSnapshot = await getDoc(userDocRef);

      // If document exists, proceed with existing user operations
      if (documentSnapshot.exists()) {
        console.log("User logged in already.");
        setProfileEmail(user.email);
        setIsLoggedIn(true);
        setGoogleLoginPageNumber(-1);
        setOnClickLoginButton(false);

        const userData = documentSnapshot.data();
        stripeCustomerId = userData.stripeCustomerId;
        setStrId(stripeCustomerId);
        if (process.env.REACT_APP_DEPLOYMENT_MODE === "PRODUCTION") {
          mixpanel.track("User logged in using Google!", { email: user.email });
        }
      } else {
        // New user case handling...
        console.log("New User");
        setProfileEmail(false);
        setIsLoggedIn(false);

        if (appState === "Closed") {
          setClosedStateModalIsOpen(true);
          setLoading(false);
          return;
        } else {
          setProfileEmail(user.email);

          const idToken = await auth.currentUser.getIdToken(true);
          try {
            const response = await fetch(
              `${process.env.REACT_APP_PAYEZY_SERVER_URI}/create-stripe-customer?userEmail=${user.email}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${idToken}`,
                },
              }
            );

            if (response.ok) {
              const customerData = await response.json();
              stripeCustomerId = customerData.customer.id;

              const userDocRef = doc(usersRef, user.email);
              const user_pii = {
                type: "individual",
                name: { first: "", middle: "", last: "" },
                address: {
                  street: "",
                  street2: "",
                  city: "",
                  subdivision: "",
                  postal_code: "",
                  country_code: "",
                },
                date_of_birth: "",
                phone_number: "",
                email_address: user.email,
              };

              await setDoc(userDocRef, {
                user_pii,
                transaction_details: {},
                beneficiary_details: {},
                cybrid_customer_details: {},
                customer_state: "Not Initialized",
                user_since: new Date().getTime(),
                display_name: user.displayName,
              });
              setStrId(stripeCustomerId);
              setIsLoggedIn(true);
              if (process.env.REACT_APP_DEPLOYMENT_MODE === "PRODUCTION") {
                mixpanel.track("User logged in using Google!", {
                  email: user.email,
                });
              }
              const requestOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${idToken}`,
                },
                body: JSON.stringify({
                  sender_details: {
                    email: user.email,
                  },
                }),
              };
              const welcomeEmailResponse = await fetch(
                `${process.env.REACT_APP_PAYEZY_SERVER_URI}/send-welcome-email`,
                requestOptions
              );
              if (!welcomeEmailResponse.ok) {
                console.error("Failed to send welcome email");
              }
            } else {
              throw new Error("Failed to create customer");
            }
          } catch (error) {
            console.error("Error creating customer:", error);
          }
          setLoading(false);
        }
      }

      // Handle any pending credentials from other providers
      let errorFromPendingCred = retrievePendingCred();
      const parsedErrorFromPendingCred = JSON.parse(errorFromPendingCred);
      if (errorFromPendingCred !== null) {
        let pendingCred;
        if (
          parsedErrorFromPendingCred.customData._tokenResponse.providerId ===
          "twitter.com"
        ) {
          pendingCred = TwitterAuthProvider.credentialFromError(
            parsedErrorFromPendingCred
          );
          await linkWithCredential(result.user, pendingCred);
        }
      }

      setLoading(false);
      resetStates();
    } catch (error) {
      if (error.code === "auth/wrong-password") {
        console.error("Wrong password provided.");
      } else {
        console.error("Login Failed:", error);
      }
      setLoading(false);
    }
  };

  // Login function using twitter firbase auth
  const loginWithTwitter = async () => {
    localStorage.removeItem("verificationStatus");
    setLoading(true);
    try {
      const result = await signInWithPopup(auth, twitterProvider);
      const { user } = result;
      // Declaring stripeCustomerId to set in App context once user is logged in.
      let stripeCustomerId;
      // Check if user exists in userData collection in Firebase
      const usersRef = collection(db, "userData");
      const documentId = user.email; // email for the logged-in user
      const userDocRef = doc(usersRef, documentId);
      const documentSnapshot = await getDoc(userDocRef);
      if (documentSnapshot.exists()) {
        // Email exist in Firestore
        console.log("User logged in already");
        setLoading(false);
        setProfileEmail(user.email);
        setIsLoggedIn(true);
        setGoogleLoginPageNumber(-1);
        setOnClickLoginButton(false);
        // The document with the specified email address exists
        const userData = documentSnapshot.data();
        // Getting user's stripeCustomerId
        stripeCustomerId = userData.stripeCustomerId;
        // Setting value for App context retrieval
        console.log("Returning user with strId ... ", stripeCustomerId);
        // Setting StrId state
        setStrId(stripeCustomerId);
        if (process.env.REACT_APP_DEPLOYMENT_MODE === "PRODUCTION") {
          mixpanel.track("User logged in using Twitter!", {
            email: user.email,
          });
        }
      } else {
        // Email does not exist in Firestore
        console.log("New user");
        setProfileEmail(false);
        setIsLoggedIn(false); // Ensure we mark them as not logged in yet
        // Check app state for new user
        if (appState === "Closed") {
          console.log("The app is currently closed. New users cannot log in.");
          setClosedStateModalIsOpen(true);
          setLoading(false);
          return; // Exit early to prevent further processing
        } else {
          // Email does not exist in Firestore
          console.log("Creating Stripe ID for new user");
          const idToken = await auth.currentUser.getIdToken(
            /* forceRefresh */ true
          );
          // New user to Payezy - Create stripe customer account
          try {
            const response = await fetch(
              `${process.env.REACT_APP_PAYEZY_SERVER_URI}/create-stripe-customer?userEmail=${user.email}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${idToken}`,
                },
              }
            );
            if (response.ok) {
              const customerData = await response.json();
              stripeCustomerId = customerData.customer.id;
              console.log("New user with stripe customer:", stripeCustomerId);
              const userDocRef = doc(usersRef, user.email); // Use email as the document ID
              // Initialize userPii object
              const user_pii = {
                type: "individual",
                name: {
                  first: "",
                  middle: "",
                  last: "",
                },
                address: {
                  street: "",
                  street2: "",
                  city: "",
                  subdivision: "",
                  postal_code: "",
                  country_code: "",
                },
                date_of_birth: "",
                phone_number: "",
                email_address: user.email, // Set email address here
              };

              // Create empty objects for transactionDetails, beneficiaryDetails, and cybridCustomerDetails
              const transaction_details = {};
              const beneficiary_details = {};
              const cybrid_customer_details = {};
              const customer_state = "Not Initialized";
              // Set the document in Firestore with userPii and the other details
              await setDoc(userDocRef, {
                user_pii,
                transaction_details,
                beneficiary_details,
                cybrid_customer_details,
                customer_state,
                user_since: new Date().getTime(),
                display_name: user.displayName,
              });
              setStrId(stripeCustomerId);
              setIsLoggedIn(true);
              if (process.env.REACT_APP_DEPLOYMENT_MODE === "PRODUCTION") {
                mixpanel.track("User logged in using Twitter!", {
                  email: user.email,
                });
              }
              const requestOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${idToken}`,
                },
                body: JSON.stringify({
                  sender_details: {
                    email: user.email,
                  },
                }),
              };
              const welcomeEmailResponse = await fetch(
                `${process.env.REACT_APP_PAYEZY_SERVER_URI}/send-welcome-email`,
                requestOptions
              );
              if (!welcomeEmailResponse.ok) {
                console.error("Failed to send welcome email");
              }
            } else {
              throw new Error("Failed to create customer");
            }
          } catch (error) {
            console.error("Error creating customer:", error);
          }
          setLoading(false);
        }
      }
      setLoading(false);
      resetStates(); // Reset states after successful login
    } catch (error) {
      console.log("errorMessage", error.message);
      setLoading(false);
      if (error.code === "auth/account-exists-with-different-credential") {
        // store pending cred into local storage
        localStorage.setItem("pendingCredential", JSON.stringify(error));
        setErrorMessage(
          "User account exists with a different credential. Please try logging in by using any other provider."
        );
        console.log("User account exists with a different credential");
        // Hide the Twitter button
        setHideTwitterButton(true);
      } else {
        console.log("Login Failed:", error);
      }
    }
  };
  const loginWithEmailAndPassword = async () => {
    localStorage.removeItem("verificationStatus");
    setGoogleLoginPageNumber(1);
  };
  const CardTitle = () => (
    <>
      <div
        className={cx(styles.loginTitle, {
          [styles.loginTitleMob]: isMobile,
        })}
      >
        {SEND_ENUM.login}
      </div>
    </>
  );

  const CardBody = () => (
    <div className={styles.bodyContainer}>
      {(appState === "Open" ||
        appState === "Restricted" ||
        appState === "Pause/Maintenance") && (
        <div className={styles.emailPswdButtonContainer}>
          <button
            className={cx(styles.continueWithGoogleButton, {
              [styles.continueWithGoogleButtonMob]: isMobile,
            })}
            onClick={() => loginWithEmailAndPassword()}
          >
            {SEND_ENUM.emailAndpswdLogin}
          </button>
        </div>
      )}
      {(appState === "Open" ||
        appState === "Restricted" ||
        appState === "Pause/Maintenance") && (
        <div className={styles.orContainer}>{SEND_ENUM.or}</div>
      )}
      <div className={styles.loginUsingGoogleAccount}>
        {SEND_ENUM.loginWithSocialAccounts}
      </div>

      <div className={styles.continueWithGoogleButtonContainer}>
        {/* Button to login with Google */}
        <button
          className={cx(styles.continueWithGoogleButton, {
            [styles.continueWithGoogleButtonMob]: isMobile,
          })}
          onClick={() => loginWithGoogle()}
        >
          <img src={googleIcon} alt="google" className={styles.googleIcon} />
          {SEND_ENUM.continueWithGoogle}
        </button>

        {/* Button to login with Twitter */}
        {!hideTwitterButton && (
          <button
            className={cx(styles.continueWithGoogleButton, {
              [styles.continueWithGoogleButtonMob]: isMobile,
            })}
            onClick={() => loginWithTwitter()}
          >
            {SEND_ENUM.continueWithTwitter}
            <img src={xIcon} alt="xIcon" className={styles.xIcon} />
          </button>
        )}

        {/* Display error message if there is one */}
        {errorMessage && (
          <div className={styles.errorMessage}>{errorMessage}</div>
        )}
      </div>
    </div>
  );

  return (
    <div>
      <div
        className={cx(styles.loginWithGoogleContainer, {
          [styles.loginWithGoogleContainerMob]: isMobile,
        })}
      >
        <Card titleComponent={<CardTitle />}>{CardBody()}</Card>
      </div>
    </div>
  );
};

export default LoginOptions;
