import React from "react";
import ButtonRade from "../../components/RadeButtons";
import { SEND_ENUM } from "../../enums/sendEnum";
import styles from "./index.module.scss";

const SupporetdBankAccount = ({ setModalIsOpen }) => {
  //Function to close the Modal.
  const handleOnClickClose = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <div className={styles.popUpContent}>
        {SEND_ENUM.supportedBankAccountModalContent}
      </div>
      <div className={styles.popUpButtonDiv}>
        <ButtonRade
          outline
          customStyling={styles.cancelButton}
          onClick={handleOnClickClose}
        >
          {SEND_ENUM.closeButton}
        </ButtonRade>
      </div>
    </div>
  );
};

export default SupporetdBankAccount;
