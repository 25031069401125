import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import cx from "classnames";
import { AppContext } from "../../context";
import { POPUP_ENUM } from "../../enums/popUpEnum";
import ButtonRade from "../../components/RadeButtons";
import styles from "./index.module.scss";

const AcceptsTermsToContinuePopUp = ({
  setModalIsOpen,
  setSendFlowPageNumber,
  sendFlowPageNumber,
  isLoading,
  onNextFromRecurring,
}) => {
  const { isMobile } = useContext(AppContext);
  const location = useLocation();
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedError, setIsCheckedError] = useState(false);
  const isRecurringTransfersCreateRoute = () =>
    location.pathname === "/autoSend/create";
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setIsCheckedError(false);
  };

  const handleClickCancelButton = () => {
    setModalIsOpen(false);
  };

  // TODO: create counter party only if new recipient
  const handleClickContinueButton = async () => {
    if (!isChecked) {
      setIsCheckedError(true);
    } else {
      setIsCheckedError(false);
      setModalIsOpen(false);
      if (isRecurringTransfersCreateRoute && onNextFromRecurring) {
        onNextFromRecurring();
      } else {
        setSendFlowPageNumber(sendFlowPageNumber + 1);
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <div className={styles.spinnerOverlay}>
          <div className={styles.spinnerContainer} />
        </div>
      ) : (
        <>
          <div
            className={cx(styles.acceptTerms, {
              [styles.acceptTermsMob]: isMobile,
            })}
          >
            {POPUP_ENUM.acceptTerms}
          </div>
          <div
            className={cx(styles.checklist, {
              [styles.checklistMob]: isMobile,
            })}
          >
            <div>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                className={cx(styles["checkbox-input"], {
                  [styles.checked]: isChecked, // Apply the animation class when the checkbox is checked
                })}
              />
            </div>
            <div className={styles["checkbox-label"]}>
              I agree to the{" "}
              <a
                href="https://docs.payezy.io/platform/terms-of-use"
                target="_blank"
                rel="noreferrer"
                className={styles.termsOfUse}
              >
                Terms of Use,
              </a>{" "}
              <a
                href="https://docs.payezy.io/platform/privacy-policy"
                target="_blank"
                rel="noreferrer"
                className={styles.privacyPolicy}
              >
                Privacy Policy
              </a>{" "}
              and{" "}
              <a
                href="https://docs.payezy.io/platform/partner-user-agreement"
                target="_blank"
                rel="noreferrer"
                className={styles.privacyPolicy}
              >
                Partner Agreement.
              </a>
            </div>
          </div>
          <div
            className={cx(styles.buttonContainer, {
              [styles.buttonContainerMob]: isMobile,
            })}
          >
            <ButtonRade
              className={cx(styles.cancelButton, {
                [styles.cancelButtonMob]: isMobile,
              })}
              onClick={handleClickCancelButton}
            >
              {POPUP_ENUM.cancel}
            </ButtonRade>
            <ButtonRade
              className={cx(styles.continueButton, {
                [styles.continueButtonMob]: isMobile,
              })}
              onClick={handleClickContinueButton}
              disabled={isLoading}
            >
              {POPUP_ENUM.continue}
            </ButtonRade>
          </div>
          {isCheckedError && (
            <div
              className={cx(styles.errorMessage, {
                [styles.errorMessageMob]: isMobile,
              })}
            >
              {POPUP_ENUM.pleaseAgreeToContinue}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AcceptsTermsToContinuePopUp;
