import React from "react";
import ButtonRade from "../../components/RadeButtons";
import styles from "./TransferActionConfirmation.module.scss";
import { RECURRING_TRANSFER_ENUM } from "../../enums/transferEnums";

const CancelTransferConfirm = ({
  setModalIsOpen,
  handleCancelTransfer,
  event,
  transferId,
  index,
  scheduledDate,
  setModalPageNumber,
}) => {
  const handleOnClickConfirm = async () => {
    setModalPageNumber(3);
    await handleCancelTransfer(event, transferId, index, scheduledDate);
  };

  //Function to close the Modal.
  const handleOnClickCancel = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <div className={styles.popUpContent}>
        {RECURRING_TRANSFER_ENUM.confirmCancelTransfer}
      </div>
      <div className={styles.popUpButtonDiv}>
        <ButtonRade
          outline
          customStyling={styles.cancelButton}
          onClick={handleOnClickCancel}
        >
          Cancel
        </ButtonRade>
        <ButtonRade
          outline
          customStyling={styles.continueButton}
          onClick={handleOnClickConfirm}
        >
          Confirm
        </ButtonRade>
      </div>
    </div>
  );
};

export default CancelTransferConfirm;
