import React, { useEffect } from "react";
import cx from "classnames";
import { AppContext } from "../../context";
import { useContext, useState } from "react";
import styles from "./index.module.scss";
import ButtonRade from "../../components/RadeButtons";
import Profile from "../Profile/Profile";
import { PROFILE_ENUM } from "../../enums/profileEnum";
import KYCCompleted from "../KycStatus/KycStatus";
import { collection, doc, getDoc, updateDoc } from "firebase/firestore";
import Modal from "react-modal";

import { db } from "../../firebase";
import { auth } from "../../firebase";
import { validateForm } from "../../api/utilities/formValidation";
// import { verifyCustomer } from "../../api/utilities/verifyCustomer";
import { createCybridCustomer } from "../../api/utilities/createCybridCustomer.js";
import { verifyCybridCustomer } from "../../api/utilities/verifyCybridCustomer";
import ConfirmUpdateProfile from "../ConfirmUpdateProfile/ConfirmUpdateProfile.js";
import mixpanel from "mixpanel-browser";
const KYC = ({
  profileEmail,
  KYCPageNumber,
  setKYCPageNumber,
  showResumeButton,
  setShowResumeButton,
  setShow,
  personaInquiryId,
  setPersonaInquiryId,
  handleRefreshKYCStatus,
  underReview,
}) => {
  const { isMobile, setUserData } = useContext(AppContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  //states to store customer details
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("+1");
  const [emailAddress, setEmailAddress] = useState("");
  const [street, setStreet] = useState("");
  const [street2, setStreet2] = useState("");
  const [city, setCity] = useState("");
  const [subdivision, setSubdivision] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [state, setState] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [message, setMessage] = useState("");
  const [reSubmitKyc, setReSubmitKyc] = useState("");
  // Validation states
  const [errors, setErrors] = useState({});

  //Function that handle start KYc button
  const handleClickKYCAMLButton = async () => {
    setModalIsOpen(true);
    // setCustomerState("unverified");

    // setKYCPageNumber(1);
  };
  const handleCreateCybridCustomer = async () => {
    if (process.env.REACT_APP_DEPLOYMENT_MODE === "PRODUCTION") {
      mixpanel.track("KYC Submission!");
    }
    try {
      // Build customer data with conditional fields

      // Fetch id token from auth
      const idToken = await auth.currentUser.getIdToken(true);

      // Fetch call with requestOptions
      const { customer_guid } = await createCybridCustomer(
        profileEmail,
        db,
        idToken,
        collection,
        doc,
        updateDoc
      );

      // If customer_guid is present
      if (customer_guid) {
        try {
          // First call to verifyCybridCustomer
          const verifyCustomerResponse = await verifyCybridCustomer(
            customer_guid,
            idToken
          );
          console.log(
            "personaInquiryId in first try",
            verifyCustomerResponse.personaInquiryId
          );
          console.log(
            "Is personaInquiryId null in first try",
            verifyCustomerResponse.personaInquiryId === null
          );
          // Check if the response is null
          if (verifyCustomerResponse.personaInquiryId === null) {
            // Wait for 500 ms before retrying
            await new Promise((resolve) => setTimeout(resolve, 500));
            console.log(
              "personaInquiryId is null in first try, retry calling verifyCybridCustomer.."
            );
            // Retry calling verifyCybridCustomer
            const retryCustomerResponse = await verifyCybridCustomer(
              customer_guid,
              idToken
            );
            console.log(
              "personaInquiryId in second try",
              retryCustomerResponse.personaInquiryId
            );
            // Check retry response and handle redirection
            if (retryCustomerResponse.personaInquiryId) {
              setShowResumeButton(true);
              const redirectUrl = `https://inquiry.withpersona.com/inquiry?inquiry-id=${retryCustomerResponse.personaInquiryId}&redirect-uri=${process.env.REACT_APP_KYC_REDIRECT_URI}`;
              window.open(redirectUrl, "_self");

              return true; // Successful verification after retry
            } else {
              console.error("Second attempt also returned null.");
              return false; // Failed after retry
            }
          } else {
            setShowResumeButton(true);
            const redirectUrl = `https://inquiry.withpersona.com/inquiry?inquiry-id=${verifyCustomerResponse.personaInquiryId}&redirect-uri=${process.env.REACT_APP_KYC_REDIRECT_URI}`;
            window.open(redirectUrl, "_self");

            return true; // Successful initial response
          }
        } catch (error) {
          console.error("KYC initiation failed:", error);
          return false; // KYC initiation failed
        }
      } else {
        console.error("customer_guid is not provided.");
        return false; // customer_guid is not available
      }
    } catch (error) {
      console.error("Error creating customer:", error);
      setMessage("Failed to create customer. Please try again.");
      return false; // Error occurred while creating customer
    }
  };

  // Function to validate the phone number
  const handlePhoneNumberChange = (e) => {
    const enteredValue = e.target.value;

    setPhoneNumber(enteredValue);
  };

  // Function to handle middle name input field
  const handleChangeInputMiddleName = (e) => {
    setMiddleName(e.target.value);
  };

  // Function to handle last name input field
  const handleChangeInputLastName = (e) => {
    setLastName(e.target.value);
  };

  // function to handle name input field
  const handleChangeInputFieldName = (e) => {
    setFirstName(e.target.value);
  };

  // function to handle email input field
  const handleChangeInputFieldEmail = (e) => {
    setEmailAddress(e.target.value);
  };

  // Function to handle street input field
  const handleChangeInputStreet = (e) => {
    setStreet(e.target.value);
  };

  // Function to handle street2 input field
  const handleChangeInputStreet2 = (e) => {
    setStreet2(e.target.value);
  };

  // Function to handle city input field
  const handleChangeInputCity = (e) => {
    setCity(e.target.value);
  };

  // Function to handle subdivision input field
  const handleChangeInputSubdivision = (e) => {
    setSubdivision(e.target.value);
  };

  // Function to handle postalCode input field
  const handleChangeInputPostalCode = (e) => {
    setPostalCode(e.target.value);
  };

  // Function to handle countryCode input field
  const handleChangeInputCountryCode = (e) => {
    errors.countryCode = "";
    setCountryCode(e.target.value);
  };

  // function to update the profile details and open the popup
  const handlesubmitUpdateProfile = () => {
    handleSubmit();
  };

  //Fetch user data from firebase

  //Fetch user KYC data

  //Function that handles DOB input field
  const handleDateOfBirthChange = (e) => {
    const inputDate = e.target.value;
    setDateOfBirth(inputDate);
  };

  // Validation function
  const validateUserDataForm = () => {
    const customerData = {
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      street: street,
      street2: street2,
      city: city,
      subdivision: subdivision,
      postalCode: postalCode,
      dateOfBirth: dateOfBirth,
      phoneNumber: phoneNumber,
      emailAddress: emailAddress,
    };
    const validationResult = validateForm(customerData);
    if (validationResult === true) {
      console.log("Form is valid!");
    } else {
      console.log("Form errors:", validationResult);
    }
  };
  //Function to handle resubmitting KYC
  const handleSubmitResubmitKYC = async () => {
    try {
      // Build customer data with conditional fields
      // let customerData = {
      //   type: "individual",
      //   name: {
      //     first: firstName,
      //     ...(middleName && { middle: middleName }), // Only add if middleName is not empty
      //     last: lastName,
      //   },
      //   address: {
      //     street: street,
      //     ...(street2 && { street2: street2 }), // Only add if street2 is not empty
      //     city: city,
      //     subdivision: subdivision,
      //     postal_code: postalCode,
      //     country_code: "US",
      //   },
      //   date_of_birth: dateOfBirth,
      //   phone_number: phoneNumber.startsWith("+1")
      //     ? phoneNumber
      //     : "+1" + phoneNumber,
      //   email_address: profileEmail,
      // };

      // Check if currentUser is available
      if (!auth.currentUser) {
        console.warn("No user is currently logged in.");
        return false; // Exit early if no user is authenticated
      }

      // Fetch id token from auth
      const idToken = await auth.currentUser.getIdToken(true);

      // Fetch call with requestOptions
      const { customer_guid } = await createCybridCustomer(
        profileEmail,
        db,
        idToken,
        collection,
        doc,
        // customerData,
        updateDoc
      );

      // If customer_guid is present
      if (customer_guid) {
        try {
          // First call to verifyCybridCustomer
          const verifyCustomerResponse = await verifyCybridCustomer(
            customer_guid,
            idToken
          );
          console.log(
            "verifyCustomerResponse",
            verifyCustomerResponse.personaInquiryId === null
          );

          // Check if the response is null
          if (verifyCustomerResponse.personaInquiryId === null) {
            // Wait for 500 ms before retrying
            await new Promise((resolve) => setTimeout(resolve, 500));

            // Retry calling verifyCybridCustomer
            const retryCustomerResponse = await verifyCybridCustomer(
              customer_guid,
              idToken
            );

            console.log("retryCustomerResponse", retryCustomerResponse);
            console.log(
              "personaInquiryId",
              retryCustomerResponse.personaInquiryId
            );
            // Check retry response and handle redirection
            if (retryCustomerResponse.personaInquiryId) {
              setShowResumeButton(true);
              setPersonaInquiryId(retryCustomerResponse.personaInquiryId);
              const redirectUrl = `https://inquiry.withpersona.com/inquiry?inquiry-id=${retryCustomerResponse.personaInquiryId}&redirect-uri=${process.env.REACT_APP_KYC_REDIRECT_URI}`;
              window.open(redirectUrl, "_self");
              return true; // Successful verification after retry
            } else {
              console.error("Second attempt also returned null.");
              return false; // Failed after retry
            }
          } else {
            setShowResumeButton(true);
            setPersonaInquiryId(verifyCustomerResponse.personaInquiryId);
            // Handle successful initial response
            const redirectUrl = `https://inquiry.withpersona.com/inquiry?inquiry-id=${verifyCustomerResponse.personaInquiryId}&redirect-uri=${process.env.REACT_APP_KYC_REDIRECT_URI}`;
            window.open(redirectUrl, "_self");
            return true; // Successful initial response
          }
        } catch (error) {
          console.error("KYC initiation failed:", error);
          return false; // KYC initiation failed
        }
      } else {
        console.error("customer_guid is not provided.");
        return false; // customer_guid is not available
      }
    } catch (error) {
      console.error("Error creating customer:", error);
      setMessage("Failed to create customer. Please try again.");
      return false; // Error occurred while creating customer
    }
  };

  // Handler function for form submission
  const handleSubmit = async () => {
    try {
      // Build customer data with conditional fields
      let customerData = {
        type: "individual",
        name: {
          first: firstName,
          ...(middleName && { middle: middleName }), // Only add if middleName is not empty
          last: lastName,
        },
        address: {
          street: street,
          ...(street2 && { street2: street2 }), // Only add if street2 is not empty
          city: city,
          subdivision: subdivision,
          postal_code: postalCode,
          country_code: "US",
        },
        date_of_birth: dateOfBirth,
        phone_number: phoneNumber.startsWith("+1")
          ? phoneNumber
          : "+1" + phoneNumber,
        email_address: emailAddress,
      };

      // Check if currentUser is available
      if (!auth.currentUser) {
        console.warn("No user is currently logged in.");
        return false; // Exit early if no user is authenticated
      }

      // Fetch id token from auth
      const idToken = await auth.currentUser.getIdToken(true);

      // Fetch call with requestOptions
      const { customer_guid } = await createCybridCustomer(
        profileEmail,
        db,
        idToken,
        collection,
        doc,
        customerData,
        updateDoc
      );

      // If customer_guid is present
      if (customer_guid) {
        try {
          // First call to verifyCybridCustomer
          const verifyCustomerResponse = await verifyCybridCustomer(
            customer_guid,
            idToken
          );

          // Check if the response is null
          if (verifyCustomerResponse.personaInquiryId === null) {
            // Wait for 500 ms before retrying
            await new Promise((resolve) => setTimeout(resolve, 500));

            // Retry calling verifyCybridCustomer
            const retryCustomerResponse = await verifyCybridCustomer(
              customer_guid,
              idToken
            );

            // Check retry response and handle redirection
            if (retryCustomerResponse) {
              setShowResumeButton(true);
              const redirectUrl = `https://inquiry.withpersona.com/inquiry?inquiry-id=${retryCustomerResponse.personaInquiryId}&redirect-uri=${process.env.REACT_APP_KYC_REDIRECT_URI}`;
              window.open(redirectUrl, "_self");
              return true; // Successful verification after retry
            } else {
              console.error("Second attempt also returned null.");
              return false; // Failed after retry
            }
          } else {
            setShowResumeButton(true);
            const redirectUrl = `https://inquiry.withpersona.com/inquiry?inquiry-id=${verifyCustomerResponse.personaInquiryId}&redirect-uri=${process.env.REACT_APP_KYC_REDIRECT_URI}`;
            window.open(redirectUrl, "_self");
            return true; // Successful initial response
          }
        } catch (error) {
          console.error("KYC initiation failed:", error);
          return false; // KYC initiation failed
        }
      } else {
        console.error("customer_guid is not provided.");
        return false; // customer_guid is not available
      }
    } catch (error) {
      console.error("Error creating customer:", error);
      setMessage("Failed to create customer. Please try again.");
      return false; // Error occurred while creating customer
    }
  };

  //Card Body for Card component
  const CardBody = () => (
    <div
      className={cx(styles.KYCMainDiv, {
        [styles.KYCMainDivWeb]: !isMobile,
        [styles.KYCMainDivMob]: isMobile,
      })}
    >
      <div
        className={cx(styles.KYCDiv, {
          [styles.KYCDivWeb]: !isMobile,
          [styles.KYCDivMob]: isMobile,
        })}
      >
        {PROFILE_ENUM.profileIsNotverified}
      </div>

      <div
        className={cx(styles.buttonContainer, {
          [styles.buttonContainerMob]: isMobile,
        })}
      >
        <ButtonRade
          outline
          customStyling={cx(styles.KYCAMLButton, {
            [styles.KYCAMLButtonWeb]: !isMobile,
            [styles.KYCAMLButtonMob]: isMobile,
          })}
          onClick={handleClickKYCAMLButton}
        >
          {PROFILE_ENUM.KycButton}
        </ButtonRade>
      </div>
    </div>
  );
  const EmptyCardBody = () => (
    <div
      className={cx(styles.KYCMainDiv, {
        [styles.KYCMainDivWeb]: !isMobile,
        [styles.KYCMainDivMob]: isMobile,
      })}
    >
      <div
        className={cx(styles.KYCDiv, {
          [styles.KYCDivWeb]: !isMobile,
          [styles.KYCDivMob]: isMobile,
        })}
      ></div>

      <div
        className={cx(styles.buttonContainer, {
          [styles.buttonContainerMob]: isMobile,
        })}
      ></div>
    </div>
  );

  return (
    <>
      {(() => {
        switch (KYCPageNumber) {
          case 0:
            return (
              <Profile
                setPersonaInquiryId={setPersonaInquiryId}
                state={state}
                emailAddress={emailAddress}
                setEmailAddress={setEmailAddress}
                setDateOfBirth={setDateOfBirth}
                handleSubmitResubmitKYC={handleSubmitResubmitKYC}
                setReSubmitKyc={setReSubmitKyc}
                setErrors={setErrors}
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
                firstName={firstName}
                middleName={middleName}
                lastName={lastName}
                phoneNumber={phoneNumber}
                street={street}
                street2={street2}
                city={city}
                subdivision={subdivision}
                postalCode={postalCode}
                countryCode={countryCode}
                dateOfBirth={dateOfBirth}
                message={message}
                reSubmitKyc={reSubmitKyc}
                handlePhoneNumberChange={handlePhoneNumberChange}
                handleChangeInputLastName={handleChangeInputLastName}
                handleChangeInputFieldName={handleChangeInputFieldName}
                handleChangeInputFieldEmail={handleChangeInputFieldEmail}
                handlesubmitUpdateProfile={handlesubmitUpdateProfile}
                handleDateOfBirthChange={handleDateOfBirthChange}
                validateForm={validateUserDataForm}
                handleSubmit={handleSubmit}
                errors={errors}
                setKYCPageNumber={setKYCPageNumber}
                handleChangeInputMiddleName={handleChangeInputMiddleName}
                handleChangeInputStreet={handleChangeInputStreet}
                handleChangeInputStreet2={handleChangeInputStreet2}
                handleChangeInputCity={handleChangeInputCity}
                handleChangeInputSubdivision={handleChangeInputSubdivision}
                handleChangeInputPostalCode={handleChangeInputPostalCode}
                handleChangeInputCountryCode={handleChangeInputCountryCode}
                setSubdivision={setSubdivision}
              />
            );
          case 1:
            return (
              <KYCCompleted
                handleRefreshKYCStatus={handleRefreshKYCStatus}
                setPersonaInquiryId={setPersonaInquiryId}
                dateOfBirth={dateOfBirth}
                emailAddress={emailAddress}
                setFirstName={setFirstName}
                setMiddleName={setMiddleName}
                setLastName={setLastName}
                setPhoneNumber={setPhoneNumber}
                setEmailAddress={setEmailAddress}
                setStreet={setStreet}
                setStreet2={setStreet2}
                setCity={setCity}
                setSubdivision={setSubdivision}
                setPostalCode={setPostalCode}
                setCountryCode={setCountryCode}
                setDateOfBirth={setDateOfBirth}
                setReSubmitKyc={setReSubmitKyc}
                handleDateOfBirthChange={handleDateOfBirthChange}
                handleChangeInputStreet={handleChangeInputStreet}
                handleChangeInputStreet2={handleChangeInputStreet2}
                handleChangeInputCity={handleChangeInputCity}
                handleChangeInputSubdivision={handleChangeInputSubdivision}
                handleChangeInputPostalCode={handleChangeInputPostalCode}
                handleChangeInputCountryCode={handleChangeInputCountryCode}
                handleSubmit={handleSubmit}
                handleSubmitResubmitKYC={handleSubmitResubmitKYC}
                profileEmail={profileEmail}
                firstName={firstName}
                middleName={middleName}
                lastName={lastName}
                phoneNumber={phoneNumber}
                street={street}
                street2={street2}
                city={city}
                subdivision={subdivision}
                postalCode={postalCode}
                countryCode={countryCode}
                message={message}
                reSubmitKyc={reSubmitKyc}
                handlePhoneNumberChange={handlePhoneNumberChange}
                handleChangeInputLastName={handleChangeInputLastName}
                handleChangeInputFieldName={handleChangeInputFieldName}
                handleChangeInputFieldEmail={handleChangeInputFieldEmail}
                handlesubmitUpdateProfile={handlesubmitUpdateProfile}
                validateForm={validateUserDataForm}
                setResubmitKyc={setReSubmitKyc}
                errors={errors}
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
                setKYCPageNumber={setKYCPageNumber}
                setErrors={setErrors}
                underReview={underReview}
              />
            );
          case 2:
            return <CardBody />;
          default:
            return <EmptyCardBody />;
        }
      })()}
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <ConfirmUpdateProfile
          personaInquiryId={personaInquiryId}
          handleCreateCybridCustomer={handleCreateCybridCustomer}
          handleSubmitResubmitKYC={handleSubmitResubmitKYC}
          handleSubmit={handleSubmit}
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          setKYCPageNumber={setKYCPageNumber}
          handlesubmitUpdateProfile={handlesubmitUpdateProfile}
        />
      </Modal>
    </>
  );
};

export default KYC;
