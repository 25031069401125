import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import cx from "classnames";
import { useMediaQuery } from "react-responsive";
import SendINR from "./pages/SendINRLandingPage/SendINRLandingPage";
import FooterMain from "./components/FooterMain/FooterMain";
import LoginOptions from "./pages/LoginOptions/LoginOptions";
import { AppContext } from "./context";
import Header from "./components/header/header";
import { NavLink, useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Routes, Route, Navigate } from "react-router-dom";
import NavBarMobile from "./pages/Mobile";
import Spinner from "./components/Spinner/Spinner";
import { doc, collection, getDoc, updateDoc } from "firebase/firestore";
import KycLandingPage from "../src/pages/KycLandingPage/KycLandingPage";
import styles from "./styles/app.module.scss";
import { auth, db } from "./firebase";
import EmailPswdSignIn from "./pages/EmailPswdSignIn/EmailPswdSignIn";
import SupportLandingPage from "./pages/Support/SupportLandingPage";
import LoginToContinue from "./pages/LoginToContinue/LoginToContinue";
import { Helmet } from "react-helmet";
import PasswordReset from "./pages/PasswordReset/PasswordReset";
import Modal from "react-modal";
import ClosedStateModal from "./pages/ClosedStateModal/ClosedStateModal";
import { getCybridCustomerState } from "./api/utilities/getCybridCustomerState";
import KYCFrozen from "./pages/KycLandingPage/KYCFrozen";
import TransferHistory from "./pages/TransferHistory";
import BankAccountsMain from "./pages/BankAccountsMain/BankAccountsMain";
import mixpanel from "mixpanel-browser";
import { getCybridCustomer } from "./api/utilities/getCybridCustomer";
import { SEND_ENUM } from "./enums/sendEnum";
import RecurringTransfers from "./pages/RecurringTransfers";
import CreateRecurringTransfer from "./pages/RecurringTransfers/CreateRecurringTransfer";
// Initialize Mixpanel only if enabled in environment variables
if (process.env.REACT_APP_DEPLOYMENT_MODE === "PRODUCTION") {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_API, {
    debug: true,
  });
}
const HUNDRED = 100;
function App() {
  // Stripe Integration
  const [stripePromise, setStripePromise] = useState(null); // Stripe Promise from stripe server
  const [usdToInrExRate, setUsdToInrExRate] = useState(null); // Stripe Promise from stripe server
  const [loading, setLoading] = useState(false); // Loading state
  const [isLoggedIn, setIsLoggedIn] = useState(null); //state for implementing protected route
  const [googleLoginPageNumber, setGoogleLoginPageNumber] = useState(false); //state for storing login with google button click
  const [sendFlowPageNumber, setSendFlowPageNumber] = useState(0); //state for storing USD send flow
  const [onClickLoginButton, setOnClickLoginButton] = useState(false); //state for storing the onclick even of login button
  const [profileEmail, setProfileEmail] = useState(false); // State for profile email
  const [amountInUSD, setAmountInUSD] = useState(null); // State to store amount in USD
  const [amountInINR, setAmountInINR] = useState(null); // State to store amount in INR
  //state to set the user selection based on they enter Pay Eth or Get Rud
  const [userSelectionMode, setUserSelectionMode] = useState(
    SEND_ENUM.usdEnterMode
  );
  const [recipientId, setRecipientId] = useState(false); // State to recipient account ID
  const [strId, setStrId] = useState(null); //state to store the stripe id
  const [txId, setTxId] = useState(""); //state to store the transaction id
  const [sessionId, setSessionId] = useState(null); //state to store the session id
  const [transferHistoryData, setTranferHistoryData] = useState([]); //  state for storing transfer history data
  const [txData, setTxData] = useState(null);
  const [customerState, setCustomerState] = useState("Not Initialized");
  const [showResumeButton, setShowResumeButton] = useState(null);
  const location = useLocation(); // variable for page navigation
  // State for setting the kyc flow page number
  const [KYCPageNumber, setKYCPageNumber] = useState(false);
  const [KycStatus, setKycStatus] = useState(null);
  // state to set the error message if there is any at the time of login
  const [errorMessage, setErrorMessage] = useState("");
  // state to hide the twitter login button if there is error
  const [hideTwitterButton, setHideTwitterButton] = useState(false);
  // State to store fetched tickets
  const [supportTickets, setSupportTickets] = useState([]);
  // state to set support flow page number
  const [supportFlowPageNumber, setSupportFlowPageNumber] = useState(null);
  const [isAnimationRequired, setIsAnimationRequired] = useState(null); //state to track whether animation required or not
  const [isNavBarClicked, setIsNavbarClicked] = useState(null);
  const [authLoading, setAuthLoading] = useState(false);
  const [appState, setAppState] = useState(null); // State for app state
  const [appStateLoading, setAppStateLoading] = useState(true); // Loading for app state
  const [userData, setUserData] = useState("");
  // State to set the Modal open or close
  const [closedStateModalIsOpen, setClosedStateModalIsOpen] = useState(false);
  const [transactionId, setTransactionId] = useState(
    localStorage.getItem("transactionId") || null
  );
  const [cybridCounterpartyGuid, setCybridCounterpartyGuid] = useState(null); // State to set the cybrid counterparty guid once the create counterparty is called
  const [newExchangeRate, setNewExchangeRate] = useState(null); // State to set new exchange rate if promo rate is applied
  const [customerActivityLimit, setCustomerActivityLimit] = useState(null); // State to set the customer activity limit
  const [customerGuid, setCustomerGuid] = useState(null);
  const isMobile = useMediaQuery({ query: "(max-width: 992px)" }); //Variable for mobile view

  useEffect(() => {
    // Track user entrance event only if Mixpanel is enabled
    if (process.env.REACT_APP_DEPLOYMENT_MODE === "PRODUCTION") {
      mixpanel.track("Visit App's landing page!");
    }
  }, []);
  //identificationGUID
  const handleClickNavbar = () => {
    // if (customerState === "Not Initialized" || !customerState) {
    //   setKYCPageNumber(2);
    // }
    setIsNavbarClicked(false);
  };

  useEffect(() => {
    // Update localStorage whenever the transactionId or transactionStatus changes
    if (transactionId) {
      localStorage.setItem("transactionId", transactionId);
    }
  }, [transactionId]);

  useEffect(() => {
    const fetchStripeConfig = async () => {
      try {
        // Obtain ID token
        const idToken = await auth.currentUser.getIdToken(
          /* forceRefresh */ true
        );
        const r = await fetch(
          process.env.REACT_APP_PAYEZY_SERVER_URI + "/stripe-config",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );

        const { publishableKey } = await r.json();
        setStripePromise(loadStripe(publishableKey));
      } catch (error) {
        console.error("Error fetching stripe config:", error);
      }
    };

    if (auth.currentUser) {
      fetchStripeConfig();
    } else {
      console.log("Unauthorized. Please login");
    }
  }, [auth.currentUser]);
  //Fetch USD to INR exchange rate
  useEffect(() => {
    const getUSDINRRate = async () => {
      try {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
        console.log("calling rate api");

        const response = await fetch(
          process.env.REACT_APP_PAYEZY_SERVER_URI + "/get-usdinr-rate",
          requestOptions
        );

        const { UsdToInr } = await response.json();
        setUsdToInrExRate(UsdToInr);
      } catch (error) {
        console.error("Error fetching USD to INR rate:", error);
      }
    };

    getUSDINRRate();
  }, []);
  // Fetch verification customer details
  useEffect(() => {
    const fetchVerificationCustomer = async () => {
      // Check if currentUser is available
      if (!auth.currentUser) {
        console.warn("No user is currently logged in.");
        return; // Exit early if no user is authenticated
      }

      const idToken = await auth.currentUser.getIdToken(true);
      if (idToken) {
        try {
          const usersRef = collection(db, "userData");
          const userDocRef = doc(usersRef, profileEmail);
          const documentSnapshot = await getDoc(userDocRef);

          if (documentSnapshot.exists()) {
            const userData = documentSnapshot.data();
            setUserData(userData);
            const cachedVerificationStatus =
              localStorage.getItem("verificationStatus");
            if (cachedVerificationStatus === "verified") {
              setCustomerState(cachedVerificationStatus);
              setShowResumeButton(false);

              return; // Exit early since we have the verified status cached
            }

            if (userData.cybrid_customer_details.customer_guid) {
              const verificationStatus = await getCybridCustomerState(
                idToken,
                userData.cybrid_customer_details.customer_guid
              );
              setCustomerState(verificationStatus);
              if (verificationStatus === "verified") {
                localStorage.setItem("verificationStatus", verificationStatus);
                setShowResumeButton(false);
                const customerDetailsResponse = await getCybridCustomer(
                  idToken,
                  userData.cybrid_customer_details.customer_guid
                );
                if (
                  customerDetailsResponse &&
                  customerDetailsResponse.customer
                ) {
                  const user_pii = {
                    name: {
                      first:
                        customerDetailsResponse?.customer?.name?.first || "",
                      middle:
                        customerDetailsResponse?.customer?.name?.middle || "",
                      last: customerDetailsResponse?.customer?.name?.last || "",
                    },
                    address: {
                      street:
                        customerDetailsResponse?.customer?.address?.street ||
                        "",
                      street2:
                        customerDetailsResponse?.customer?.address?.street2 ||
                        "",
                      city:
                        customerDetailsResponse?.customer?.address?.city || "",
                      subdivision:
                        customerDetailsResponse?.customer?.address
                          ?.subdivision || "",
                      postal_code:
                        customerDetailsResponse?.customer?.address
                          ?.postal_code || "",
                      country_code:
                        customerDetailsResponse?.customer?.address
                          ?.country_code || "",
                    },
                    date_of_birth:
                      customerDetailsResponse?.customer?.date_of_birth || "",
                    phone_number:
                      customerDetailsResponse?.customer?.phone_number || "",
                    email_address:
                      customerDetailsResponse?.customer?.email_address || "",
                    document_submitted:
                      customerDetailsResponse?.customer
                        ?.identification_numbers[0]?.type || "",
                    document_id:
                      customerDetailsResponse?.customer
                        ?.identification_numbers[0]?.identification_number ||
                      "",
                  };

                  // Update Firestore document if user_pii details are not already saved
                  if (userData?.user_pii?.name?.first === "") {
                    await updateDoc(userDocRef, {
                      user_pii,
                    });
                  }
                }
              }

              if (customerState === "verified") {
                setShowResumeButton(false);
              }
              // Merge the existing userPii with the updated customerState
              if (verificationStatus) {
                // Update only the userPii field with the modified customerState
                await updateDoc(userDocRef, {
                  customer_state: verificationStatus,
                });
              } else {
                // Update customer data for KYC as 'Not Initialized'
                await updateDoc(userDocRef, {
                  customer_state: "Not Initialized",
                });
              }
            } else {
              setCustomerState("Not Initialized");
            }
          }
        } catch (err) {
          console.error("Error fetching customer data:", err);
        }
      }
    };

    fetchVerificationCustomer();
  }, [isLoggedIn, sessionId]);

  // Fetch the app state from Firebase
  useEffect(() => {
    const fetchAppState = async () => {
      try {
        const appStateRef = doc(db, "pzStates", "currentState");
        const docSnap = await getDoc(appStateRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setAppState(data.state);
        } else {
          console.log("No current app state found");
        }
      } catch (error) {
        console.error("Error fetching current app state:", error);
      } finally {
        setAppStateLoading(false); // Stop loading once the data is fetched
      }
    };
    fetchAppState();
  }, []);
  // Persisting state for already logged in user in closed state.
  useEffect(() => {
    if (appState === "Closed") {
      const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
        if (authLoading) {
          return; // Skip during modal display
        }
        if (user) {
          const fetchData = async () => {
            const usersRef = collection(db, "userData");
            const userDocRef = doc(usersRef, user.email);

            try {
              const documentSnapshot = await getDoc(userDocRef);
              // Retrive and update stripeCustomerId on refresh/auth change
              // Check if user email exists in Firestore, if so, update StrId
              if (documentSnapshot.exists()) {
                setLoading(false); // Closing the loading screen
                setProfileEmail(user.email); // Setting the profile email using the logged in details
                setIsLoggedIn(true);
                setGoogleLoginPageNumber(-1);
                setOnClickLoginButton(false);
                // Generate and set the session ID using uuid
                const sessionID = uuid();
                setSessionId(sessionID);
                // The document with the specified email address exists
                const userData = documentSnapshot.data();
                // Set the StrId
                setStrId(userData.stripeCustomerId);
              } else {
                // The document does not exist
                console.log("Document does not exist");
              }
            } catch (error) {
              console.error("Error getting document:", error);
            }
          };

          fetchData();
        }
      });

      return () => unregisterAuthObserver();
    }
  }, [auth, authLoading, appState]);

  // Persisting state for already logged in user in open and restricted state.
  useEffect(() => {
    if (
      appState === "Open" ||
      appState === "Restricted" ||
      appState === "Pause/Maintenance"
    ) {
      const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
        if (authLoading) {
          return; // Skip during modal display
        }
        if (user) {
          setLoading(false); // Closing the loading screen
          setProfileEmail(user.email); // Setting the profile email using the logged in details
          setIsLoggedIn(true);
          setGoogleLoginPageNumber(-1);
          setOnClickLoginButton(false);
          // Generate and set the session ID using uuid
          const sessionID = uuid();
          setSessionId(sessionID);
          console.log("User refreshed page or redirected, resetting sessionID");

          // Retrive and update stripeCustomerId on refresh/auth change
          // Check if user email exists in Firestore, if so, update StrId
          const fetchData = async () => {
            const usersRef = collection(db, "userData");
            const userDocRef = doc(usersRef, user.email);

            try {
              const documentSnapshot = await getDoc(userDocRef);

              if (documentSnapshot.exists()) {
                // The document with the specified email address exists
                const userData = documentSnapshot.data();
                // Set the StrId
                setStrId(userData.stripeCustomerId);
              } else {
                // The document does not exist
                console.log("Document does not exist");
              }
            } catch (error) {
              console.error("Error getting document:", error);
            }
          };

          fetchData();
        }
      });

      return () => unregisterAuthObserver();
    }
  }, [auth, authLoading, appState]);
  // USD send app login flow on clicking login button
  const sendAppLoginFlow = () => {
    switch (googleLoginPageNumber) {
      case 0:
        return (
          <LoginOptions
            setProfileEmail={setProfileEmail}
            setGoogleLoginPageNumber={setGoogleLoginPageNumber}
            setIsLoggedIn={setIsLoggedIn}
            setErrorMessage={setErrorMessage}
            errorMessage={errorMessage}
            setHideTwitterButton={setHideTwitterButton}
            hideTwitterButton={hideTwitterButton}
            appState={appState}
            setClosedStateModalIsOpen={setClosedStateModalIsOpen}
            setOnClickLoginButton={setOnClickLoginButton}
          />
        );
      case 1:
        return (
          <EmailPswdSignIn
            setGoogleLoginPageNumber={setGoogleLoginPageNumber}
            setProfileEmail={setProfileEmail}
            setIsLoggedIn={setIsLoggedIn}
          />
        );
      default:
        return null;
    }
  };
  // Custom component to handle password reset logic
  const PasswordResetRoute = () => {
    const location = useLocation();

    // Extract query parameters from the URL
    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get("mode");
    const oobCode = queryParams.get("oobCode");

    // Render ResetPassword component only if mode is 'resetPassword' and oobCode exists
    if (mode === "resetPassword" && oobCode) {
      return (
        <PasswordReset
          oobCode={oobCode}
          setGoogleLoginPageNumber={setGoogleLoginPageNumber}
          setOnClickLoginButton={setOnClickLoginButton}
        />
      );
    }

    // Otherwise, navigate or show a message indicating the link is invalid
    return <Navigate to="/" />;
  };
  // Code to fetch Customer activity limit
  useEffect(() => {
    const checkCustomerGuidAndFetchLimit = async () => {
      if (!profileEmail) {
        console.log("No authenticated user email found");
        return;
      }
      try {
        // Fetch the user document from Firestore
        const userDocRef = doc(db, "userData", profileEmail);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const fetchedCustomerGuid =
            userData.cybrid_customer_details?.customer_guid;
          setCustomerGuid(fetchedCustomerGuid || null);
          if (fetchedCustomerGuid) {
            // If customer_guid exists, proceed to fetch the customer activity limit
            const idToken = await auth.currentUser.getIdToken(
              /* forceRefresh */ true
            );
            const requestOptions = {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${idToken}`,
              },
            };
            const response = await fetch(
              `${process.env.REACT_APP_PAYEZY_SERVER_URI}/get-customer-activity-limits`,
              requestOptions
            );
            const result = await response.json();
            setCustomerActivityLimit(result.transfers.remaining / HUNDRED);
          } else {
            // If customer_guid is not present, set the limit to 0
            setCustomerActivityLimit(0);
          }
        } else {
          console.log("User document not found");
          setCustomerActivityLimit(0);
        }
      } catch (error) {
        console.log(
          "Error checking customer GUID or fetching activity limit:",
          error
        );
        setCustomerActivityLimit(0);
      }
    };

    if (auth.currentUser) {
      checkCustomerGuidAndFetchLimit();
    } else {
      console.log("No authenticated user");
    }
  }, [auth.currentUser, profileEmail]);
  return (
    <>
      {appStateLoading ? (
        <Spinner />
      ) : (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <link rel="icon" href="/favicon.ico" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <meta name="theme-color" content="#000000" />
            <meta
              name="description"
              content="Payezy - The easiest, cheapest, and safest way to support your friends and family back home."
            />
            <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
            {/* You can add more meta tags or change existing ones as needed */}
            <title>Payezy</title>
          </Helmet>
          <AppContext.Provider
            value={{
              isMobile,
              sendFlowPageNumber,
              setSendFlowPageNumber,
              googleLoginPageNumber,
              setGoogleLoginPageNumber,
              setOnClickLoginButton,
              stripePromise,
              loading,
              setLoading,
              profileEmail,
              recipientId,
              setRecipientId,
              strId,
              setStrId,
              sessionId,
              setSessionId,
              txId,
              setTxId,
              KYCPageNumber,
              setKYCPageNumber,
              transferHistoryData,
              setTranferHistoryData,
              supportTickets,
              setSupportTickets,
              supportFlowPageNumber,
              setSupportFlowPageNumber,
              isAnimationRequired,
              setIsAnimationRequired,
              isNavBarClicked,
              setIsNavbarClicked,
              setAuthLoading,
              customerState,
              setCustomerState,
              KycStatus,
              setKycStatus,
              txData,
              setTxData,
              transactionId,
              setTransactionId,
              showResumeButton,
              setShowResumeButton,
              userData,
              setUserData,
            }}
          >
            {window.location.pathname !== "/reset-password" && (
              <div
                className={cx(styles.headerContainer, {
                  [styles.headerContainerMob]: isMobile,
                })}
              >
                {" "}
                <Header
                  profileEmail={profileEmail}
                  setProfileEmail={setProfileEmail}
                  setGoogleLoginPageNumber={setGoogleLoginPageNumber}
                  googleLoginPageNumber={googleLoginPageNumber}
                  setOnClickLoginButton={setOnClickLoginButton}
                  setAmountInINR={setAmountInINR}
                  setAmountInUSD={setAmountInINR}
                  setSendFlowPageNumber={setSendFlowPageNumber}
                />
              </div>
            )}

            {isMobile && profileEmail && (
              <NavBarMobile
                profileEmail={profileEmail}
                setGoogleLoginPageNumber={setGoogleLoginPageNumber}
                setOnClickLoginButton={setOnClickLoginButton}
                setProfileEmail={setProfileEmail}
                setSendFlowPageNumber={setSendFlowPageNumber}
                setAmountInINR={setAmountInINR}
                setAmountInUSD={setAmountInUSD}
              />
            )}
            {loading && <Spinner />}
            {!loading && (
              <div className={styles.spinnerDiv}>{sendAppLoginFlow()}</div>
            )}

            <div
              className={cx(styles.mainContentDiv, {
                [styles.mainContentDivMob]: isMobile,
                [styles.resetPasswordLayout]:
                  window.location.pathname === "/reset-password",
              })}
            >
              {!isMobile &&
                !onClickLoginButton &&
                window.location.pathname !== "/reset-password" && (
                  <nav className={styles.navBar}>
                    <ul
                      style={{ listStyleType: "none", padding: 0, margin: 0 }}
                    >
                      <li className={styles.navBarItems}>
                        <NavLink
                          onClick={handleClickNavbar}
                          className={`${styles.navLink} ${
                            location.pathname === "/send"
                              ? styles.activeNavLink
                              : ""
                          }`}
                          to="/send"
                        >
                          Send
                        </NavLink>
                      </li>
                      {profileEmail && (
                        <li className={styles.navBarItems}>
                          <NavLink
                            onClick={handleClickNavbar}
                            className={`${styles.navLink} ${
                              location.pathname === "/profile"
                                ? styles.activeNavLink
                                : ""
                            }`}
                            to="/profile"
                          >
                            Profile
                          </NavLink>
                        </li>
                      )}
                      {profileEmail && customerState === "verified" && (
                        <li className={styles.navBarItems}>
                          <NavLink
                            onClick={handleClickNavbar}
                            className={`${styles.navLink} ${
                              location.pathname === "/connectBank"
                                ? styles.activeNavLink
                                : ""
                            }`}
                            to="/connectBank"
                          >
                            Connect Bank
                          </NavLink>
                        </li>
                      )}
                      {profileEmail && customerState === "verified" && (
                        <li className={styles.navBarItems}>
                          <NavLink
                            onClick={handleClickNavbar}
                            className={`${styles.navLink} ${
                              location.pathname.startsWith("/autoSend")
                                ? styles.activeNavLink
                                : ""
                            }`}
                            to="/autoSend"
                          >
                            AutoSend
                            <div className={styles.betaBadge}>BETA</div>
                          </NavLink>
                        </li>
                      )}

                      {profileEmail && (
                        <li className={styles.navBarItems}>
                          <NavLink
                            onClick={handleClickNavbar}
                            className={`${styles.navLink} ${
                              location.pathname === "/transfers"
                                ? styles.activeNavLink
                                : ""
                            }`}
                            to="/transfers"
                          >
                            Transfers
                          </NavLink>
                        </li>
                      )}
                      {profileEmail && (
                        <li className={styles.navBarItems}>
                          <NavLink
                            onClick={handleClickNavbar}
                            className={`${styles.navLink} ${
                              location.pathname === "/support"
                                ? styles.activeNavLink
                                : ""
                            }`}
                            to="/support"
                          >
                            Support
                          </NavLink>
                        </li>
                      )}
                      {profileEmail && (
                        <div className={styles.navBarItems}>
                          <a
                            href="https://discord.gg/YSMxERM3me"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.navLink}
                          >
                            Discord
                          </a>
                        </div>
                      )}

                      <div className={styles.footerDiv}>
                        <FooterMain />
                      </div>
                    </ul>
                  </nav>
                )}

              {!onClickLoginButton && (
                <div
                  className={cx(styles.layoutDiv, {
                    [styles.layoutDivMob]: isMobile,
                    [styles.centeredLayoutDiv]:
                      window.location.pathname === "/reset-password",
                  })}
                >
                  {" "}
                  <Routes>
                    <Route path="/" element={<Navigate to="/send" replace />} />
                    <Route
                      path="/send"
                      element={
                        <SendINR
                          profileEmail={profileEmail}
                          setGoogleLoginPageNumber={setGoogleLoginPageNumber}
                          setAmountInUSD={setAmountInUSD}
                          amountInUSD={amountInUSD}
                          amountInINR={amountInINR}
                          setAmountInINR={setAmountInINR}
                          usdToInrExRate={usdToInrExRate}
                          appState={appState}
                          setNewExchangeRate={setNewExchangeRate}
                          newExchangeRate={newExchangeRate}
                          cybridCounterpartyGuid={cybridCounterpartyGuid}
                          setCybridCounterpartyGuid={setCybridCounterpartyGuid}
                          userSelectionMode={userSelectionMode}
                          setUserSelectionMode={setUserSelectionMode}
                          customerActivityLimit={customerActivityLimit}
                          setCustomerActivityLimit={setCustomerActivityLimit}
                          customerGuid={customerGuid}
                        />
                      }
                    />

                    {isLoggedIn && customerState !== "frozen" && (
                      <Route
                        path="/profile"
                        element={
                          <KycLandingPage
                            isLoggedIn={isLoggedIn}
                            strId={strId}
                            profileEmail={profileEmail}
                          />
                        }
                      />
                    )}
                    {isLoggedIn && customerState === "frozen" && (
                      <Route path="/profile" element={<KYCFrozen />} />
                    )}
                    {isLoggedIn && customerState === "verified" && (
                      <Route
                        path="/connectBank"
                        element={
                          <BankAccountsMain
                            amountInINR={amountInINR}
                            amountInUSD={amountInUSD}
                            usdToInrExRate={usdToInrExRate}
                            setSendFlowPageNumber={setSendFlowPageNumber}
                            profileEmail={profileEmail}
                            transactionId={transactionId}
                            cybridCounterpartyGuid={cybridCounterpartyGuid}
                            recipientId={recipientId}
                            newExchangeRate={newExchangeRate}
                          />
                        }
                      />
                    )}
                    {isLoggedIn && customerState === "verified" && (
                      <Route
                        path="/autoSend"
                        element={
                          <RecurringTransfers usdToInrExRate={usdToInrExRate} />
                        }
                      />
                    )}
                    {isLoggedIn && customerState === "verified" && (
                      <Route
                        path="/autoSend/create"
                        element={
                          <CreateRecurringTransfer
                            customerActivityLimit={customerActivityLimit}
                          />
                        }
                      />
                    )}
                    {isLoggedIn && (
                      <Route path="/transfers" element={<TransferHistory />} />
                    )}
                    <Route
                      path="/support"
                      element={
                        isLoggedIn ? (
                          <SupportLandingPage />
                        ) : (
                          <div
                            className={cx(styles.loginToContinueContainer, {
                              [styles.loginToContinueContainerMob]: isMobile,
                            })}
                          >
                            <LoginToContinue
                              setGoogleLoginPageNumber={
                                setGoogleLoginPageNumber
                              }
                              setOnClickLoginButton={setOnClickLoginButton}
                            />
                          </div>
                        )
                      }
                    />
                    <Route
                      path="/reset-password"
                      element={<PasswordResetRoute />}
                    />
                  </Routes>
                </div>
              )}
            </div>
          </AppContext.Provider>
          <Modal
            isOpen={closedStateModalIsOpen}
            overlayClassName={styles.popupOverlay}
            className={styles.popupContent}
            shouldCloseOnOverlayClick={false}
            ariaHideApp={false}
          >
            <ClosedStateModal
              setClosedStateModalIsOpen={setClosedStateModalIsOpen}
            />
          </Modal>
        </>
      )}
    </>
  );
}

export default App;
