import React, { useState } from "react";
import ButtonRade from "../../components/RadeButtons";
import { PROFILE_ENUM } from "../../enums/profileEnum";
import styles from "./index.module.scss";
import cx from "classnames";
import { AppContext } from "../../context";
import { useContext } from "react";
import KYCRejected from "../../assets/KYCRejectedIcon.svg";
import { getDoc, doc, collection } from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect } from "react";
import ProfilePreviewKYC from "../Profile/ProfilePreviewKYC";
import StepIndicator from "../../components/StepIndicator/StepIndicator";
import { useNavigate } from "react-router-dom";
import refreshButton from "../../assets/refreshButton.svg";

function KYCCompleted({
  handleRefreshKYCStatus,
  setPersonaInquiryId,
  errorForName,
  middleName,
  city,
  subdivision,
  street,
  street2,
  setDateOfBirth,
  errorForLastName,
  profileEmail,
  handlesubmitUpdateProfile,
  handleSubmitResubmitKYC,
  firstName,
  setModalIsOpen,
  setSubdivision,
  lastName,
  phoneNumber,
  handleSubmit,
  emailAddress,
  handleChangeInputFieldName,
  handleChangeInputLastNameEmail,
  handlePhoneNumberChange,
  setErrorForID,
  idBackSide,
  setIdBackSide,
  handleChangeInputFieldEmail,
  setErrorForPancard,
  idFrontSide,
  postalCode,
  setIdFrontSide,
  setErrorForAadhar,
  reSubmitKyc,
  setKYCPageNumber,
  setResubmitKyc,
  isFileUploaded,
  idBackSideFileType,
  setIdBackSideFileType,
  idFrontSideFileType,
  setIdFrontSideFileType,
  selectedFileName,
  setSelectedFileName,
  pancardHolding,
  setPancardHolding,
  aadharHolding,
  setAadharHolding,
  errorForAadharHold,
  setErrorForAadharHold,
  errorForPancardHold,
  setErrorForPancardHold,
  handleChangeInputStreet,
  handleChangeInputStreet2,
  handleChangeInputCity,
  handleChangeInputSubdivision,
  handleChangeInputPostalCode,
  handleChangeInputCountryCode,
  handleDateOfBirthChange,
  setFirstName,
  dateOfBirth,
  setMiddleName,
  setLastName,
  setPhoneNumbe,
  setEmailAddress,
  setStreet,
  setStreet2,
  setCity,
  setPostalCode,
  setCountryCode,
  setReSubmitKyc,
  setPhoneNumber,
  underReview,
}) {
  const { isMobile, customerState } = useContext(AppContext);
  // status to store KYC status
  const [currentStep, setCurrentStep] = useState(1);
  const navigate = useNavigate(); // Initialize the useNavigate hook
  const cachedVerificationStatus = localStorage.getItem("verificationStatus");
  // Function to handle button click and navigate to the support route
  const handleNavigateToSupport = () => {
    navigate("/support"); // Replace '/support' with your desired route
  };
  useEffect(() => {
    const fetchUserData = async () => {
      const usersRef = collection(db, "userData");
      const userDocRef = doc(usersRef, profileEmail);
      const documentSnapshot = await getDoc(userDocRef);

      if (documentSnapshot.exists()) {
        const userData = documentSnapshot.data();
        setFirstName(userData.user_pii.name.first);
        setEmailAddress(userData.user_pii.email_address || "");
        setDateOfBirth(userData.user_pii.date_of_birth || "");
        setMiddleName(userData.user_pii.name.middle || "");
        setLastName(userData.user_pii.name.last || "");
        setPhoneNumber(userData.user_pii.phone_number || "");
        setStreet(userData.user_pii.address.street || "");
        setStreet2(userData.user_pii.address.street2 || "");
        setSubdivision(userData.user_pii.address.subdivision);
        setCity(userData.user_pii.address.city || "");
        setPostalCode(userData.user_pii.address.postal_code || "");
      }

      // Autofill the form fields with data from Firestore
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    // Function to fetch the KYC status from the database
    async function fetchKYCStatus() {
      if (!profileEmail) {
        console.error("Profile email is not defined.");
        return;
      }
      // await handleRefreshKYCStatus();
      // Create a reference to the "userData" collection in the database
      const usersRef = collection(db, "userData");

      // Get the document reference for the user with the given profileEmail
      const userDocRef = doc(usersRef, profileEmail);

      // Fetch the user document snapshot from the database
      const userDocSnapshot = await getDoc(userDocRef);

      // Check if the user document exists in the database
      if (userDocSnapshot.exists()) {
        // Extract user data from the document snapshot
        const userData = userDocSnapshot.data();
        if (customerState === "reviewing" || underReview) {
          setCurrentStep(2);
        } else if (
          cachedVerificationStatus === "verified" ||
          customerState === "verified"
        ) {
          setCurrentStep(3);
        } else if (customerState === "rejected") {
          setCurrentStep(1);
        } else {
          setCurrentStep(1);
        }
      }
    }

    // Call the fetchKYCStatus function when KycStatus or confirmUpdateModalOpen changes
    // This ensures that the KYC status is fetched whenever there's an update to those dependencies
    // For example, when the user submits or updates the KYC status
    fetchKYCStatus();
  }, [
    profileEmail,
    setResubmitKyc,
    setKYCPageNumber,
    customerState,
    currentStep,
    underReview,
  ]);

  return (
    <div
      className={cx(styles.KYCInProgressContainer, {
        [styles.KYCInProgressContainerWeb]: !isMobile,
        [styles.KYCInProgressContainerMob]: isMobile,
      })}
    >
      {customerState === "pending" && (
        <>
          <div
            className={cx(styles.KYCApplicationSubmitted, {
              [styles.KYCApplicationSubmittedWeb]: !isMobile,
              [styles.KYCApplicationSubmittedMob]: isMobile,
            })}
          >
            {PROFILE_ENUM.KYCApplicationSubmitted}
          </div>
          <div
            className={cx(styles.weWillLetYouKnow, {
              [styles.weWillLetYouKnowWeb]: !isMobile,
              [styles.weWillLetYouKnowMob]: isMobile,
            })}
          >
            {PROFILE_ENUM.weWillLetYouKnow}
          </div>
        </>
      )}

      {customerState === "frozen" && (
        <div
          className={cx(styles.KYCButtonDiv, {
            [styles.KYCButtonDivWeb]: !isMobile,
            [styles.KYCButtonDivMob]: isMobile,
          })}
        >
          <ButtonRade
            customStyling={cx(styles.KYCButton, {
              [styles.KYCButtonWeb]: !isMobile,
              [styles.KYCButtonMob]: isMobile,
            })}
          >
            {PROFILE_ENUM.KYC}
            {customerState === "frozen" && (
              <img
                src={KYCRejected}
                alt="KYC Rejeceted"
                className={cx(styles.KYCInProgess, {
                  [styles.KYCInProgessWeb]: !isMobile,
                  [styles.KYCInProgessMob]: isMobile,
                })}
              />
            )}
          </ButtonRade>
        </div>
      )}
      <div
        className={cx(styles.statusContainer, {
          [styles.statusContainerWeb]: !isMobile,
          [styles.statusContainerMob]: isMobile,
        })}
      >
        {" "}
        {customerState !== "unverified" && (
          <div
            className={cx(styles.statusOfKYC, {
              [styles.statusOfKYCWeb]: !isMobile,
              [styles.statusOfKYCMob]: isMobile,
            })}
          >
            KYC {PROFILE_ENUM.statusOfKYC}
          </div>
        )}
        <div
          className={cx(styles.submittedAndWaiting, {
            [styles.submittedAndWaitingWeb]: !isMobile,
            [styles.submittedAndWaitingMob]: isMobile,
          })}
        >
          {(cachedVerificationStatus === "verified" ||
            customerState === "verified") && (
            <div
              className={cx(styles.yourAccountIsVerified, {
                [styles.yourAccountIsVerifiedWeb]: !isMobile,
                [styles.yourAccountIsVerifiedMob]: isMobile,
              })}
            >
              {PROFILE_ENUM.yourAccountIsVerified}
            </div>
          )}
          {customerState === "frozen" && (
            <>
              {" "}
              <div
                className={cx(styles.yourApplicationIsFrozen, {
                  [styles.yourApplicationIsFrozenWeb]: !isMobile,
                  [styles.yourApplicationIsFrozenMob]: isMobile,
                })}
              >
                {PROFILE_ENUM.yourKYCIsInFroxen}
              </div>
              <div className={styles.submitTicketButtonContainer}>
                <ButtonRade
                  customStyling={styles.submitTicketButton}
                  onClick={handleNavigateToSupport}
                >
                  Submit Ticket
                </ButtonRade>
              </div>
            </>
          )}
          {customerState === "rejected" && (
            <div
              className={cx(styles.yourApplicationIsRejected, {
                [styles.yourApplicationIsRejectedWeb]: !isMobile,
                [styles.yourApplicationIsRejectedMob]: isMobile,
              })}
            >
              {PROFILE_ENUM.yourApplicationIsRejected}
            </div>
          )}
          {(customerState === "reviewing" || underReview) && (
            <div
              className={cx(styles.submittedAndWaitingText, {
                [styles.submittedAndWaitingTextWeb]: !isMobile,
                [styles.submittedAndWaitingTextMob]: isMobile,
              })}
            >
              <span>
                Your KYC has been successfully submitted. Please come back after
                some time or click the refresh button
              </span>

              <img
                src={refreshButton}
                alt="refresh button"
                className={styles.refreshButtonIcon}
              />
              <span> to get an update.</span>
            </div>
          )}
        </div>
      </div>
      {customerState !== "frozen" && (
        <StepIndicator currentStep={currentStep} />
      )}

      {(cachedVerificationStatus === "verified" ||
        customerState === "verified") && (
        <ProfilePreviewKYC
          street={street}
          middleName={middleName}
          dateOfBirth={dateOfBirth}
          handleChangeInputStreet2={handleChangeInputStreet2}
          handleChangeInputSubdivision={handleChangeInputSubdivision}
          setSubdivision={setSubdivision}
          setDateOfBirth={setDateOfBirth}
          emailAddress={emailAddress}
          setEmailAddress={setEmailAddress}
          errorForName={errorForName}
          firstName={firstName}
          lastName={lastName}
          phoneNumber={phoneNumber}
          handleChangeInputFieldName={handleChangeInputFieldName}
          handleChangeInputLastNameEmail={handleChangeInputLastNameEmail}
          handlePhoneNumberChange={handlePhoneNumberChange}
          setErrorForID={setErrorForID}
          idBackSide={idBackSide}
          subdivision={subdivision}
          postalCode={postalCode}
          setIdBackSide={setIdBackSide}
          handleChangeInputFieldEmail={handleChangeInputFieldEmail}
          setErrorForPancard={setErrorForPancard}
          idFrontSide={idFrontSide}
          setIdFrontSide={setIdFrontSide}
          setErrorForAadhar={setErrorForAadhar}
          reSubmitKyc={reSubmitKyc}
          setResubmitKyc={setResubmitKyc}
          handleSubmitResubmitKYC={handleSubmitResubmitKYC}
          isFileUploaded={isFileUploaded}
          idBackSideFileType={idBackSideFileType}
          setIdBackSideFileType={setIdBackSideFileType}
          idFrontSideFileType={idFrontSideFileType}
          setIdFrontSideFileType={setIdFrontSideFileType}
          selectedFileName={selectedFileName}
          setSelectedFileName={setSelectedFileName}
          pancardHolding={pancardHolding}
          setPancardHolding={setPancardHolding}
          aadharHolding={aadharHolding}
          setAadharHolding={setAadharHolding}
          errorForAadharHold={errorForAadharHold}
          setErrorForAadharHold={setErrorForAadharHold}
          errorForPancardHold={errorForPancardHold}
          setErrorForPancardHold={setErrorForPancardHold}
          errorForLastName={errorForLastName}
          handleDateOfBirthChange={handleDateOfBirthChange}
          setFirstName={setFirstName}
          setMiddleName={setMiddleName}
          city={city}
          street2={street2}
          setLastName={setLastName}
          setPhoneNumber={setPhoneNumber}
          setStreet={setStreet}
          setStreet2={setStreet2}
          setCity={setCity}
          setPostalCode={setPostalCode}
          setCountryCode={setCountryCode}
          setReSubmitKyc={setReSubmitKyc}
        />
      )}
    </div>
  );
}

export default KYCCompleted;
