import React, { useContext } from "react";
import styles from "./index.module.scss";
import { AppContext } from "../../context";
import { SEND_ENUM } from "../../enums/sendEnum";
import cx from "classnames";

const BankVerificationFromPersona = () => {
  const { isMobile } = useContext(AppContext);

  return (
    <div
      className={cx(styles.plaidConnectMainConatiner, {
        [styles.plaidConnectMainConatinerMob]: isMobile,
      })}
    >
      {/* Animated Circle Loader*/}
      <ul className={styles.loaderList}>
        <li>
          <div
            className={cx(styles.loaderCircle, {
              [styles.loaderCircleMob]: isMobile,
            })}
          >
            <span></span>
          </div>
        </li>
      </ul>
      <div
        className={cx(styles.plaidConnectContent, {
          [styles.plaidConnectContentMob]: isMobile,
        })}
      >
        {SEND_ENUM.redirectingToPersona}
      </div>
    </div>
  );
};

export default BankVerificationFromPersona;
