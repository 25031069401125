import { auth, db } from "../../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";

export const transferMoney = async (
  selectedAccount,
  customer_guid,
  sender_email,
  senderFullName,
  transaction_id,
  cybrid_counterparty_guid,
  recipientId,
  navigate,
  setSendFlowPageNumber,
  setModalIsOpen,
  setNewExchangeRate
) => {
  try {
    // Fetch the receiver details using recipientId from firebase
    const recipientDocRef = doc(
      db,
      "counterpartyDetails",
      sender_email,
      "userCounterpartyDetails",
      recipientId
    );
    const recipientSnap = await getDoc(recipientDocRef);
    if (!recipientSnap.exists()) {
      throw new Error("Receiver not found");
    }
    const recipientData = recipientSnap.data();
    const receiverDetails = {
      receiver_id: recipientId,
      receiver_email: recipientData.counterparty_pii.email,
      receiver_nickname: recipientData.counterparty_pii.nick_name,
      receiver_name: recipientData.counterparty_pii.name.full,
      receiver_phone: recipientData.counterparty_pii.phone_no,
      receiver_bank_number: recipientData.counterparty_pii.account_no,
      receiver_bank_ifsc: recipientData.counterparty_pii.ifsc_code,
      receiver_bank_name: recipientData.counterparty_pii.bank_name,
      cybrid_counterparty_guid: cybrid_counterparty_guid,
    };
    console.log("recipientData", recipientData);
    // Fetch the transaction details using the transaction_id from firebase
    const transactionRef = doc(
      db,
      "transactions",
      sender_email,
      "userTransactions",
      transaction_id
    );
    const transactionSnap = await getDoc(transactionRef);

    if (!transactionSnap.exists()) {
      throw new Error("Transaction not found");
    }

    // Extract the transaction details
    const transactionData = transactionSnap.data();
    await updateDoc(transactionRef, {
      "transaction_details.status": "payment_init",
    });
    // Prepare the full transaction data for the POST request
    const transactionDetails = {
      transaction_id: transaction_id,
      transaction_details: transactionData.transaction_details, // Fetch the details
    };
    const senderDetails = {
      sender_email: sender_email,
      sender_name: senderFullName,
      account_four_digits: selectedAccount.plaid_account_mask,
      external_bank_account_guid: selectedAccount.guid,
    };

    const idToken = await auth.currentUser.getIdToken(true);
    // TODO : current customer guid match with stored in userdata-email
    const response = await fetch(
      `${process.env.REACT_APP_PAYEZY_SERVER_URI}/transfer-fiat-and-send/${customer_guid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({
          sender_id: sender_email,
          sender_name: senderFullName,
          external_bank_account_guid: selectedAccount.guid,
          receiver_details: receiverDetails,
          transaction_data: transactionDetails,
          account_four_digits: selectedAccount.plaid_account_mask,
          sender_details: senderDetails,
        }),
      }
    );
    const transactionIdParam = transaction_id;
    if (!response.ok) {
      setModalIsOpen(false);
      navigate("/transfers", { state: { transactionIdParam } });
      setSendFlowPageNumber(0);
      localStorage.removeItem("transactionId");
      setNewExchangeRate(""); // Reset the new exchange rate
      throw new Error("Failed to transfer money");
    }

    const result = await response.json();
    setModalIsOpen(false);
    navigate("/transfers", { state: { transactionIdParam } });
    setSendFlowPageNumber(0);
    localStorage.removeItem("transactionId");
    setNewExchangeRate(""); // Reset the new exchange rate
    console.log("Transfer successful:", result);
    return result;
  } catch (error) {
    console.error("Error transferring money:", error);
    throw error;
  }
};
