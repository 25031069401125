import React, { useContext } from "react";
import {
  RECURRING_TRANSFER_ENUM,
  TransferScheduleType,
} from "../../enums/transferEnums";
import styles from "./RecurringTransferConfirmation.module.scss";
import ButtonRade from "../../components/RadeButtons";
import cx from "classnames";
import { AppContext } from "../../context";

export default function RecurringTransferConfirmation({
  formData,
  onBack,
  onConfirm,
  isLoading,
  errorMessage,
}) {
  const { isMobile } = useContext(AppContext);
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  };

  const getScheduleDescription = () => {
    if (formData.schedule_type === TransferScheduleType.FIXED) {
      return (
        <>
          <p>
            <strong>Schedule Type:</strong> Fixed{" "}
            {formData.frequency.value.toLowerCase()} transfers
          </p>
          <p>
            <strong>Start Date:</strong> {formatDate(formData.start_date)}
          </p>
          <p>
            <strong>End Date:</strong> {formatDate(formData.end_date)}
          </p>
          <p>
            <strong>Amount per Transfer:</strong>{" "}
            {formatCurrency(formData.amount_in_usd)}
          </p>
        </>
      );
    } else {
      return (
        <>
          <p>
            <strong>Schedule Type:</strong> Custom Schedule
          </p>
          <div className={styles.customScheduleList}>
            {formData.scheduled_transfers.map((transfer, index) => (
              <div key={index} className={styles.scheduleItem}>
                <span>{formatDate(transfer.scheduled_date)}</span>
                <span>{formatCurrency(transfer.amount_in_usd)}</span>
              </div>
            ))}
          </div>
        </>
      );
    }
  };

  return (
    <div className={styles.container}>
      <div
        className={cx(styles.confirmRecurringTransfer, {
          [styles.confirmRecurringTransferMob]: isMobile,
        })}
      >
        {RECURRING_TRANSFER_ENUM.confirmRecurringTransfer}
      </div>

      <div className={styles.section}>
        <h3>Sender Details</h3>
        <p>
          <strong>Name:</strong> {formData.sender_details.sender_name}
        </p>
        <p>
          <strong>Email:</strong> {formData.sender_details.sender_email}
        </p>
        <p>
          <strong>Account:</strong> ****
          {formData.sender_details.account_four_digits}
        </p>
      </div>

      <div className={styles.receiverDetailsSection}>
        <h3>Receiver Details</h3>
        <p>
          <strong>Name:</strong> {formData.receiver_details.receiver_name}
        </p>
        <p>
          <strong>Email:</strong> {formData.receiver_details.receiver_email}
        </p>
        <p>
          <strong>Bank:</strong> {formData.receiver_details.receiver_bank_name}
        </p>
        <p>
          <strong>Account:</strong>{" "}
          {formData.receiver_details.receiver_bank_number}
        </p>
        <p>
          <strong>IFSC:</strong> {formData.receiver_details.receiver_bank_ifsc}
        </p>
      </div>

      <div className={styles.section}>
        <h3>Transfer Schedule Details</h3>
        {getScheduleDescription()}
        <p>
          <strong>Total Scheduled Transfers:</strong>{" "}
          {formData.total_transfers_scheduled}
        </p>
      </div>

      {errorMessage && <div className={styles.error}>{errorMessage}</div>}

      <div className={styles.buttons}>
        <ButtonRade
          onClick={onBack}
          disabled={isLoading}
          customStyling={cx(styles.backButton, {
            [styles.backButtonMob]: isMobile,
          })}
        >
          Back
        </ButtonRade>
        <ButtonRade
          onClick={onConfirm}
          disabled={isLoading}
          className={cx(styles.confirmButton, {
            [styles.confirmButtonMob]: isMobile,
          })}
        >
          {isLoading ? "Processing..." : "Create"}
        </ButtonRade>
      </div>
    </div>
  );
}
