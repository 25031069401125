import React, { useContext, useState } from "react";
import styles from "./index.module.scss";
import { POPUP_ENUM } from "../../enums/popUpEnum";
import ButtonRade from "../../components/RadeButtons";
import cx from "classnames";
import { AppContext } from "../../context";

const ConfirmReSubmitKYC = ({
  handleClickResumeKYC,
  personaInquiryId,
  modalIsOpen,
  setModalIsOpen,
}) => {
  const { isMobile } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  // State to handle loading state
  // Function that handles the onclick of the cancel button
  const handleClickCancelButton = () => {
    setModalIsOpen(false);
  };

  // Function that handles the onclick of the confirm button
  const handleClickConfirmButton = async () => {
    setIsLoading(true);
    if (personaInquiryId) {
      // Get the redirect URI from environment variable
      const redirectUrl = `https://inquiry.withpersona.com/inquiry?inquiry-id=${personaInquiryId}&redirect-uri=${process.env.REACT_APP_KYC_REDIRECT_URI}`;
      window.open(redirectUrl, "_self");

      setModalIsOpen(false);
    }
  };

  const CardBody = () => (
    <>
      {" "}
      {!isLoading ? (
        <div>
          <>
            <div
              className={cx(styles.acceptTerms, {
                [styles.acceptTermsMob]: isMobile,
              })}
            >
              Are you sure want to continue?
            </div>

            <div
              className={cx(styles.buttonContainer, {
                [styles.buttonContainerMob]: isMobile,
              })}
            >
              <ButtonRade
                className={cx(styles.cancelButton, {
                  [styles.cancelButtonMob]: isMobile,
                })}
                onClick={handleClickCancelButton}
                disabled={isLoading} // Disable button while loading
              >
                {POPUP_ENUM.cancel}
              </ButtonRade>

              {/* Show loader while loading */}

              <ButtonRade
                className={cx(styles.continueButton, {
                  [styles.continueButtonMob]: isMobile,
                })}
                onClick={handleClickConfirmButton}
              >
                {POPUP_ENUM.submit}
              </ButtonRade>
            </div>
          </>
        </div>
      ) : (
        <div className={styles.loaderContainer}>
          You will be redirected to Persona for completion of KYC.
          <div className={styles.spinnerOverlay}>
            <div className={styles.spinnerContainer} />
          </div>
        </div>
      )}
    </>
  );

  return CardBody();
};

export default ConfirmReSubmitKYC;
