import React, { useContext } from "react";
import styles from "./index.module.scss";
import { POPUP_ENUM } from "../../enums/popUpEnum";
import cx from "classnames";
import { AppContext } from "../../context";
import ButtonRade from "../../components/RadeButtons";
import { HEADER_ENUM } from "../../enums/headerEnum";
import { useNavigate } from "react-router-dom";

const PasswordResetSuccessful = ({
  setGoogleLoginPageNumber,
  setOnClickLoginButton,
}) => {
  const { isMobile } = useContext(AppContext);
  const navigate = useNavigate(); // Use the navigate hook
  const handleClickLogin = () => {
    navigate("/send");
    setGoogleLoginPageNumber(1);
    setOnClickLoginButton(true);
  };
  const CardBody = () => (
    <div>
      <div
        className={cx(styles.serviceTicketSubmittedTitle, {
          [styles.serviceTicketSubmittedTitleMob]: isMobile,
        })}
      >
        {POPUP_ENUM.passwordResetSuccessful}
      </div>
      <div
        className={cx(styles.serviceTicketSubmittedContent, {
          [styles.serviceTicketSubmittedContentMob]: isMobile,
        })}
      >
        {POPUP_ENUM.loginToContinueToApp}
      </div>
      <div
        className={cx(styles.buttonContainer, {
          [styles.buttonContainerMob]: isMobile,
        })}
      >
        <ButtonRade
          className={cx(styles.continueButton, {
            [styles.continueButtonMob]: isMobile,
          })}
          onClick={handleClickLogin}
        >
          {HEADER_ENUM.loginButton}
        </ButtonRade>
      </div>
    </div>
  );
  return CardBody();
};

export default PasswordResetSuccessful;
