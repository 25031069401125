export async function getCybridCustomer(idToken, customerGUID) {
  var requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
  };

  const updateCustomerResponse = await fetch(
    `${process.env.REACT_APP_PAYEZY_SERVER_URI}/get-cybrid-customer/${customerGUID}`,
    requestOptions
  );

  const updateResponseData = await updateCustomerResponse.json();
  return updateResponseData;
}
