import React from "react";
import cx from "classnames";
import { useContext, useState, useEffect } from "react";
import Modal from "react-modal";
import { getDoc, doc, collection, setDoc, updateDoc } from "firebase/firestore";
import { AppContext } from "../../context";
import Card from "../../components/Card/Card";
import { SEND_ENUM } from "../../enums/sendEnum";
import FetchUserDetails from "../EnterReceiverDetails/EnterReceiverDetails";
import { db } from "../../firebase";
import CompleteKycModal from "../CompleteKYCModel/CompleteKYCModel";
import styles from "./index.module.scss";
import SendINR from "../SendINR/SendINR";
import BankAccountsMain from "../BankAccountsMain/BankAccountsMain";
import { v4 as uuidv4 } from "uuid";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import mixpanel from "mixpanel-browser";
import MaintenanceCardBody from "../MaintenanceCardBody/MaintenanceCardBody";

const MIN_ALLOWED_TRANSFER = 10; //varibale that store minimum allowed tranfer
const MAX_DECIMAL_PLACE = 2;
const SendINRLandingPage = ({
  profileEmail,
  setAmountInUSD,
  amountInUSD,
  amountInINR,
  setAmountInINR,
  usdToInrExRate,
  appState,
  cybridCounterpartyGuid,
  setCybridCounterpartyGuid,
  newExchangeRate,
  setNewExchangeRate,
  userSelectionMode,
  setUserSelectionMode,
  customerActivityLimit,
  customerGuid,
}) => {
  const [errorForLogin, setErrorForLogin] = useState(false); //state for storing login error
  const [fetchingPrice, setFetchingPrice] = useState(false); //state for fetching USD price
  const [modalIsOpen, setModalIsOpen] = useState(false); // state for opening the modal
  const [promotionCode, setPromotionCode] = useState("");
  const [secureToken, setSecureToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [paymentType, setPaymentType] = useState(SEND_ENUM.bankTransfer); //state that stored payment type
  const renderTooltip1 = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle}>
      Coming soon..
    </Tooltip>
  );
  const {
    isMobile,
    setSendFlowPageNumber,
    sendFlowPageNumber,
    setGoogleLoginPageNumber,
    setOnClickLoginButton,
    recipientId,
    setRecipientId,
    isAnimationRequired,
    setIsAnimationRequired,
    isNavBarClicked,
    setIsNavbarClicked,
    setTxData,
    setTransactionId,
    transactionId,
  } = useContext(AppContext);

  //Fetch the treasury balance in INR
  useEffect(() => {
    if (!isNavBarClicked) {
      // setIsAnimationRequired(true);
    }
  }, [setIsAnimationRequired]);

  useEffect(() => {
    // Scroll to the top of the page when sendFlowPageNumber changes
    window.scrollTo(0, 0);
  }, [sendFlowPageNumber]);
  
  // Handles the click on the Proceed button
  const handleClickProceedButton = async () => {
    setIsNavbarClicked(true);
    // Check if required fields are filled and amount is valid
    if (!profileEmail && amountInUSD && amountInUSD >= MIN_ALLOWED_TRANSFER) {
      // Proceed to the next page in the Google login flow
      setGoogleLoginPageNumber(0);
      setOnClickLoginButton(true);
    } else if (!amountInUSD && userSelectionMode === SEND_ENUM.usdEnterMode) {
      // Show an error message if the user did not enter a valid amount of USD
      setErrorForLogin("Please enter a valid amount of USD to continue");
    } else if (!amountInINR && userSelectionMode === SEND_ENUM.inrEnterMode) {
      // Show an error message if the user did not enter a valid amount of INR
      setErrorForLogin("Please enter a valid amount of INR to continue");
    } else if (appState === "Restricted") {
      // Show an error message if the user did not enter a valid amount of USD
      setErrorForLogin(
        "Transfer requests are temporarily on hold. Please try again after some time. We apologize for the inconvenience caused and appreciate your patience."
      );
    } else if (amountInUSD < MIN_ALLOWED_TRANSFER) {
      // Show an error message if the amount is less than the minimum required ($100)
      setErrorForLogin("Only orders with a minimum of $10 will be processed.");
    } else if (customerGuid) {
      if (amountInUSD > customerActivityLimit) {
        // Show an error message if the amount exceeds the customerActivityLimit for KYC started users
        setErrorForLogin("Maximum allowed transfer exceeded");
      } else {
        // For users with customer_guid, proceed with KYC status check
        setIsLoading(true);
        // Query the document to retrieve KYCStatus
        const usersRef = collection(db, "userData");
        const userDocRef = doc(usersRef, profileEmail);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          // If the user data exists, check their KYC status
          const userData = userDocSnapshot.data();
          if (userData.customer_state === "verified") {
            // Capture the amount and exchange rate in USD // to be discussed
            setTxData({ amount: amountInUSD, exchange_rate: usdToInrExRate });
            // Create a new transaction document in the "transactions" collection
            // TODO: create-transaction-id server call reqParams: profileEmail
            const transactionId = uuidv4(); // Generate a unique ID using uuid
            setTransactionId(transactionId);
            // Reference to the transaction inside the transactions collection for the current profileEmail
            const transactionRef = doc(
              collection(db, "transactions", profileEmail, "userTransactions"),
              transactionId
            );
            if (promotionCode && secureToken) {
              // Set the transaction data
              await setDoc(transactionRef, {
                sender_id: profileEmail, // Sender's email
                receiver_id: "", // Empty for now, will be added later
                transaction_details: {
                  amount_in_usd: Number(amountInUSD).toFixed(MAX_DECIMAL_PLACE), // Amount entered by the sender in USD
                  base_exchange_rate: usdToInrExRate, // Exchange rate
                  amount_in_inr: Number(amountInINR).toFixed(MAX_DECIMAL_PLACE), // Amount in INR (calculated)
                  promotion: {
                    promo_code: promotionCode,
                    secure_token: secureToken,
                  },
                  status: "created",
                },
              });
            } else {
              // Set the transaction data
              await setDoc(transactionRef, {
                sender_id: profileEmail, // Sender's email
                receiver_id: "", // Empty for now, will be added later
                transaction_details: {
                  amount_in_usd: Number(amountInUSD).toFixed(MAX_DECIMAL_PLACE), // Amount entered by the sender in USD
                  base_exchange_rate: usdToInrExRate, // Exchange rate
                  amount_in_inr: Number(amountInINR).toFixed(MAX_DECIMAL_PLACE), // Amount in INR (calculated)
                  promotion: {
                    promo_code: "",
                    secure_token: "",
                  },
                  status: "created",
                },
              });
            }
            // Update transaction_details by adding transactionId
            const updatedTransactionDetails = {
              ...userData.transaction_details,
              ...{
                [Object.keys(userData.transaction_details).length]:
                  transactionId,
              },
            };
            // Update the userData document with the new transaction_details
            await updateDoc(userDocRef, {
              transaction_details: updatedTransactionDetails,
            });
            // If KYC status is "Verified," proceed to the send flow
            setSendFlowPageNumber(1);
            if (process.env.REACT_APP_DEPLOYMENT_MODE === "PRODUCTION") {
              mixpanel.track("Clicking proceed after price discovery!");
            }
            setIsLoading(false);
          } else {
            // If KYC status is not "Verified," open a modal for further actions
            setModalIsOpen(true);
            setIsLoading(false);
          }
        } else {
          // If user data doesn't exist, open a modal for further actions
          setModalIsOpen(true);
          setIsLoading(false);
        }
      }
    } else {
      // No customer_guid, show modal to prompt KYC
      setModalIsOpen(true);
    }
  };
  // Function to handle payment type options
  const handleClickPaymentMethod = (paymentType) => {
    setPaymentType(paymentType);
  };
  // Renders the components based on the sendFlowPageNumber
  const renderComponents = () => {
    switch (sendFlowPageNumber) {
      case 1:
        return (
          <FetchUserDetails
            profileEmail={profileEmail}
            setSendFlowPageNumber={setSendFlowPageNumber}
            sendFlowPageNumber={sendFlowPageNumber}
            setRecipientId={setRecipientId}
            setCybridCounterpartyGuid={setCybridCounterpartyGuid}
          />
        );
      case 2:
        return (
          <>
            <BankAccountsMain
              amountInINR={amountInINR}
              amountInUSD={amountInUSD}
              usdToInrExRate={usdToInrExRate}
              setSendFlowPageNumber={setSendFlowPageNumber}
              profileEmail={profileEmail}
              transactionId={transactionId}
              cybridCounterpartyGuid={cybridCounterpartyGuid}
              recipientId={recipientId}
              newExchangeRate={newExchangeRate}
              setNewExchangeRate={setNewExchangeRate}
            />
          </>
        );

      default:
        return (
          <div
            className={cx(styles.routesContainer, {
              [styles.routesContainerMob]: isMobile,
            })}
          >
            <Card
              titleComponent={<CardTitle />}
              isAnimationRequired={isAnimationRequired}
            >
              {appState === "Pause/Maintenance" ? (
                <MaintenanceCardBody />
              ) : (
                CardBody()
              )}
            </Card>
          </div>
        );
    }
  };

  const CardTitle = () => (
    <>
      <div className={styles.processPaymentVia}>Process payment via</div>
      <div
        className={cx(styles.enterAmountOfUSDWishToSend, {
          [styles.enterAmountOfUSDWishToSendMob]: isMobile,
        })}
      >
        <div
          onClick={() => handleClickPaymentMethod(SEND_ENUM.bankTransfer)}
          className={cx(styles.commonOptionBankTranfer, {
            [styles.selectedOptionBankTranfer]:
              paymentType === SEND_ENUM.bankTransfer,
          })}
        >
          {SEND_ENUM.bankTransfer}
        </div>
        <OverlayTrigger placement="top" overlay={renderTooltip1}>
          <div
            className={cx(styles.commonOptionCardPayment, {
              [styles.selectedOptionCardPayment]:
                paymentType === SEND_ENUM.cardPayment,
            })}
            onClick={(e) => {
              e.preventDefault(); // Prevent click event
            }}
          >
            {SEND_ENUM.cardPayment}
          </div>
        </OverlayTrigger>

        <div
          className={cx(styles.horizontalLine, {
            [styles.horizontalLineMob]: isMobile,
          })}
        ></div>
      </div>
    </>
  );

  const CardBody = () => (
    <SendINR
      fetchingPrice={fetchingPrice}
      errorForLogin={errorForLogin}
      handleClickProceedButton={handleClickProceedButton}
      amountInINR={amountInINR}
      usdToInrExRate={usdToInrExRate}
      amountInUSD={amountInUSD}
      setFetchingPrice={setFetchingPrice}
      setAmountInINR={setAmountInINR}
      setPromotionCode={setPromotionCode}
      promotionCode={promotionCode}
      setSecureToken={setSecureToken}
      secureToken={secureToken}
      setNewExchangeRate={setNewExchangeRate}
      newExchangeRate={newExchangeRate}
      customerActivityLimit={customerActivityLimit}
      isLoading={isLoading}
      userSelectionMode={userSelectionMode}
      setUserSelectionMode={setUserSelectionMode}
      setErrorForLogin={setErrorForLogin}
      setAmountInUSD={setAmountInUSD}
    />
  );

  return (
    <div className={styles.sendINRLandingPageContainer}>
      {renderComponents()}

      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <CompleteKycModal setModalIsOpen={setModalIsOpen} />
      </Modal>
    </div>
  );
};

export default SendINRLandingPage;
