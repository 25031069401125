import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import ButtonRade from "../RadeButtons";
import { SEND_ENUM } from "../../enums/sendEnum";
import { AppContext } from "../../context";
import { signOutHandler } from "../../firebase";
import styles from "./index.module.scss";

const GoogleLogout = ({
  setModalIsOpen,
  setProfileEmail,
  setAmountInINR,
  setAmountInUSD,
  setSendFlowPageNumber,
}) => {
  const { setSessionId } = useContext(AppContext);
  let navigate = useNavigate();
  const handleClickLogout = async () => {
    setSessionId(null);
    setAmountInINR(null);
    setAmountInUSD(null);
    setModalIsOpen(false);
    await signOutHandler();
    setProfileEmail(null);
    navigate("/send");
    setSendFlowPageNumber(0);
    localStorage.removeItem("transactionId");
  };

  //Function to close the Modal.
  const handleOnClickCancel = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <div className={styles.popUpContent}>{SEND_ENUM.logoutMessage}</div>
      <div className={styles.popUpButtonDiv}>
        <ButtonRade
          outline
          customStyling={styles.cancelButton}
          onClick={handleOnClickCancel}
        >
          {SEND_ENUM.cancel}
        </ButtonRade>
        <ButtonRade
          outline
          customStyling={styles.continueButton}
          onClick={handleClickLogout}
        >
          {SEND_ENUM.logout}
        </ButtonRade>
      </div>
    </div>
  );
};

export default GoogleLogout;
