import React, { useContext, useEffect, useState } from "react";
import styles from "./BankAccountsMain.module.scss";
import cx from "classnames";
import { AppContext } from "../../context";
import { transferMoney } from "../../api/utilities/transferMoney";
import { SEND_ENUM } from "../../enums/sendEnum";
import Card from "../../components/Card/Card";
import ButtonRade from "../../components/RadeButtons";
import { fetchExtBankAccounts } from "../../api/utilities/fetchExtBankAccount";
import Modal from "react-modal";
import ConfirmDeleteExtBankAccount from "../ConfirmDeleteExtBankAccount/ConfirmDeleteExtBankAccount";
import { useLocation, useNavigate } from "react-router-dom";
import SmallPlusIcon from "../../assets/smallPlusIcon";
import TransactionProgressModal from "../TransactionProgressModal/TransactionProgressModal";
import trashBinIcon from "../../assets/trashBinLogo.svg";
import PlaidConnect from "../PlaidConnect/PlaidConnectNew";
import PlaidConnectRefresh from "../PlaidConnect/PlaidConnectRefresh";
import mixpanel from "mixpanel-browser";
import RefreshIcon from "../../assets/RefreshIcon";
import { auth } from "../../firebase";
import BankVerificationFromPersona from "../BankVerificationFromPersona/BankVerificationFromPersona";

const TO_FIXED_TWO = 2;
const BankAccountsMain = ({
  amountInINR,
  amountInUSD,
  usdToInrExRate,
  setSendFlowPageNumber,
  profileEmail,
  transactionId,
  cybridCounterpartyGuid,
  recipientId,
  newExchangeRate,
  setNewExchangeRate,
  onNextFromRecurring,
  onBackFromRecurring,
}) => {
  const { isMobile, userData } = useContext(AppContext);
  const location = useLocation();
  let navigate = useNavigate();
  // Route check function
  const isConnectBankRoute = () => location.pathname === "/connectBank";
  const isRecurringTransfersCreateRoute = () =>
    location.pathname === "/autoSend/create";
  const senderFullName = `${userData.user_pii.name.first || ""} ${
    userData.user_pii.name.middle ? userData.user_pii.name.middle + " " : ""
  }${userData.user_pii.name.last || ""}`.trim();
  // State to set the connected bank accounts of user
  const [bankAccounts, setBankAccounts] = useState({ total: 0, objects: [] });
  // State to set the selected account by user
  const [selectedAccount, setSelectedAccount] = useState(null);
  // State to set the loading
  const [isLoading, setIsLoading] = useState(false);
  // State to set the Modal open or close
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // State for controlling different modal open based on page number
  const [modalPageNumber, setModalPageNumber] = useState(false);
  // State to set the selected account id
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  // State to set the selected account name
  const [selectedAccountName, setSelectedAccountName] = useState(null);
  // State to set the error message
  const [errorMessage, setErrorMessage] = useState("");
  // Code to fetch the connected bank accounts
  const fetchAccounts = async () => {
    setErrorMessage("");
    try {
      setIsLoading(true);
      const accounts = await fetchExtBankAccounts(
        userData.cybrid_customer_details.customer_guid
      );
      setBankAccounts(accounts);
    } catch (error) {
      console.log("Error:", error);
      setIsLoading(false);
      setErrorMessage(
        "Something went wrong while fetching your bank accounts. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchAccounts();
  }, []);

  const handleAccountSelection = (account) => {
    console.log("Selected account:", account);
    setSelectedAccount(account);
  };
  console.log("bankAccounts", bankAccounts);
  // Handle add bank account button on click
  const handleAddBankAccount = () => {
    setErrorMessage("");
    // Redirect to PlaidConnectNew component
    // Update the handleAddBankAccount function
    setModalIsOpen(true);
    setModalPageNumber(2);
  };

  const handleVerifyAccount = async (external_bank_guid) => {
    setErrorMessage("");
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      };
      const response = await fetch(
        `${process.env.REACT_APP_PAYEZY_SERVER_URI}/verify-external-bank-account/${external_bank_guid}`,
        requestOptions
      );
      const responseData = await response.json();
      console.log("responseData", responseData);
      if (responseData.state === "waiting" && responseData.persona_inquiry_id) {
        setModalIsOpen(true);
        setModalPageNumber(4);
        setTimeout(() => {
          const redirectUrl = `https://inquiry.withpersona.com/inquiry?inquiry-id=${responseData.persona_inquiry_id}&redirect-uri=${process.env.REACT_APP_BANK_VERIFICATION_REDIRECT_URI}`;
          window.open(redirectUrl, "_self");
        }, 3000);
        setModalIsOpen(false);
      } else {
        console.log("Different state or missing persona_inquiry_id");
        setErrorMessage(
          "Something went wrong while verifying your bank account. Please try again."
        );
      }
    } catch (error) {
      console.error("Error verifying account:", error);
      setErrorMessage(
        "Something went wrong while verifying your bank account. Please try again."
      );
    }
  };

  const handleUpdateBankAccount = async (accountId, accountName) => {
    setErrorMessage("");
    setSelectedAccountId(accountId);
    setSelectedAccountName(accountName);
    setModalIsOpen(true);
    setModalPageNumber(3);
  };

  // Handle cancel button click
  const handleClickCancel = () => {
    setErrorMessage("");
    setSendFlowPageNumber(0);
    localStorage.removeItem("transactionId");
  };
  // Handle send money button on click
  const handleSendMoney = async () => {
    setErrorMessage("");
    try {
      setModalIsOpen(true);
      setModalPageNumber(1);
      await transferMoney(
        selectedAccount,
        userData.cybrid_customer_details.customer_guid,
        profileEmail,
        senderFullName,
        transactionId,
        cybridCounterpartyGuid,
        recipientId,
        navigate,
        setSendFlowPageNumber,
        setModalIsOpen,
        setNewExchangeRate
      );
      if (process.env.REACT_APP_DEPLOYMENT_MODE === "PRODUCTION") {
        mixpanel.track("Sending Trasnfer Request!");
      }
      // Handle successful transfer (e.g., show success message)
      console.log("Money transferred successfully");
    } catch (error) {
      // Handle transfer error (e.g., show error message)
      console.error("Error transferring money:", error);
      // You might want to set an error state here and display it to the user
      setErrorMessage(
        "Something went wrong while transferring money. Please try again."
      );
    }
  };

  // Handle connected account delete button on click
  const handleDeleteAccount = async (accountId, accountName) => {
    setErrorMessage("");
    setModalIsOpen(true);
    setModalPageNumber(0);
    setSelectedAccountId(accountId);
    setSelectedAccountName(accountName);
  };

  // Handle continue button click for recurring transfers
  const handleContinue = () => {
    if (selectedAccount && onNextFromRecurring) {
      onNextFromRecurring(
        selectedAccount,
        userData.cybrid_customer_details.customer_guid,
        profileEmail,
        senderFullName,
        cybridCounterpartyGuid,
        recipientId
      );
    }
  };

  // CardTitle contents
  const CardTitle = () => (
    <div
      className={cx(styles.titleContainer, {
        [styles.titleContainerMob]: isMobile,
      })}
    >
      {SEND_ENUM.connectedBankAccounts}
    </div>
  );

  // CardBody contents
  const CardBody = () => (
    <div
      className={cx(styles.bodyContainer, {
        [styles.bodyContainerMob]: isMobile,
      })}
    >
      {isLoading ? (
        <div className={styles.spinnerOverlay}>
          <div className={styles.spinnerContainer} />
        </div>
      ) : (
        <>
          {isRecurringTransfersCreateRoute() && (
            <div className={styles.headerContainerIfRecurringRoute}>
              {SEND_ENUM.connectedBankAccounts}
            </div>
          )}
          <>
            {bankAccounts?.objects?.length > 0 ? (
              <>
                {!isConnectBankRoute() && (
                  <div
                    className={cx(styles.selectConnectedAccount, {
                      [styles.selectConnectedAccountMob]: isMobile,
                      [styles.selectConnectedAccountIfNothingSelected]:
                        !selectedAccount,
                    })}
                  >
                    {SEND_ENUM.selectConnectedAccount}
                  </div>
                )}
                <ul className={styles.accountList}>
                  {bankAccounts.objects.map((account) => (
                    <li
                      key={account.guid}
                      className={cx(styles.accountItem, {
                        [styles.accountItemMob]: isMobile,
                      })}
                    >
                      {!isConnectBankRoute() && (
                        <div
                          className={cx(styles.radioContainer, {
                            [styles.radioContainerMob]: isMobile,
                          })}
                        >
                          <label className={styles.radioLabel}>
                            <input
                              type="radio"
                              id={account.guid}
                              name="bankAccount"
                              checked={
                                selectedAccount &&
                                selectedAccount.guid === account.guid
                              }
                              onChange={() => handleAccountSelection(account)}
                              disabled={
                                account.state === "refresh_required" ||
                                account.state === "unverified"
                              }
                            />
                            <span className={styles.accountMask}>
                              {account.plaid_account_mask}
                            </span>
                            <span className={styles.accountName}>
                              {account.plaid_account_name}
                            </span>
                            {account.state === "refresh_required" && (
                              <span className={styles.reconnectRequired}>
                                {SEND_ENUM.reconnectRequired}
                              </span>
                            )}
                            {account.state === "unverified" && (
                              <span className={styles.reconnectRequired}>
                                {SEND_ENUM.verificationRequired}
                              </span>
                            )}
                          </label>
                        </div>
                      )}
                      {isConnectBankRoute() && (
                        <div>
                          <div
                            className={cx(styles.connectedBanksBoxes, {
                              [styles.connectedBanksBoxesMob]: isMobile,
                            })}
                          >
                            <span className={styles.accountMask}>
                              {account.plaid_account_mask}
                            </span>
                            <span className={styles.accountName}>
                              {account.plaid_account_name}
                            </span>
                            {account.state === "refresh_required" && (
                              <span className={styles.reconnectRequired}>
                                {SEND_ENUM.reconnectRequired}
                              </span>
                            )}
                            {account.state === "unverified" && (
                              <span className={styles.reconnectRequired}>
                                {SEND_ENUM.verificationRequired}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                      <div className={styles.deleteAndRefreshButton}>
                        <ButtonRade
                          onClick={() =>
                            handleDeleteAccount(
                              account.guid,
                              account.plaid_account_name
                            )
                          }
                          data-tooltip={SEND_ENUM.deleteButton}
                          customStyling={cx(
                            styles.deleteButton,
                            styles.tooltip,
                            {
                              [styles.deleteButtonMob]: isMobile,
                            }
                          )}
                        >
                          <img
                            src={trashBinIcon}
                            alt="trashBinIcon"
                            className={styles.trashBinIcon}
                          />
                          {isMobile && (
                            <div className={styles.removeText}>
                              {SEND_ENUM.removeButton}
                            </div>
                          )}
                        </ButtonRade>
                        {account.state === "unverified" ? (
                          <ButtonRade
                            onClick={() => handleVerifyAccount(account.guid)}
                            customStyling={cx(styles.verifyButton, {
                              [styles.verifyButtonMob]: isMobile,
                            })}
                          >
                            <RefreshIcon className={styles.refreshIcon} />
                            <div className={styles.refreshText}>
                              {SEND_ENUM.verifyButton}
                            </div>
                          </ButtonRade>
                        ) : (
                          <ButtonRade
                            onClick={() =>
                              handleUpdateBankAccount(
                                account.guid,
                                account.plaid_account_name
                              )
                            }
                            disabled={account.state !== "refresh_required"}
                            customStyling={cx(styles.refreshButton, {
                              [styles.refreshButtonMob]: isMobile,
                              [styles.refreshButtonDisabled]:
                                account.state !== "refresh_required",
                              [styles.refreshButtonMobAndDisabled]:
                                isMobile &&
                                account.state !== "refresh_required",
                            })}
                          >
                            <RefreshIcon className={styles.refreshIcon} />
                            <div className={styles.refreshText}>
                              {SEND_ENUM.reconnectButton}
                            </div>
                          </ButtonRade>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
                <div className={styles.connectMoreBanks}>
                  <div
                    className={cx(styles.connectMoreBanksContent, {
                      [styles.connectMoreBanksContentMob]: isMobile,
                    })}
                  >
                    {SEND_ENUM.connectMoreBanksContent}
                  </div>
                  <div
                    className={cx(styles.addBankButtonContainer, {
                      [styles.addBankButtonContainerIfConnectBankRoute]:
                        isConnectBankRoute() ||
                        isRecurringTransfersCreateRoute(),
                    })}
                  >
                    <ButtonRade
                      customStyling={cx(styles.addMoreBankButton, {
                        [styles.addMoreBankButtonMob]: isMobile,
                      })}
                      onClick={handleAddBankAccount}
                    >
                      {SEND_ENUM.addBank}{" "}
                      <SmallPlusIcon className={styles.smallPlusSign} />
                    </ButtonRade>
                  </div>
                </div>
              </>
            ) : (
              <div
                className={cx(styles.noBankConnectedDetails, {
                  [styles.noBankConnectedDetailsMob]: isMobile,
                })}
              >
                <div
                  className={cx(styles.noBankAccounts, {
                    [styles.noBankAccountsMob]: isMobile,
                  })}
                >
                  {SEND_ENUM.noBankAccountsConnected}
                </div>
                <div
                  className={cx(styles.connectBankContainer, {
                    [styles.connectBankContainerMob]: isMobile,
                  })}
                >
                  <ButtonRade
                    customStyling={cx(styles.connectBankButton, {
                      [styles.connectBankButtonMob]: isMobile,
                    })}
                    onClick={handleAddBankAccount}
                  >
                    {SEND_ENUM.connectBankButton}{" "}
                    <SmallPlusIcon className={styles.smallPlusSign} />
                  </ButtonRade>
                </div>
              </div>
            )}
          </>
          {!isConnectBankRoute() && !isRecurringTransfersCreateRoute() && (
            <div className={styles.transferDetailsMainContainer}>
              <div className={styles.transferDetailsHeading}>
                {SEND_ENUM.transferDetails}
              </div>
              <div
                className={cx(styles.transferDetails, {
                  [styles.transferDetailsMob]: isMobile,
                })}
              >
                <div
                  className={cx(styles.youSendKey, {
                    [styles.youSendKeyMob]: isMobile,
                  })}
                >
                  {SEND_ENUM.youSend}
                </div>
                <div
                  className={cx(styles.valueInUsd, {
                    [styles.valueInUsdMob]: isMobile,
                  })}
                >
                  $ {Number(amountInUSD).toFixed(TO_FIXED_TWO)}
                </div>
              </div>
              <div
                className={cx(styles.transferDetails, {
                  [styles.transferDetailsMob]: isMobile,
                })}
              >
                <div
                  className={cx(styles.recipientGetsKey, {
                    [styles.recipientGetsKeyMob]: isMobile,
                  })}
                >
                  {SEND_ENUM.recipientGets}
                </div>
                <div
                  className={cx(styles.valueInInr, {
                    [styles.valueInInrMob]: isMobile,
                  })}
                >
                  ₹ {Number(amountInINR).toFixed(TO_FIXED_TWO)}
                </div>
              </div>
              <div
                className={cx(styles.transferDetails, {
                  [styles.transferDetailsMob]: isMobile,
                })}
              >
                <div
                  className={cx(styles.exchangeRateKey, {
                    [styles.exchangeRateKeyMob]: isMobile,
                  })}
                >
                  {newExchangeRate
                    ? SEND_ENUM.appliedPromoRate
                    : SEND_ENUM.payezyFxRate}
                </div>
                <div
                  className={cx(styles.exchangeRateValue, {
                    [styles.exchangeRateValueMob]: isMobile,
                  })}
                >
                  {newExchangeRate
                    ? Number(newExchangeRate).toFixed(TO_FIXED_TWO)
                    : Number(usdToInrExRate).toFixed(TO_FIXED_TWO)}{" "}
                  {SEND_ENUM.inrPerUsd}
                </div>
              </div>
            </div>
          )}
          {!isConnectBankRoute() && (
            <div
              className={cx(styles.buttonsContainer, {
                [styles.buttonsContainerMob]: isMobile,
              })}
            >
              <ButtonRade
                customStyling={cx(styles.cancelButton, {
                  [styles.cancelButtonMob]: isMobile,
                })}
                onClick={
                  isRecurringTransfersCreateRoute()
                    ? onBackFromRecurring
                    : handleClickCancel
                }
              >
                {SEND_ENUM.cancel}
              </ButtonRade>
              <ButtonRade
                customStyling={cx(styles.sendMoneyButton, {
                  [styles.sendMoneyButtonMob]: isMobile,
                  [styles.disabledSendMoneyButton]: !selectedAccount,
                  [styles.disabledAndMob]: isMobile && selectedAccount,
                })}
                onClick={
                  isRecurringTransfersCreateRoute()
                    ? handleContinue
                    : handleSendMoney
                }
                disabled={!selectedAccount}
              >
                {isRecurringTransfersCreateRoute()
                  ? "Continue"
                  : SEND_ENUM.sendMoneyButton}
              </ButtonRade>
            </div>
          )}
          {errorMessage && (
            <div className={styles.errorMessage}>{errorMessage}</div>
          )}
        </>
      )}
    </div>
  );

  return (
    <>
      {isRecurringTransfersCreateRoute() ? (
        CardBody()
      ) : (
        <Card titleComponent={<CardTitle />}>{CardBody()}</Card>
      )}
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        {(() => {
          switch (modalPageNumber) {
            case 0:
              return (
                <ConfirmDeleteExtBankAccount
                  setModalIsOpen={setModalIsOpen}
                  setBankAccounts={setBankAccounts}
                  selectedAccountId={selectedAccountId}
                  selectedAccountName={selectedAccountName}
                  setErrorMessage={setErrorMessage}
                />
              );
            case 1:
              return <TransactionProgressModal />;
            case 2:
              return (
                <PlaidConnect
                  fetchAccounts={fetchAccounts}
                  setModalIsOpen={setModalIsOpen}
                  setIsLoading={setIsLoading}
                  setErrorMessage={setErrorMessage}
                />
              );
            case 3:
              return (
                <PlaidConnectRefresh
                  fetchAccounts={fetchAccounts}
                  setModalIsOpen={setModalIsOpen}
                  setIsLoading={setIsLoading}
                  selectedAccountId={selectedAccountId}
                  setErrorMessage={setErrorMessage}
                />
              );
            case 4:
              return <BankVerificationFromPersona />;
            default:
              return null;
          }
        })()}
      </Modal>
    </>
  );
};

export default BankAccountsMain;
