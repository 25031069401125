import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import RecurringTransferSchedule from "./RecurringTransferSchedule";
import RecurringTransferConfirmation from "./RecurringTransferConfirmation";
import EnterReceiverDetails from "../EnterReceiverDetails/EnterReceiverDetails";
import BankAccountsMain from "../BankAccountsMain/BankAccountsMain";
import { createRecurringTransfer } from "../../api/recurringTransfers";
import { useContext } from "react";
import { AppContext } from "../../context";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import {
  RECURRING_TRANSFER_ENUM,
  TransferFrequency,
  TransferScheduleType,
} from "../../enums/transferEnums";
import Card from "../../components/Card/Card";
import cx from "classnames";
import Modal from "react-modal";
import styles from "./CreateRecurringTransfer.module.scss";
import CreatingRecurringTransferModal from "./CreatingRecurringTransferModal";
import RecurringTransferCreatedModal from "./RecurringTransferCreatedModal";

const STEPS = {
  SCHEDULE: 0,
  BENEFICIARY: 1,
  BANK_ACCOUNT: 2,
  CONFIRMATION: 3,
};

export default function CreateRecurringTransfer({ customerActivityLimit }) {
  const navigate = useNavigate();
  const { profileEmail, setTxData, userData, isMobile } =
    useContext(AppContext);

  const [currentStep, setCurrentStep] = useState(STEPS.SCHEDULE);
  const [formData, setFormData] = useState({
    amount_in_usd: "",
    frequency: "",
    start_date: "",
    receiver_details: {
      receiver_id: "",
      receiver_email: "",
      receiver_nickname: "",
      receiver_name: "",
      receiver_phone: "",
      receiver_bank_number: "",
      receiver_bank_ifsc: "",
      receiver_bank_name: "",
      cybrid_counterparty_guid: "",
    },
    bank_account: null,
    customer_guid: "",
    sender_details: {
      sender_email: profileEmail,
      sender_name: userData?.user_pii?.name?.full || "",
      account_four_digits: "",
      external_bank_account_guid: "",
    },
  });
  const [sendFlowPageNumber, setSendFlowPageNumber] = useState(0);
  const [recipientId, setRecipientId] = useState(null);
  const [cybridCounterpartyGuid, setCybridCounterpartyGuid] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // State to set the Modal open or close
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // State for controlling different modal open based on page number
  const [modalPageNumber, setModalPageNumber] = useState(false);

  useEffect(() => {
    // Sync currentStep with sendFlowPageNumber
    setCurrentStep(sendFlowPageNumber);
    // Scroll to the top of the page when sendFlowPageNumber changes
    window.scrollTo(0, 0);
  }, [sendFlowPageNumber]);

  const handleScheduleNext = () => {
    setIsLoading(true);

    setTxData({
      amount: formData.amount_in_usd,
      frequency: formData.frequency,
      start_date: formData.start_date,
    });

    setSendFlowPageNumber(STEPS.BENEFICIARY);
    setIsLoading(false);
  };

  const handleBeneficiaryNext = async () => {
    setSendFlowPageNumber(STEPS.BANK_ACCOUNT);
    let receiverDetails = null;
    try {
      // Fetch the receiver details using recipientId from firebase
      const recipientDocRef = doc(
        db,
        "counterpartyDetails",
        profileEmail,
        "userCounterpartyDetails",
        recipientId
      );
      const recipientSnap = await getDoc(recipientDocRef);
      if (!recipientSnap.exists()) {
        throw new Error("Receiver not found");
      }
      const recipientData = recipientSnap.data();
      receiverDetails = {
        receiver_id: recipientId,
        receiver_email: recipientData.counterparty_pii.email,
        receiver_nickname: recipientData.counterparty_pii.nick_name,
        receiver_name: recipientData.counterparty_pii.name.full,
        receiver_phone: recipientData.counterparty_pii.phone_no,
        receiver_bank_number: recipientData.counterparty_pii.account_no,
        receiver_bank_ifsc: recipientData.counterparty_pii.ifsc_code,
        receiver_bank_name: recipientData.counterparty_pii.bank_name,
        cybrid_counterparty_guid: cybridCounterpartyGuid,
      };
      console.log("recipientData", recipientData);
    } catch (error) {
      console.error("Error fetching recipient details:", error);
      throw error;
    }

    // Extract all necessary receiver details from the EnterReceiverDetails component

    setFormData((prev) => ({
      ...prev,
      receiver_details: receiverDetails,
    }));
  };
  const handleBankAccountNext = (
    selectedAccount,
    customer_guid,
    profileEmail,
    senderFullName,
    cybridCounterpartyGuid,
    recipientId
  ) => {
    setFormData((prev) => ({
      ...prev,
      bank_account: selectedAccount,
      customer_guid: customer_guid,
      sender_details: {
        ...prev.sender_details,
        sender_email: profileEmail,
        sender_name: senderFullName,
        account_four_digits: selectedAccount.plaid_account_mask,
        external_bank_account_guid: selectedAccount.guid,
      },
    }));
    setSendFlowPageNumber(STEPS.CONFIRMATION);
  };

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      setModalIsOpen(true);
      setModalPageNumber(0);
      const transactionId = uuidv4();
      let frequencyValue;
      console.log("formData", formData);

      // Only validate amount for fixed schedule type
      if (formData.schedule_type === TransferScheduleType.FIXED) {
        // Destructure frequency value
        const { frequency } = formData;
        frequencyValue = frequency.value; // Extract the value
        if (!formData.amount_in_usd) {
          throw new Error("Amount is required");
        }

        // Convert amount to number
        const amountInUsd = parseFloat(formData.amount_in_usd);
        if (isNaN(amountInUsd) || amountInUsd <= 0) {
          throw new Error("Invalid amount");
        }

        // Validate fixed schedule fields
        if (!frequencyValue || !formData.start_date || !formData.end_date) {
          throw new Error("Missing required fields for fixed schedule");
        }

        // Validate frequency
        if (!Object.values(TransferFrequency).includes(frequencyValue)) {
          throw new Error("Invalid frequency");
        }

        // Validate dates
        const startDate = new Date(formData.start_date);
        const endDate = new Date(formData.end_date);
        const oneYearFromNow = new Date();
        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

        if (isNaN(startDate.getTime())) {
          throw new Error("Invalid start date");
        }

        if (isNaN(endDate.getTime())) {
          throw new Error("Invalid end date");
        }

        if (endDate <= startDate) {
          throw new Error("End date must be after start date");
        }

        if (endDate > oneYearFromNow) {
          throw new Error("End date cannot be more than 1 year from now");
        }
      } else if (formData.schedule_type === TransferScheduleType.CUSTOM) {
        // Validate custom schedule
        if (!formData.scheduled_transfers?.length) {
          throw new Error("Please add at least one transfer");
        }

        const oneYearFromNow = new Date();
        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

        // Validate each scheduled transfer
        formData.scheduled_transfers.forEach((transfer) => {
          const transferDate = new Date(transfer.scheduled_date);
          if (transferDate > oneYearFromNow) {
            throw new Error("All transfers must be within 1 year");
          }
          if (!transfer.amount_in_usd || transfer.amount_in_usd <= 0) {
            throw new Error("All transfers must have a valid amount");
          }
        });
      }

      // Prepare sender details
      const senderDetails = {
        sender_email: profileEmail,
        sender_name: formData.sender_details.sender_name,
        account_four_digits: formData.sender_details.account_four_digits,
        external_bank_account_guid:
          formData.sender_details.external_bank_account_guid,
      };

      // Prepare receiver details
      const receiverDetails = {
        receiver_id: recipientId,
        receiver_email: formData.receiver_details.receiver_email,
        receiver_nickname: formData.receiver_details.receiver_nickname,
        receiver_name: formData.receiver_details.receiver_name,
        receiver_phone: formData.receiver_details.receiver_phone,
        receiver_bank_number: formData.receiver_details.receiver_bank_number,
        receiver_bank_ifsc: formData.receiver_details.receiver_bank_ifsc,
        receiver_bank_name: formData.receiver_details.receiver_bank_name,
        cybrid_counterparty_guid: cybridCounterpartyGuid,
      };

      // Create recurring transfer with all necessary data
      await createRecurringTransfer({
        sender_id: profileEmail,
        sender_name: formData.sender_details.sender_name,
        external_bank_account_guid:
          formData.sender_details.external_bank_account_guid,
        customer_guid: formData.customer_guid,
        sender_details: senderDetails,
        receiver_details: receiverDetails,
        schedule_type: formData.schedule_type,
        scheduled_transfers: formData.scheduled_transfers,
        frequency: frequencyValue,
        start_date: formData.start_date,
        end_date: formData.end_date,
        next_transfer_date: formData.next_transfer_date,
        total_transfers_scheduled: formData.total_transfers_scheduled,
        status: "ACTIVE",
        transaction_id: transactionId,
      });

      setModalPageNumber(1);
    } catch (error) {
      console.error("Error creating recurring transfer:", error);
      setErrorMessage(
        error.message || "Error creating recurring transfer. Please try again."
      );
      setIsLoading(false);
      setModalIsOpen(false);
    }
  };

  const handleBack = () => {
    switch (currentStep) {
      case STEPS.BENEFICIARY:
        setSendFlowPageNumber(STEPS.SCHEDULE);
        break;
      case STEPS.BANK_ACCOUNT:
        setSendFlowPageNumber(STEPS.BENEFICIARY);
        break;
      case STEPS.CONFIRMATION:
        setSendFlowPageNumber(STEPS.BANK_ACCOUNT);
        break;
      default:
        navigate("/autoSend");
    }
  };

  // CardTitle contents
  const CardTitle = () => (
    <div
      className={cx(styles.titleContainer, {
        [styles.titleContainerMob]: isMobile,
      })}
    >
      {RECURRING_TRANSFER_ENUM.createRecurringTransfer}
    </div>
  );

  const CardBody = () => (
    <div
      className={cx(styles.createRecurringTransferMainContainer, {
        [styles.createRecurringTransferMainContainerMob]: isMobile,
      })}
    >
      <div className={styles.buttonContent}>
        <div className={styles.stepNameAndNumber}>
          <div
            className={cx(styles.stepsButton, {
              [styles.stepsButtonMob]: isMobile,
              [styles.active]: currentStep === 0,
              [styles.completed]: currentStep > 0,
            })}
          >
            1
          </div>
          {!isMobile && (
            <div
              className={cx(styles.stepsName, {
                [styles.activeStepName]: currentStep === 0,
                [styles.completedStepName]: currentStep > 0,
              })}
            >
              Schedule
            </div>
          )}
        </div>
        <div className={styles.line}></div>

        <div className={styles.stepNameAndNumber}>
          <div
            className={cx(styles.stepsButton, {
              [styles.stepsButtonMob]: isMobile,
              [styles.active]: currentStep === 1,
              [styles.completed]: currentStep > 1,
            })}
          >
            2
          </div>
          {!isMobile && (
            <div
              className={cx(styles.stepsName, {
                [styles.activeStepName]: currentStep === 1,
                [styles.completedStepName]: currentStep > 1,
              })}
            >
              Beneficiary
            </div>
          )}
        </div>
        <div className={styles.line}></div>

        <div className={styles.stepNameAndNumber}>
          <div
            className={cx(styles.stepsButton, {
              [styles.stepsButtonMob]: isMobile,
              [styles.active]: currentStep === 2,
              [styles.completed]: currentStep > 2,
            })}
          >
            3
          </div>
          {!isMobile && (
            <div
              className={cx(styles.stepsName, {
                [styles.activeStepName]: currentStep === 2,
                [styles.completedStepName]: currentStep > 2,
              })}
            >
              Bank
            </div>
          )}
        </div>
        <div className={styles.line}></div>
        <div className={styles.stepNameAndNumber}>
          <div
            className={cx(styles.stepsButton, {
              [styles.stepsButtonMob]: isMobile,
              [styles.active]: currentStep === 3,
              [styles.completed]: currentStep > 3,
            })}
          >
            4
          </div>
          {!isMobile && (
            <div
              className={cx(styles.stepsName, {
                [styles.stepsNameMob]: isMobile,
                [styles.activeStepName]: currentStep === 3,
                [styles.completedStepName]: currentStep > 3,
              })}
            >
              Confirm
            </div>
          )}
        </div>
      </div>
      <div className={styles.content}>{renderCurrentStep()}</div>
    </div>
  );

  const renderCurrentStep = () => {
    switch (currentStep) {
      case STEPS.SCHEDULE:
        return (
          <RecurringTransferSchedule
            onNext={handleScheduleNext}
            formData={formData}
            setFormData={setFormData}
            isLoading={isLoading}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            customerActivityLimit={customerActivityLimit}
          />
        );
      case STEPS.BENEFICIARY:
        return (
          <EnterReceiverDetails
            onBackFromRecurring={handleBack}
            onNextFromRecurring={handleBeneficiaryNext}
            // setSendFlowPageNumber={setSendFlowPageNumber}
            // sendFlowPageNumber={sendFlowPageNumber}
            profileEmail={profileEmail}
            setRecipientId={setRecipientId}
            setCybridCounterpartyGuid={setCybridCounterpartyGuid}
          />
        );
      case STEPS.BANK_ACCOUNT:
        return (
          <BankAccountsMain
            amountInINR={formData.amount_in_inr}
            amountInUSD={formData.amount_in_usd}
            setSendFlowPageNumber={setSendFlowPageNumber}
            profileEmail={profileEmail}
            cybridCounterpartyGuid={cybridCounterpartyGuid}
            recipientId={recipientId}
            onNextFromRecurring={handleBankAccountNext}
            onBackFromRecurring={handleBack}
          />
        );
      case STEPS.CONFIRMATION:
        return (
          <RecurringTransferConfirmation
            formData={formData}
            onBack={handleBack}
            onConfirm={handleConfirm}
            isLoading={isLoading}
            errorMessage={errorMessage}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Card titleComponent={<CardTitle />}>{CardBody()}</Card>;
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        {(() => {
          switch (modalPageNumber) {
            case 0:
              return <CreatingRecurringTransferModal />;
            case 1:
              return (
                <RecurringTransferCreatedModal
                  setModalIsOpen={setModalIsOpen}
                />
              );
            default:
              return null;
          }
        })()}
      </Modal>
    </>
  );
}
